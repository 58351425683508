import { PersonNote } from "views/persons/components/person-notes/types"
import {
  CompanyState,
  CompanyActionTypes,
  CompanyContextValues
} from "./company-module.types"

export const initialCompanyState: CompanyState = {
  isLoading: true,
  companyDetails: null!,
  selectedKnownEmployeeId: "",
  personRecordOverlayUpdated: false,
  offLimits: null!,
  notes: [],
  notesPagination: {
    firstItemOnPage: 1,
    totalItemCount: 1,
    pageSize: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    pageNumber: 1,
    maxPages: 5,
    pageCount: 1,
    onPageChange: () => {}
  }
}

export const companyReducer = (
  state: CompanyState,
  action: { type: string; payload?: any }
) => {
  const { type, payload } = action
  switch (type) {
    case CompanyActionTypes.SetIsLoading:
      return { ...state, isLoading: payload }
    case CompanyActionTypes.SetCompanyDetails:
      return { ...state, companyDetails: payload }
    case CompanyActionTypes.SetSelectedKnownEmployeeId:
      return { ...state, selectedKnownEmployeeId: payload }
    case CompanyActionTypes.SetOffLimits:
      return { ...state, offLimits: payload }
    case CompanyActionTypes.SetPersonRecordOverlayUpdated:
      return { ...state, personRecordOverlayUpdated: payload }
    case CompanyActionTypes.SetNotes:
      const sortedData = payload.sort(
        (a: PersonNote, b: PersonNote) =>
          new Date(b.updatedDate ? b.updatedDate : b.createdDate).getTime() -
          new Date(a.updatedDate ? a.updatedDate : a.createdDate).getTime()
      )
      return {
        ...state,
        notes: sortedData || []
      }
    case CompanyActionTypes.SetNotesPagination: {
      return {
        ...state,
        notesPagination: { ...state.notesPagination, ...payload }
      }
    }
    default:
      return state
  }
}

export const initialCompanyContextValues: CompanyContextValues = {
  ...initialCompanyState,
  companyId: "",
  setCompanyDetails: () => {},
  getCompanyData: () => {},
  setSelectedKnownEmployeeId: () => {},
  setOffLimits: () => {},
  setPersonRecordOverlayUpdated: () => {},
  setNotes: () => {},
  setNotesPagination: () => {}
}
