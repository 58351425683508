import React from "react"
import { H6 } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import NoteListItem from "./NoteListItem"
import { AddNoteButtonWrapper, NoteContainer } from "./styles"
import { Nullable } from "tsdef"

type PersonRecordNotesPropTypes = {
  note?: any
  personName?: Nullable<string> | undefined
  name: string
  typeTitle: string
  showAddIcon?: boolean
  showMostRecent?: boolean
  outputOnlyLabel?: boolean
  buttonMarginTop?: string
  label?: string
  openModal: (isCreating?: boolean) => void
}

export const PersonRecordNotes = ({
  note,
  showAddIcon = true,
  showMostRecent = true,
  outputOnlyLabel = false,
  label = "",
  buttonMarginTop = "5px",
  openModal
}: PersonRecordNotesPropTypes) => {
  return (
    <NoteContainer noPadding={true}>
      {note ? (
        <>
          {showMostRecent && (
            <H6 mt="5px">{messages.person.note.mostRecentNote}</H6>
          )}
          <NoteListItem
            note={note}
            onEdit={() => openModal()}
            {...(showAddIcon ? { onCreate: () => openModal(true) } : {})}
          />
        </>
      ) : outputOnlyLabel ? (
        <span onClick={() => openModal()}>{label}</span>
      ) : (
        <AddNoteButtonWrapper
          size="extra-small"
          mode="standard-green"
          mt={buttonMarginTop}
          onClick={() => openModal()}
        >
          + {messages.person.note.newNote}
        </AddNoteButtonWrapper>
      )}
    </NoteContainer>
  )
}
