import { Nullable } from "tsdef"
import { InterviewProgressStage } from "views/persons/components/person-assignments/components/InterviewProgress/constants/interview-progress-stages"

export enum InterviewProgressField {
  Stage = "stage",
  Status = "status",
  StatusId = "statusId",
  DueDate = "dueDate",
  AssignTo = "assignTo"
}

export const interviewProgressInitialValues = {
  [InterviewProgressField.Stage]: InterviewProgressStage.Identified,
  [InterviewProgressField.Status]: null,
  [InterviewProgressField.AssignTo]: null,
  [InterviewProgressField.DueDate]: null
}

export const interviewProgressInitialTouched = {
  [InterviewProgressField.Stage]: true,
  [InterviewProgressField.Status]: true
}

export type InterviewProgressValues = {
  stage: InterviewProgressStage
  status: Nullable<string>
  assignTo: Nullable<string>
  dueDate: Nullable<Date>
}

const fieldTargets: { [key in InterviewProgressField]?: string } = {
  [InterviewProgressField.Stage]: "interviewProgressStage",
  [InterviewProgressField.Status]: "interviewProgressStatus",
  [InterviewProgressField.DueDate]: "interviewProgressDueDate",
  [InterviewProgressField.AssignTo]: "interviewProgressAssignTo"
}

const elementTargets = {
  loader: "interview-progress-loader"
}

export const interviewProgressE2ETargets = {
  ...fieldTargets,
  ...elementTargets
}

export const debounceDuration = 300
