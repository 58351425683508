import {
  KeyboardEvent,
  useCallback,
  FocusEvent,
  useEffect,
  useMemo
} from "react"
import { useFormikContext } from "formik"
import get from "lodash/get"
import { InternalFilterType } from "./InternalSearchModule/types"
import { useInternalSearch } from "./InternalSearchModule/context"
import { v4 as uuidv4 } from "uuid"

const useAddSectionBox = (bundle: InternalFilterType.Sections) => {
  const boxName = "sections"
  const { filters } = useInternalSearch()
  const { values, setFieldValue } = useFormikContext()

  const boxes = useMemo(() => get(values, "sections") || [], [values])

  const boxesCount = useMemo(
    () => Math.max(filters?.[bundle]?.length || 1, boxes?.length || 1),
    [filters, boxes, bundle]
  )

  useEffect(() => {
    if (boxes?.length < boxesCount) {
      const newObjects = [...Array(boxesCount - boxes.length)].map(() => ({
        idBox: uuidv4()
      }))
      setFieldValue(boxName, [...boxes, ...newObjects])
    }
  }, [boxes, boxesCount, setFieldValue, boxName])

  const addNewBox = useCallback(() => {
    setFieldValue(boxName, [...boxes, { idBox: uuidv4() }])
  }, [boxes, setFieldValue, boxName])

  const removeBox = useCallback(
    (index: number) => {
      const keywordBoxesCopy = [...boxes]
      keywordBoxesCopy.splice(index, 1)

      setFieldValue(boxName, keywordBoxesCopy)
    },
    [boxes, setFieldValue, boxName]
  )

  return { boxes, boxesCount, addNewBox, removeBox }
}

export { useAddSectionBox }

const useAddSectionField = (indexSection: number) => {
  const boxName = `sections.${indexSection}.fields`

  const { filters } = useInternalSearch()
  const { values, setFieldValue } = useFormikContext()

  const boxes = useMemo(() => get(values, boxName) || [], [boxName, values])

  const boxesCount = useMemo(
    () =>
      Math.max(
        filters?.sections?.[indexSection]?.fields?.length || 1,
        boxes?.length || 1
      ),
    [filters, boxes, indexSection]
  )

  useEffect(() => {
    if (boxes?.length < boxesCount) {
      const newObjects = [...Array(boxesCount - boxes.length)].map(() => ({
        idBox: uuidv4()
      }))

      setFieldValue(boxName, [...boxes, ...newObjects])
    }
  }, [boxes, boxesCount, setFieldValue, boxName])

  const addNewBox = useCallback(() => {
    setFieldValue(boxName, [...boxes, { idBox: uuidv4() }])
  }, [boxes, setFieldValue, boxName])

  const removeBox = useCallback(
    (index: number) => {
      const keywordBoxesCopy = [...boxes]
      keywordBoxesCopy.splice(index, 1)
      setFieldValue(boxName, keywordBoxesCopy)
    },
    [boxes, setFieldValue, boxName]
  )

  const removeAllBoxes = useCallback(() => {
    setFieldValue(boxName, [])
  }, [setFieldValue, boxName])

  return { boxes, boxesCount, addNewBox, removeBox, removeAllBoxes }
}

export { useAddSectionField }

export const useToggleButtonSection = (filterType: InternalFilterType) => {
  const { updateFilters } = useInternalSearch()

  return useCallback(
    (_: any, value: any) => {
      updateFilters({
        [filterType]: value
      })
    },
    [filterType, updateFilters]
  )
}

type UseArrayFieldChangeParams = {
  field: string
  isOnBlur?: boolean
}

type useArrayFieldSelectChangeProps = {
  field: string
}
export const useArrayFieldAutoCompleteChange = ({
  field
}: useArrayFieldSelectChangeProps) => {
  const formik = useFormikContext()
  const { addArrayBasedInternalFilter } = useInternalSearch()

  return useCallback(
    (selectedValue: string) => {
      formik.setFieldTouched(field)

      if (!formik.isValid) return

      addArrayBasedInternalFilter(field, selectedValue)

      formik.setSubmitting(false)
    },
    [addArrayBasedInternalFilter, field, formik]
  )
}

export const useArrayFieldSelectChange = ({
  field
}: useArrayFieldSelectChangeProps) => {
  const formik = useFormikContext()
  const { addArrayBasedInternalFilter } = useInternalSearch()

  return useCallback(
    (e: React.FormEvent<HTMLSelectElement>) => {
      e.preventDefault()
      formik.setFieldTouched(field)

      if (!formik.isValid) return

      addArrayBasedInternalFilter(field, e.currentTarget.value)

      formik.setSubmitting(false)
    },
    [addArrayBasedInternalFilter, field, formik]
  )
}

export const useArrayFieldInputChange = ({
  field,
  isOnBlur = false
}: UseArrayFieldChangeParams) => {
  const formik = useFormikContext()
  const { addArrayBasedInternalFilter } = useInternalSearch()

  return useCallback(
    (e: KeyboardEvent<HTMLInputElement> | FocusEvent<HTMLInputElement>) => {
      if ((e as KeyboardEvent<HTMLInputElement>).key === "Enter" || isOnBlur) {
        e.preventDefault()
        formik.setFieldTouched(field)

        if (!formik.isValid) return

        addArrayBasedInternalFilter(field, e.currentTarget.value)

        formik.setFieldValue(field, "", false)
        formik.setSubmitting(false)
      }
    },
    [addArrayBasedInternalFilter, field, formik, isOnBlur]
  )
}

export const useRadioField = (filterType: InternalFilterType | string) => {
  const { updateFilters } = useInternalSearch()

  return useCallback(
    (event: any) => {
      const value = event.target.value
      updateFilters({
        [filterType]: value
      })
    },
    [filterType, updateFilters]
  )
}
