import React, { ReactNode } from "react"
import { Div, Flex } from "@ikiru/talentis-fpc"
import PersonDataPrivacy from "../person-data-privacy"
import PersonEducation from "../person-education"
import { PersonOffLimits } from "../person-offlimits/PersonOffLimits"

interface OuterBoxProps {
  children: ReactNode
}

const OuterBox: React.FC<OuterBoxProps> = ({ children }) => (
  <Div mb="s">{children}</Div>
)

const PersonOtherInfo = () => {
  return (
    <Flex flexWrap="wrap">
      <Flex flexDirection="column" width={[1, 1, 1, 1 / 2]} pr={[0, 0, 0, "s"]}>
        <OuterBox>
          <PersonDataPrivacy />
        </OuterBox>
      </Flex>
      <Flex flexDirection="column" width={[1, 1, 1, 1 / 2]} pr={[0, 0, 0, "s"]}>
        <OuterBox>
          <PersonEducation />
        </OuterBox>
        <OuterBox>
          <PersonOffLimits />
        </OuterBox>
      </Flex>
    </Flex>
  )
}

export default PersonOtherInfo
