import { colors, Div, Flex, H5, theme } from "@ikiru/talentis-fpc"
import styled from "styled-components"
import { ReactComponent as PlusIcon } from "assets/plus-internal-search.svg"
import { ReactComponent as MinusIcon } from "assets/minus-internal-search.svg"

export const ButtonsFlex = styled(Flex)`
  margin-left: auto;
  margin-bottom: 5px;
  gap: 10px;
  flex-shrink: 0;
`

const iconStyles = `
  width: 25px !important;
  height: 25px !important;
  
  path {
    fill: ${colors.grey.dark};
  }
`

export const StyledPlusIcon = styled(PlusIcon)`
  ${iconStyles}
`

export const StyledMinusIcon = styled(MinusIcon)`
  ${iconStyles}
`

export const DropDownContainer = styled(Div)`
  position: absolute;
  margin-top: 1px;
  border-radius: 3px;
  min-width: 200px;
  border: 2px solid ${theme.colors.grey.light};
  background-color: ${theme.colors?.white?.standard};
  overflow: hidden;
  z-index: 2;
`

export const DropDownItemContainer = styled(Flex)`
  justify-content: flex-start;
  align-items: center;
  padding: ${theme.space.xxs}px;
  height: 24px;
  cursor: pointer;
  color: ${theme.colors.grey.dark};
  &:hover {
    color: #fff;
    background-color: rgb(2, 125, 247);
  }
`
export const DropDownItem = styled(H5)`
  margin: 0;
  padding-top: 2px;
  font-size: 13px;
  line-height: 10px;
  color: inherit;
`
