import React from "react"
import { Flex, H5, SmallText, Span } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"

const instructions = [
  {
    title: messages.attributes.hintAddCategoryTitle,
    description: messages.attributes.hintAddCategoryDescription
  },
  {
    title: messages.attributes.hintAddAttributesTitle,
    description: messages.attributes.hintAddAttributesDescription
  },
  {
    title: messages.attributes.hintRenameTitle,
    description: messages.attributes.hintRenameDescription
  },
  {
    title: messages.attributes.hintHideTitle,
    description: messages.attributes.hintHideDescription
  },
  {
    title: messages.attributes.hintUnhideTitle,
    description: messages.attributes.hintUnhideDescription
  },
  {
    title: messages.attributes.hintDeleteTitle,
    description: messages.attributes.hintDeleteDescription
  }
]

const AttributeInstructions = () => {
  return (
    <Flex flexDirection="column" mt="m">
      <H5 mt="xs" mb="xxs" color="grey.dark">
        {messages.attributes.optionGuide}
      </H5>
      {instructions.map((instruction, index) => (
        <SmallText key={index} mb={4}>
          <Span fontWeight={600}>{instruction.title}</Span> -
          {instruction.description}
        </SmallText>
      ))}
    </Flex>
  )
}

export default AttributeInstructions
