import React, { useMemo } from "react"
import {
  Flex,
  Div,
  BodyText,
  Avatar,
  ConnectedIcon,
  Link,
  spacing,
  getMinWidthMediaQuery,
  OffLimits
} from "@ikiru/talentis-fpc"
import { RouterUrl } from "setup/router/routes"
import {
  CompanyPersonRecordBox,
  CompanyPersonData,
  LatestNoteDiv,
  PersonInfoWrapper,
  CurrentPersonData
} from "./style"
import { ProjectsDetails } from "./ProjectsDetails"
import { useCompany } from "views/companies/company-module/company-module.context"
import { useNavigate } from "react-router-dom"
import { useMediaQuery } from "utils/hooks/use-media-query"
import { CountsDetails } from "../../types"
import { messages } from "setup/messages/messages"
import { usePersonRecordNotes } from "components/PersonRecords/Notes/hooks/usePersonRecordNotes"
import LatestCompanyNotes from "./LatestCompanyNotes"
import PersonSectionButton from "views/persons/components/person-components/PersonSectionButton"

export type FormerPosition = {
  title: string
  startDate: string
  endDate: string
}

export type CurrentPosition = {
  companyId: string
  companyName: string
  title: string
  startDate: string
}

export type CompanyPersonRecordProps = {
  personId: string
  dataPoolId?: string
  name: string
  position: string
  counts: CountsDetails[]
  location: string
  currentPositions: CurrentPosition[]
  formerPositions: FormerPosition[]
  photo?: string
  isOfflimits?: boolean
}

export const CompanyPersonRecord = React.memo(
  ({
    personId,
    name,
    position,
    counts,
    photo,
    location,
    isOfflimits = false
  }: CompanyPersonRecordProps) => {
    const {
      setSelectedKnownEmployeeId,
      companyDetails,
      notes,
      notesPagination,
      setNotes,
      setNotesPagination
    } = useCompany()

    const increaseNoteCount = () => {
      setNotesPagination({
        totalItemCount: notesPagination.totalItemCount + 1
      })
    }

    const { note, openNoteModalCallback } = usePersonRecordNotes(
      personId,
      name,
      companyDetails.id,
      companyDetails.name,
      "company",
      notes,
      setNotes,
      increaseNoteCount
    )
    const navigate = useNavigate()

    const personUrl = useMemo(
      () => `${RouterUrl.ProfileDetails}/${personId}`,
      [personId]
    )

    const mediaQuery = getMinWidthMediaQuery("md")
    const isLarge = useMediaQuery(mediaQuery)

    const mediaQuerySmall = getMinWidthMediaQuery("sm")
    const isSmall = useMediaQuery(mediaQuerySmall)

    const onClickPerson = () => {
      isLarge ? setSelectedKnownEmployeeId(personId) : navigate(personUrl)
    }

    return (
      <CompanyPersonRecordBox>
        <PersonInfoWrapper isOfflimits={isOfflimits} onClick={onClickPerson}>
          {isOfflimits && (
            <OffLimits isSmall isCircle isLeftCircle={!isSmall} height="auto">
              {messages.offLimits.offLimits}
            </OffLimits>
          )}
          <Flex alignItems="center" pl="xxs" my="xs">
            <Div height="40px" width="40px" minHeight="40px" minWidth="40px">
              <Avatar
                photo={{ url: photo }}
                data-e2e-target-name={`person-${name}-avatar`}
                name={name}
                disableHoverOpacity
              />
            </Div>
            <Flex alignItems="center" ml="xxs">
              <Link
                mb="none"
                fontWeight={600}
                mr="xxs"
                onClick={onClickPerson}
                target="_blank"
              >
                {name}
              </Link>
              <Div>
                <ConnectedIcon width={spacing.m} />
              </Div>
            </Flex>
          </Flex>
        </PersonInfoWrapper>
        <Flex flexDirection="column" flex="3">
          <Flex flexDirection={["column", "column", "row"]} minHeight="60px">
            <CompanyPersonData onClick={onClickPerson}>
              <BodyText my={0}>{position}</BodyText>
            </CompanyPersonData>
            <CompanyPersonData onClick={onClickPerson}>
              <BodyText my={0}>{location}</BodyText>
            </CompanyPersonData>
            <CurrentPersonData>
              <ProjectsDetails counts={counts} onClick={onClickPerson} />
              <Div p={3}>
                <PersonSectionButton
                  disabled={false}
                  label={messages.person.note.newNote}
                  onClick={openNoteModalCallback}
                />
              </Div>
            </CurrentPersonData>
          </Flex>
          {Boolean(note) && note && (
            <LatestNoteDiv>
              <LatestCompanyNotes note={note} />
            </LatestNoteDiv>
          )}
        </Flex>
      </CompanyPersonRecordBox>
    )
  }
)
