import React, { useCallback, useEffect, useState, useMemo } from "react"
import { apiRequest } from "setup/api/api"
import { NoteEndpoint } from "setup/api/endpoints/endpoints"
import moment from "moment"
import { PersonNote } from "views/persons/components/person-notes/types"
import { useModal } from "utils/hooks/use-modal"
import { messages } from "setup/messages/messages"
import { ModalNames } from "setup/modal/modal.definitions"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import { NoteType } from "views/assignments/components/assignment-notes/components/NotesList/NoteAssignmentItem"
import { NoteEditForm } from "components/Notes/components/forms/NoteEditForm"
import { generateLinks } from "components/Notes/components/forms/utils"
import NotesEditMode from "components/Notes/components/EditMode"

const usePersonRecordNotes = (
  personRecordId: string,
  personName = "",
  id = "",
  projectName = "",
  type = "",
  notes: NoteType[] = [],
  setNotes?: any,
  onSumbitCallback?: (newNote?: any) => void,
  onSubmitEditCallback?: () => void
) => {
  const [isLoading, setIsLoading] = useState(false)
  const [note, setNote] = useState<PersonNote>()

  const { personOverlayNoteUpdated, setPersonOverlayNoteUpdated, personId } =
    usePerson()

  const { selectedTab, updateCompanyCandidateNote } = useAssignment()

  const { open, close } = useModal()

  const typeId = useMemo(
    () =>
      type === "assignment"
        ? "assignmentId"
        : type === "campaign"
        ? "campaignId"
        : "companyId",
    [type]
  )

  const getLatestNote = (personNotes: PersonNote[]) => {
    return personNotes?.sort((a: PersonNote, b: PersonNote) =>
      moment(b.updatedDate || b.createdDate).diff(
        a.updatedDate || a.createdDate
      )
    )?.[0]
  }

  const fetchNotes = useCallback(
    async (personId: string) => {
      setIsLoading(true)
      const [, response] = await apiRequest.get({
        endpoint: NoteEndpoint.Root,
        config: {
          params: { personId }
        }
      })

      if (type) {
        if (response && response.data) {
          const { notes: notesList } = response.data
          const noteTemp = notesList.filter((note: any) => note[typeId] === id)
          const notes = noteTemp.map((item: any) => ({
            ...item,
            linkCreatedByUser: item.createdByUser,
            linkUpdatedByUser: item.updatedByUser
          }))

          setNote(getLatestNote(notes))
        }
      } else {
        setNote(getLatestNote(response?.data?.notes))
      }

      setIsLoading(false)
    },
    [id, type, typeId]
  )

  const onCreateNoteSubmit = useCallback(
    (newNoteItem: any) => {
      const newNote = {
        ...newNoteItem,
        linkCreatedByUser: newNoteItem.createdByUser,
        linkUpdatedByUser: newNoteItem.updatedByUser
      }
      const newNotes = [newNote, ...notes]

      setNote(newNote)
      setNotes(newNotes)
      onSumbitCallback && onSumbitCallback(newNote)

      close(ModalNames.EditNoteData)
    },
    [notes, setNotes, onSumbitCallback, close]
  )

  const createNoteModal = useCallback(
    (title: string) => {
      return (
        <NotesEditMode
          title={title}
          projectId={id}
          projectType={type}
          projectName={projectName}
          onSubmit={onCreateNoteSubmit}
          close={() => close(ModalNames.EditNoteData)}
          personLinkedNote={{
            id: personRecordId,
            name: personName
          }}
        />
      )
    },
    [
      id,
      type,
      projectName,
      onCreateNoteSubmit,
      personRecordId,
      personName,
      close
    ]
  )

  const onEditNoteSubmit = useCallback(
    (newNoteItem: any) => {
      const updatedNote = {
        ...newNoteItem,
        linkCreatedByUser: newNoteItem.createdByUser,
        linkUpdatedByUser: newNoteItem.updatedByUser
      }

      const newNotes = notes.map((item) => {
        return item.id === updatedNote.id ? updatedNote : item
      })

      setNote(updatedNote)
      setNotes(newNotes)
      onSubmitEditCallback && onSubmitEditCallback()
      close(ModalNames.EditNoteData)
    },
    [close, notes, onSubmitEditCallback, setNotes]
  )

  const editNoteModal = useCallback(
    (title: string, note: NoteType) => {
      const {
        noteTitle,
        noteDescription,
        id,
        assignmentId,
        campaignId,
        personId,
        companyId
      } = note

      return (
        <NoteEditForm
          title={title}
          initialValues={{
            noteTitle,
            noteDescription
          }}
          notes={notes}
          projectId={assignmentId as string}
          setNotes={setNotes as any}
          noteId={id}
          links={generateLinks(note)}
          linkInitialValue={{
            assignment: assignmentId,
            campaign: campaignId,
            person: personId || personRecordId,
            company: companyId
          }}
          onSubmit={onEditNoteSubmit}
          personLinkedNote={{
            id: personRecordId,
            name: personName
          }}
        />
      )
    },
    [notes, onEditNoteSubmit, personName, personRecordId, setNotes]
  )

  const openNoteModalCallback = useCallback(
    (isCreating: boolean = false) => {
      const title = `${
        note && !isCreating
          ? messages.clientPortal.notes.editNote
          : messages.clientPortal.notes.addNote
      } ${
        Boolean(personName)
          ? `${messages.clientPortal.notes.for} ${personName}`
          : ""
      }`
      open(
        ModalNames.EditNoteData,
        note && !isCreating
          ? editNoteModal(title, note)
          : createNoteModal(title)
      )
    },
    [note, personName, open, editNoteModal, createNoteModal]
  )

  useEffect(() => {
    if (personRecordId) fetchNotes(personRecordId)
  }, [personRecordId, fetchNotes])

  useEffect(() => {
    const reFetchNotes = async () => {
      await fetchNotes(personRecordId)
      setPersonOverlayNoteUpdated(false)
    }
    if (personOverlayNoteUpdated && personRecordId === personId) {
      reFetchNotes()
    }
  }, [
    personRecordId,
    fetchNotes,
    personOverlayNoteUpdated,
    setPersonOverlayNoteUpdated,
    personId
  ])

  useEffect(() => {
    if (selectedTab === "companyCandidate" && note) {
      updateCompanyCandidateNote(personRecordId, note)
    }
  }, [note, personRecordId, selectedTab, updateCompanyCandidateNote])

  return {
    note,
    isLoading,
    onCreateNoteSubmit,
    onEditNoteSubmit,
    openNoteModalCallback
  }
}

export { usePersonRecordNotes }
