import React from "react"
import { Helmet } from "react-helmet"
import { colors, H1, Flex } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { RouterUrl } from "setup/router/routes"
import { SettingsSection } from "./SettingsSection"

const sections = [
  {
    title: "Team management",
    link: RouterUrl.Team,
    description: [
      "- Invite users",
      "- Control user privileges",
      "- Revoke user access"
    ]
  },
  {
    title: "Attributes management",
    link: RouterUrl.Attributes,
    description: [
      "- Add new attributes",
      "- Edit, hide/unhide, remove attributes",
      "- Set/define default attributes for each record type"
    ]
  },
  {
    title: "Assignment status management (Coming soon)",
    link: "#",
    description: ["- Custom assignment statuses"]
  },
  {
    title: "Enhanced GDPR functionalities (Coming soon)",
    link: "#",
    description: ["- Enable/disable GDPR lock"]
  }
]

export const Settings = () => {
  return (
    <Flex flexDirection="column">
      <Helmet>
        <title>{messages.seo.title.format(messages.settings.title)}</title>
      </Helmet>
      <H1 m="0" color="green.standard">
        {messages.settings.title}
      </H1>
      <Flex
        flexDirection="column"
        mt="20px"
        p="20px"
        backgroundColor={colors.grey.lightest}
      >
        {sections.map((section, index) => (
          <SettingsSection
            key={index}
            title={section.title}
            link={section.link}
            description={section.description}
          />
        ))}
      </Flex>
    </Flex>
  )
}
