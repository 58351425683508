import { messages } from "setup/messages/messages"
import { Nullable } from "tsdef"

export enum InterviewProgressField {
  Stage = "stage",
  Status = "status",
  AssignTo = "assignTo",
  DueDate = "dueDate"
}

// Candidate Status

export type InterviewProgressStatusOption = {
  label: string
  value: InterviewProgressStatus
}

export enum InterviewProgressStatus {
  NoStatus = "noStatus",
  NeedToContact = "needToContact",
  LeftMessage = "leftMessage",
  Contacted = "contacted",
  Interested = "interested",
  NotInterested = "notInterested",
  ArrangingInterview = "arrangingInterview",
  InterviewPlanned = "interviewPlanned",
  AwaitingFeedback = "awaitingFeedback",
  AwaitingReferences = "awaitingReferences",
  OnHold = "onHold",
  Rejected = "rejected",
  Withdrew = "withdrew"
}

const noStatus: InterviewProgressStatusOption = {
  value: InterviewProgressStatus.NoStatus,
  label: messages.person.assignments.interviewProgress.statuses.noStatus
}

const needToContact: InterviewProgressStatusOption = {
  value: InterviewProgressStatus.NeedToContact,
  label: messages.person.assignments.interviewProgress.statuses.needToContact
}

const leftMessage: InterviewProgressStatusOption = {
  value: InterviewProgressStatus.LeftMessage,
  label: messages.person.assignments.interviewProgress.statuses.leftMessage
}

const contacted: InterviewProgressStatusOption = {
  value: InterviewProgressStatus.Contacted,
  label: messages.person.assignments.interviewProgress.statuses.contacted
}

const interested: InterviewProgressStatusOption = {
  value: InterviewProgressStatus.Interested,
  label: messages.person.assignments.interviewProgress.statuses.interested
}

const notInterested: InterviewProgressStatusOption = {
  value: InterviewProgressStatus.NotInterested,
  label: messages.person.assignments.interviewProgress.statuses.notInterested
}

const arrangingInterview: InterviewProgressStatusOption = {
  value: InterviewProgressStatus.ArrangingInterview,
  label:
    messages.person.assignments.interviewProgress.statuses.arrangingInterview
}

const interviewPlanned: InterviewProgressStatusOption = {
  value: InterviewProgressStatus.InterviewPlanned,
  label: messages.person.assignments.interviewProgress.statuses.interviewPlanned
}

const awaitingFeedback: InterviewProgressStatusOption = {
  value: InterviewProgressStatus.AwaitingFeedback,
  label: messages.person.assignments.interviewProgress.statuses.awaitingFeedback
}

const awaitingReferences: InterviewProgressStatusOption = {
  value: InterviewProgressStatus.AwaitingReferences,
  label:
    messages.person.assignments.interviewProgress.statuses.awaitingReferences
}

const onHold: InterviewProgressStatusOption = {
  value: InterviewProgressStatus.OnHold,
  label: messages.person.assignments.interviewProgress.statuses.onHold
}

const rejected: InterviewProgressStatusOption = {
  value: InterviewProgressStatus.Rejected,
  label: messages.person.assignments.interviewProgress.statuses.rejected
}

const withdrew: InterviewProgressStatusOption = {
  value: InterviewProgressStatus.Withdrew,
  label: messages.person.assignments.interviewProgress.statuses.withdrew
}

export const candidateStatuses = [
  noStatus,
  needToContact,
  leftMessage,
  contacted,
  interested,
  notInterested,
  arrangingInterview,
  interviewPlanned,
  awaitingFeedback,
  awaitingReferences,
  onHold,
  rejected,
  withdrew
]

// Candidate Stages

export type InterviewProgressStagesOption = {
  label: string
  value: InterviewProgressStage
}

export enum InterviewProgressStage {
  Identified = "identified",
  Source = "source",
  Screening = "screening",
  InternalInterview = "internalInterview",
  Shortlist = "shortList",
  FirstClientInterview = "firstClientInterview",
  SecondClientInterview = "secondClientInterview",
  ThirdClientInterview = "thirdClientInterview",
  Offer = "offer",
  Placed = "placed",
  Archive = "archive"
}

const identified: InterviewProgressStagesOption = {
  label: messages.person.assignments.interviewProgress.stages.identified,
  value: InterviewProgressStage.Identified
}

const source: InterviewProgressStagesOption = {
  label: messages.person.assignments.interviewProgress.stages.source,
  value: InterviewProgressStage.Source
}

const screening: InterviewProgressStagesOption = {
  label: messages.person.assignments.interviewProgress.stages.screening,
  value: InterviewProgressStage.Screening
}

const internalInterview: InterviewProgressStagesOption = {
  label: messages.person.assignments.interviewProgress.stages.internalInterview,
  value: InterviewProgressStage.InternalInterview
}

const shortlist: InterviewProgressStagesOption = {
  label: messages.person.assignments.interviewProgress.stages.shortList,
  value: InterviewProgressStage.Shortlist
}

const firstClientInterview: InterviewProgressStagesOption = {
  label:
    messages.person.assignments.interviewProgress.stages.firstClientInterview,
  value: InterviewProgressStage.FirstClientInterview
}

const secondClientInterview: InterviewProgressStagesOption = {
  label:
    messages.person.assignments.interviewProgress.stages.secondClientInterview,
  value: InterviewProgressStage.SecondClientInterview
}

const thirdClientInterview: InterviewProgressStagesOption = {
  label:
    messages.person.assignments.interviewProgress.stages.thirdClientInterview,
  value: InterviewProgressStage.ThirdClientInterview
}

const offer: InterviewProgressStagesOption = {
  label: messages.person.assignments.interviewProgress.stages.offer,
  value: InterviewProgressStage.Offer
}

const placed: InterviewProgressStagesOption = {
  label: messages.person.assignments.interviewProgress.stages.placed,
  value: InterviewProgressStage.Placed
}

const archive: InterviewProgressStagesOption = {
  label: messages.person.assignments.interviewProgress.stages.archive,
  value: InterviewProgressStage.Archive
}

export const candidateStages = [
  identified,
  source,
  screening,
  internalInterview,
  shortlist,
  firstClientInterview,
  secondClientInterview,
  thirdClientInterview,
  offer,
  placed,
  archive
]

// another

export const interviewProgressInitialValues = {
  [InterviewProgressField.Stage]: InterviewProgressStage.Identified,
  [InterviewProgressField.Status]: InterviewProgressStatus.NoStatus,
  [InterviewProgressField.DueDate]: null,
  [InterviewProgressField.AssignTo]: null
}

export const interviewProgressInitialTouched = {
  [InterviewProgressField.Stage]: true,
  [InterviewProgressField.Status]: true,
  [InterviewProgressField.AssignTo]: true,
  [InterviewProgressField.DueDate]: true
}

export type InterviewProgressValues = {
  stage?: InterviewProgressStage
  statusId?: string | null
  assignTo?: Nullable<string>
  dueDate?: Nullable<Date>
}

export const e2eTargets = {
  [InterviewProgressField.Stage]: "interview-progress-stage",
  [InterviewProgressField.Status]: "interview-progress-status",
  [InterviewProgressField.AssignTo]: "interview-progress-assign-to",
  [InterviewProgressField.DueDate]: "interview-progress-due-date"
}

export const NO_SELECTED_STATUS = ""

export const DefaultStatusValue = {
  label: messages.form.generic.noStatus,
  value: NO_SELECTED_STATUS
}
