import React, { SyntheticEvent, useEffect, useState } from "react"
import {
  InterviewProgressDetailsBox,
  ProjectBox,
  ProjectBoxWrapper,
  ProjectDetailsBox,
  ProjectTitle,
  StatusCircle,
  StatusContainer,
  StatusTexts,
  ProjectFooterBox
} from "./style"
import {
  AvatarBox,
  RecordFooterTabItem,
  RecordFooterTabs
} from "views/persons/styles"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import { Candidate } from "views/candidates/candidates.types"
import { Flex, Div, Tag, Avatar } from "@ikiru/talentis-fpc"
import { Link } from "react-router-dom"
import { RouterUrl } from "setup/router/routes"
import { personAssignmentsE2ETargets } from "../../form/constants/add-person-assignment.definitions"
import { messages } from "setup/messages/messages"
import { get } from "lodash"
import { InterviewProgress } from "../InterviewProgress/InterviewProgress"
import ProjectActionPopUp from "components/ProjectActions/ProjectActionPopUp"
import { Tags } from "components/PersonRecords/Tags"
import { PersonRecordNotes } from "components/PersonRecords/Notes"
import { Bio } from "views/assignments/components/candidates-list/components/candidate-record/components/Bio/Bio"
import { useTagsCandidates } from "../../hooks/useTagsCandidates"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { usePersonRecordNotes } from "components/PersonRecords/Notes/hooks/usePersonRecordNotes"
import { usePersonRecordCommentary } from "components/PersonRecords/Commentary/hooks/usePersonRecordCommentary"
import { CompanyLink } from "components/visual/CompanyLink/CompanyLink"
import { ProjectRecordDetails } from "components/ProjectPersonRecord/ProjectRecordDetails"

type AssignmentRecordProps = {
  candidate: Candidate
}

export type menuTabsEnum = "notes" | "commentary" | "bio"

const AssignmentRecord = ({ candidate }: AssignmentRecordProps) => {
  const assignmentDetails = candidate.linkAssignment!
  const {
    assignmentId,
    setSelectedCandidatedId,
    notesPagination: assignmentNotePagination,
    setNotesPagination: setAssignmentNotePage,
    notes: assignmentNotes,
    setNotes: setAssignmentNotes
  } = useAssignment()

  const {
    personId,
    profileDetails,
    setNotes,
    notes,
    setNotesPagination,
    notesPagination,
    setPersonOverlayNoteUpdated
  } = usePerson()

  const bio = candidate.bio?.data || profileDetails.bio.value || ""

  const candidateId = candidate?.personId || personId || ""

  const increaseNoteCount = (newNote: any) => {
    setNotesPagination({
      totalItemCount: notesPagination.totalItemCount + 1
    })
    if (assignmentId && newNote.assignmentId === assignmentId) {
      setPersonOverlayNoteUpdated(true)
      setAssignmentNotePage({
        totalItemCount: assignmentNotePagination.totalItemCount + 1
      })
      setAssignmentNotes([newNote, ...assignmentNotes])
    }
  }

  const onSubmitEditNote = () => {
    if (assignmentId) {
      setPersonOverlayNoteUpdated(true)
    }
  }
  const { note, openNoteModalCallback } = usePersonRecordNotes(
    candidateId,
    profileDetails.name.value || "",
    assignmentDetails?.id || "",
    assignmentDetails.name,
    "assignment",
    notes,
    setNotes,
    increaseNoteCount,
    onSubmitEditNote
  )

  const { prepareNoteView, openCommentaryModal } = usePersonRecordCommentary(
    candidate.id
  )

  const [activeTabName, setActiveTab] = useState<menuTabsEnum>()
  const [clickedTabName, setClickedTabName] = useState<menuTabsEnum>()

  useEffect(() => {
    const tabsList: menuTabsEnum[] = []
    if (note) {
      tabsList.push("notes")
    }
    if (candidate?.commentary) {
      tabsList.push("commentary")
    }
    if (bio) {
      tabsList.push("bio")
    }
    const clickedTab =
      tabsList[tabsList.findIndex((val) => val === clickedTabName)] ||
      tabsList[0]
    setActiveTab(clickedTabName ? clickedTab : tabsList[0])
  }, [note, candidate, clickedTabName, bio])

  const isFooterHasContent = () => {
    return note || candidate?.commentary || bio
  }

  const options = [
    {
      label: messages.person.note.title,
      onClick: () => {
        openNoteModalCallback(true)
      }
    },
    {
      label: messages.person.commentary.title,
      onClick: ((e: MouseEvent) => {
        if (candidate) {
          openCommentaryModal(
            "",
            candidate
          )(candidate?.commentary && prepareNoteView(candidate))
        }
      }) as VoidFunction
    },
    {
      label: candidate ? (
        <Tags
          personRecordTags={candidate.tags}
          tags={assignmentDetails.tags}
          personRecordId={candidate.id}
          usePersonRecordTags={useTagsCandidates}
          name={assignmentDetails.name}
          showOnlyAddButton={true}
          outputOnlyLabel={true}
          label={messages.assignment.assignmentTags}
        />
      ) : (
        ""
      ),
      onClick: ((e: SyntheticEvent) => {
        const label = (e.target as HTMLElement)?.querySelector(
          "#tags-label"
        ) as HTMLElement
        label?.click()
        e.preventDefault()
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
      }) as VoidFunction,
      disableClose: true
    }
  ]

  return (
    <ProjectBoxWrapper key={candidate.id}>
      <ProjectBox>
        <AvatarBox>
          <Avatar name={assignmentDetails?.name} />
        </AvatarBox>
        <ProjectDetailsBox>
          <CompanyLink
            companyName={assignmentDetails?.companyName}
            companyId={assignmentDetails?.companyId}
            fontSize={[15, 15, 16]}
            mt="s"
          />
          <ProjectTitle>{assignmentDetails?.jobTitle}</ProjectTitle>
          <Flex
            alignItems="center"
            flexWrap="nowrap"
            justifyContent="flex-start"
          >
            <Div mr="xxs">
              <Tag
                width="100%"
                data-e2e-target={personAssignmentsE2ETargets.assignment}
                mb="xxs"
              >
                <Link
                  onClick={() => setSelectedCandidatedId("")}
                  to={`${RouterUrl.AssignmentDetails}/${candidate?.linkAssignment?.id}`}
                >
                  {candidate.linkAssignment?.name}
                </Link>
              </Tag>
            </Div>
            <StatusContainer>
              <StatusCircle />
              <StatusTexts>
                {get(
                  messages.assignment.statuses,
                  assignmentDetails?.status || "active"
                )}
              </StatusTexts>
            </StatusContainer>
          </Flex>
        </ProjectDetailsBox>
        <InterviewProgressDetailsBox>
          <Div maxWidth="500px">
            <InterviewProgress candidate={candidate} />
          </Div>
          <Flex flexDirection="column" alignItems="flex-end" pl="m">
            <ProjectActionPopUp options={options} />
          </Flex>
        </InterviewProgressDetailsBox>
      </ProjectBox>
      <ProjectFooterBox>
        {isFooterHasContent() && (
          <RecordFooterTabs>
            {note && (
              <RecordFooterTabItem
                onClick={() => {
                  setActiveTab("notes")
                  setClickedTabName("notes")
                }}
                active={activeTabName === "notes"}
              >
                {messages.person.note.latestNote}
              </RecordFooterTabItem>
            )}
            {candidate?.commentary && (
              <RecordFooterTabItem
                onClick={() => {
                  setActiveTab("commentary")
                  setClickedTabName("commentary")
                }}
                active={activeTabName === "commentary"}
              >
                {messages.person.commentary.title}
              </RecordFooterTabItem>
            )}
            {bio && (
              <RecordFooterTabItem
                onClick={() => {
                  setActiveTab("bio")
                  setClickedTabName("bio")
                }}
                active={activeTabName === "bio"}
              >
                {messages.person.bio.title}
              </RecordFooterTabItem>
            )}
          </RecordFooterTabs>
        )}
        {bio && activeTabName === "bio" && (
          <Bio candidate={candidate as Candidate} description={bio} noName />
        )}
        {note && activeTabName === "notes" && (
          <PersonRecordNotes
            note={note}
            personName={profileDetails.name.value}
            name={assignmentDetails.name}
            typeTitle={messages.assignment.addNew}
            showAddIcon={false}
            showMostRecent={false}
            openModal={openNoteModalCallback}
          />
        )}
        {candidate?.commentary && activeTabName === "commentary" && (
          <ProjectRecordDetails
            project={candidate?.commentary && prepareNoteView(candidate)}
            name={assignmentDetails.name}
            typeTitle={messages.assignment.addNew}
            showAddIcon={false}
            showMostRecent={false}
            openModal={openCommentaryModal("", candidate) as () => {}}
          />
        )}
        {candidate && Boolean(candidate.tags?.length) && (
          <Tags
            personRecordTags={candidate.tags}
            tags={assignmentDetails.tags}
            personRecordId={candidate.id}
            usePersonRecordTags={useTagsCandidates}
            name={assignmentDetails.name}
            showAddIcon={false}
          />
        )}
      </ProjectFooterBox>
    </ProjectBoxWrapper>
  )
}

export default AssignmentRecord
