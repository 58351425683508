import { Nullable } from "tsdef"

export type Attribute = {
  id: Nullable<string>
  title: string
  key: string
  whereUsed: string[]
  isHidden: boolean
  children: Attribute[]
  isAttribute?: boolean
  isSystem?: boolean
  created?: Nullable<string>
  isUsed?: boolean
  description?: Nullable<string>
  isEdit?: boolean
}

export enum AttributesActionTypes {
  SetIsLoading = "setIsLoading",
  SetAttributes = "setAttributes",
  SetWhereUsedList = "setWhereUsedList"
}

export type AttributesState = {
  isLoading: boolean
  attributes: Attribute[]
  whereUsedList: string[]
}

export type AttributesContextValues = AttributesState & {
  setAttributes: SetAttributes
  setIsLoading: SetIsLoading
  setWhereUsedList: SetWhereUsedList
}

export type SetIsLoading = (data: boolean) => void
export type SetAttributes = (attributes: Attribute[]) => void
export type SetWhereUsedList = (whereUsedList: string[]) => void

export type AttributesAction = {
  type: AttributesActionTypes
  payload?: any
}
