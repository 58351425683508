import React, { useCallback, useEffect, useReducer } from "react"
import { InternalSearchContext } from "./context"
import { internalSearchReducer, initialInternalSearchState } from "./reducer"
import {
  InternalSearchActionTypes,
  UpdateFilters,
  SetIsLoadingSearchResults,
  SetSections,
  Section,
  InternalFilterType,
  SetInternalPersons,
  SetInternalSearchPage,
  InternalSearchPageParams,
  ResetFilters,
  SetIsResetFilters,
  SetIsLookup,
  SetLookupSearchString,
  SetSelectedInternalSearchPersonId,
  UpdatePerson,
  SetIsAssignmentSelect,
  SetSelectedAssignment,
  SetActiveAssignments,
  SetActiveCampaigns,
  SetSelectedCampaign,
  InternalPersonType,
  UpdateAutoCompleteLabelList
} from "./types"
import { getLocalSearchSection } from "../actions"
import get from "lodash/get"
import { Campaign } from "views/campaigns/campaign.types"
import { apiRequest } from "setup/api/api"
import {
  AssignmentsEndpoints,
  CampaignsEndpoints,
  CandidatesEndpoints,
  ContactsEndpoints
} from "setup/api/endpoints/endpoints"

type InternalSearchModuleProps = {
  children: React.ReactNode
}

export const InternalSearchModule = (props: InternalSearchModuleProps) => {
  const { children } = props
  const [state, dispatch] = useReducer(
    internalSearchReducer,
    initialInternalSearchState
  )

  const setIsLoadingSearchResults: SetIsLoadingSearchResults = useCallback(
    (data) => {
      dispatch({
        type: InternalSearchActionTypes.SetIsLoadingSearchResults,
        payload: data
      })
    },
    [dispatch]
  )

  const setSections: SetSections = useCallback(
    (data) => {
      dispatch({
        type: InternalSearchActionTypes.SetSections,
        payload: data
      })
    },
    [dispatch]
  )

  const setInternalPersons: SetInternalPersons = useCallback(
    (data) => {
      dispatch({
        type: InternalSearchActionTypes.SetInternalPersons,
        payload: data
      })
    },
    [dispatch]
  )

  const setInternalSearchPage: SetInternalSearchPage = (
    page: InternalSearchPageParams
  ) => {
    dispatch({
      type: InternalSearchActionTypes.SetInternalSearchPage,
      payload: page
    })
  }

  const updateFilters: UpdateFilters = useCallback(
    (data) => {
      dispatch({ type: InternalSearchActionTypes.UpdateFilters, payload: data })
    },
    [dispatch]
  )

  const clearFilters: UpdateFilters = useCallback(
    (data) => {
      dispatch({
        type: InternalSearchActionTypes.ClearCertainFilters,
        payload: data
      })
    },
    [dispatch]
  )

  const setIsLookup: SetIsLookup = useCallback(
    (data) => {
      dispatch({
        type: InternalSearchActionTypes.SetIsLookup,
        payload: data
      })
    },
    [dispatch]
  )

  const setLookupSearchString: SetLookupSearchString = useCallback(
    (data) => {
      dispatch({
        type: InternalSearchActionTypes.SetLookupSearchString,
        payload: data
      })
    },
    [dispatch]
  )

  const setIsAssignmentSelect: SetIsAssignmentSelect = useCallback(
    (data: boolean) => {
      dispatch({
        type: InternalSearchActionTypes.SetIsAssignmentSelect,
        payload: data
      })
    },
    [dispatch]
  )

  const setSelectedAssignment: SetSelectedAssignment = useCallback(
    (data) => {
      dispatch({
        type: InternalSearchActionTypes.SetSelectedAssignment,
        payload: data
      })
    },
    [dispatch]
  )

  const setActiveAssignments: SetActiveAssignments = useCallback(
    (data) => {
      dispatch({
        type: InternalSearchActionTypes.SetActiveAssignments,
        payload: data
      })
    },
    [dispatch]
  )

  const setActiveCampaigns: SetActiveCampaigns = useCallback(
    (data: Campaign[]) => {
      dispatch({
        type: InternalSearchActionTypes.SetActiveCampaigns,
        payload: data
      })
    },
    [dispatch]
  )

  const setSelectedCampaign: SetSelectedCampaign = useCallback(
    (data: string) => {
      dispatch({
        type: InternalSearchActionTypes.SetSelectedCampaign,
        payload: data
      })
    },
    [dispatch]
  )

  const addArrayBasedInternalFilter = useCallback(
    (filterName: InternalFilterType | string, value: string | string[]) => {
      const values = !Array.isArray(value) ? [value] : value

      const trimmedValues = values.map((v) => v.trim()).filter((v) => v !== "")

      const currentValue = get(state.filters, filterName, []) as string[]

      let newValue: string[] = [...currentValue]
      trimmedValues.forEach((v) => {
        if (!currentValue.includes(v)) {
          newValue = [...newValue, v]
        }
      })

      updateFilters({
        [filterName]: newValue
      })
    },

    [state.filters, updateFilters]
  )

  const removeArrayBasedInternalFilter = useCallback(
    (filterName: InternalFilterType | string, value: string) => {
      const currentValue = get(state.filters, filterName, []) as string[]
      updateFilters({
        [filterName]: currentValue.filter((v: string) => v !== value)
      })
    },
    [state.filters, updateFilters]
  )

  const clearArrayBasedFilter = useCallback(
    (filterName: InternalFilterType | string) => {
      const currentValue = get(state.filters, filterName, []) as string[]
      clearFilters({
        [filterName]: currentValue
      })
    },
    [state.filters, clearFilters]
  )

  const resetFilters: ResetFilters = useCallback(() => {
    dispatch({
      type: InternalSearchActionTypes.ResetFilters
    })
  }, [dispatch])

  const setIsResetFilters: SetIsResetFilters = useCallback(
    (data) => {
      dispatch({
        type: InternalSearchActionTypes.SetIsResetFilters,
        payload: data
      })
    },
    [dispatch]
  )

  const setSelectedInternalSearchPersonId: SetSelectedInternalSearchPersonId =
    useCallback(
      (data) => {
        dispatch({
          type: InternalSearchActionTypes.SetSelectedInternalSearchPersonId,
          payload: data
        })
      },
      [dispatch]
    )

  const updatePerson: UpdatePerson = useCallback(
    (person) => {
      dispatch({
        type: InternalSearchActionTypes.UpdatePerson,
        payload: person
      })
    },
    [dispatch]
  )
  const updateAutoCompleteLabelList: UpdateAutoCompleteLabelList = useCallback(
    (payload) => {
      dispatch({
        type: InternalSearchActionTypes.UpdateAutoCompleteLabelList,
        payload
      })
    },
    [dispatch]
  )

  const fetchAssignments = useCallback(
    async (isAdding = false) => {
      const [, response] = await apiRequest.get({
        endpoint: AssignmentsEndpoints.SimpleList,
        config: { params: { totalItemCount: 300 } }
      })

      const assignments = response?.data?.simpleActiveAssignments || []

      setActiveAssignments(assignments)
      if (isAdding) setSelectedAssignment(assignments[0].id)
    },
    [setActiveAssignments, setSelectedAssignment]
  )

  const fetchCampaigns = useCallback(
    async (isAdding = false) => {
      const [, response] = await apiRequest.get({
        endpoint: CampaignsEndpoints.SimpleList,
        config: { params: { totalItemCount: 300, status: "active" } }
      })

      const campaigns = response?.data?.simpleActiveCampaigns
      setActiveCampaigns(campaigns)
      if (isAdding) setSelectedCampaign(campaigns[0].id)
    },
    [setActiveCampaigns, setSelectedCampaign]
  )

  const {
    internalPersons,
    isAssignmentSelect,
    selectedAssignment,
    selectedCampaign
  } = state

  const addAssignmentToPerson = useCallback(
    async (personId: string) => {
      const [, response] = await apiRequest.post({
        endpoint: CandidatesEndpoints.Root,
        data: {
          assignmentId: selectedAssignment,
          personId: personId
        }
      })

      return response?.data?.assignmentId
    },
    [selectedAssignment]
  )

  const addCampaignToPerson = useCallback(
    async (personId: string) => {
      const [, response] = await apiRequest.post({
        endpoint: ContactsEndpoints.Root,
        data: {
          campaignId: selectedCampaign,
          personId: personId
        }
      })

      return response?.data?.campaignId
    },
    [selectedCampaign]
  )

  const assignProjectPerson = useCallback(
    (personId: string) => {
      const data = isAssignmentSelect
        ? addAssignmentToPerson(personId)
        : addCampaignToPerson(personId)
      return data
    },
    [addAssignmentToPerson, addCampaignToPerson, isAssignmentSelect]
  )

  const updatePersonProject = useCallback(
    (personId: string, projectId: string, projectName: string) => {
      const personIndex = internalPersons.findIndex(
        (person: InternalPersonType) => personId === person.id
      )

      const newPerson = internalPersons[personIndex]

      if (projectName === "assignment") {
        newPerson.assignmentIds
          ? (newPerson.assignmentIds = [...newPerson.assignmentIds, projectId])
          : (newPerson.assignmentIds = [projectId])
      } else {
        newPerson.campaignIds
          ? (newPerson.campaignIds = [...newPerson.campaignIds, projectId])
          : (newPerson.campaignIds = [projectId])
      }

      updatePerson(newPerson)
    },
    [updatePerson, internalPersons]
  )

  const linkToProject = useCallback(
    async (personId: string) => {
      const projectId = await assignProjectPerson(personId)
      const projectName = isAssignmentSelect ? "assignment" : "campaign"
      if (projectId) updatePersonProject(personId, projectId, projectName)
    },
    [assignProjectPerson, updatePersonProject, isAssignmentSelect]
  )

  useEffect(() => {
    fetchAssignments()
  }, [fetchAssignments])

  useEffect(() => {
    fetchCampaigns()
  }, [fetchCampaigns])

  useEffect(() => {
    if (isAssignmentSelect === null) {
      setIsAssignmentSelect(true)
    }
  }, [setIsAssignmentSelect, isAssignmentSelect])

  useEffect(() => {
    let isSectionsLoading = true

    const fetchSection = async () => {
      try {
        const sections = await getLocalSearchSection()
        if (isSectionsLoading) {
          const optionsSearch = sections.map((section: Section) => ({
            value: section.id,
            label: section.name
          }))
          setSections(optionsSearch)
        }
      } catch (error) {
        console.error("Error fetching sections:", error)
      }
    }

    fetchSection()

    return () => {
      isSectionsLoading = false
    }
  }, [setSections])

  return (
    <InternalSearchContext.Provider
      value={{
        ...state,
        updateFilters,
        setIsLoadingSearchResults,
        setSections,
        addArrayBasedInternalFilter,
        removeArrayBasedInternalFilter,
        clearArrayBasedFilter,
        clearFilters,
        setInternalPersons,
        setInternalSearchPage,
        resetFilters,
        setIsResetFilters,
        setIsLookup,
        setLookupSearchString,
        setSelectedInternalSearchPersonId,
        updatePerson,
        setIsAssignmentSelect,
        setSelectedAssignment,
        setSelectedCampaign,
        fetchAssignments,
        fetchCampaigns,
        linkToProject,
        updatePersonProject,
        updateAutoCompleteLabelList
      }}
    >
      {children}
    </InternalSearchContext.Provider>
  )
}
