export enum ModalNames {
  GDPRConsent = "gdpr-consent",
  PersonAvatar = "person-avatar",
  NoteModal = "person-note",
  AddNewPerson = "add-new-person",
  NewContact = "new-contact",
  ExitPrompt = "exit-prompt",
  MakeInactive = "make-inactive",
  MakeActive = "make-active",
  Licenses = "licenses",
  RemoveInvite = "remove-invite",
  ClientPortalShareSettings = "client-portal-share-settings",
  PortalConfigurationCandidateNoteSelection = "portal-configuration-candidate-note-selection",
  NoAssignments = "no-assignments",
  InviteColleagues = "invite-colleagues",
  Note = "note",
  Trial = "trial",
  VideoExample = "video-example",
  SaveSearch = "save-search",
  LoadSaveSearches = "load-save-searches",
  DeleteSavedSearch = "delete-saved-search",
  NoSelectedAssignmentOrCandidate = "no-selected-assignments-or-campaign",
  AddNewCampaignOrAssignment = "add-new-campaign-or-assignment",
  PersonRecordNoteModal = "person-record-note",
  PersonRecordCommentaryModal = "person-record-commentary",
  PersonRecordBiographyModal = "person-record-biography",
  NewAssignment = "new-assignment",
  EmailAttachments = "email-attachments",
  SearchWelcome = "search-welcome",
  EditAssignData = "editAssignData",
  EditNoteData = "editNoteData",
  EditCampaignData = "editcampaigndata",
  EditNoteItemData = "editNoteItemData",
  LinkAssignmentOrCampaign = "link-assignment-or-campaign",
  AddOrEditPersonNote = "add-or-edit-person-note",
  EditPersonDetails = "edit-person-details",
  EditPersonExperience = "editPersonExperience",
  DeletePersonExperience = "deletePersonExperience",
  EditKeywords = "editKeywords",
  EditPackageData = "editPackageData",
  EditFeeData = "editFeeData",
  EditOffLimits = "editOffLimits",
  EditCompanyDetails = "editCompanyDetails",
  EditInheritedOffLimits = "editInheritedOffLimits",
  PersonTeamEdit = "personTeamEdit",
  HintKeywords = "hintKeywords",
  HintJobTitles = "hintJobTitles",
  CVParsing = "cVParsingModal",
  ParsingResume = "parsingResume",
  TemplateEditModal = "templateEditModal",
  OverviewCVModal = "overviewCVModal",
  CVParsingAlertModal = "cVParsingAlertModal",
  GT2Modal = "gt2Modal",
  GenerateBioAI = "generateBioAi",
  RemoveUsers = "removeUsers",
  ParseSocialMedia = "parseSocialMedia",
  ParseText = "parseText",
  AssignAttributes = "assignAttributes",
  AttributeTitle = "AttributeTitle",
  ReassignAttributes = "reassignAttributes"
}
