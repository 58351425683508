type E2ETargets = {
  e2eTarget?: string
  e2eTargetName?: string
}

type SharedProps = {
  protected: boolean
  guestOnly?: boolean
  label: string
  icon: React.ReactElement
  e2eTargets?: E2ETargets
  disabled?: boolean
}

export type HeaderAction = SharedProps & {
  path?: string
  onClick?: VoidFunction
}

export type HeaderOption = {
  protected?: boolean
  trialOnly?: boolean
  guestOnly?: boolean
  label: string | JSX.Element
  onClick?: VoidFunction
  path?: string
  e2eTargets?: E2ETargets
  disabled?: boolean
  disableClose?: boolean
}

export type HeaderSetting = SharedProps & {
  path?: string
  onClick?: VoidFunction
  options?: HeaderOption[]
  hasPermission?: boolean
}

export type HeaderLink = SharedProps & {
  path: string
  activeLinkClassCustom?: string
}

export type HeaderHelper = {
  protected?: boolean
  trialOnly?: boolean
  icon: React.ReactElement
  label?: string
  onClick?: VoidFunction
  path?: string
  e2eTargets?: E2ETargets
}

export const navigationE2ETargets = {
  search: "search",
  profileSearch: "profile-search",
  assignmentList: "assignment-list",
  addAssignment: "add-assignment",
  profileOptions: "profile-options",
  support: "help-support",
  signOut: "sign-out",
  signIn: "sign-in",
  signUp: "sign-up",
  settingsOptions: "settings-options",
  inviteUser: "invite-user",
  team: "team-page",
  upgradePlan: "upgrade-plan",
  billing: "billing",
  campaignList: "campaign-list",
  reports: "reports",
  outlook: "outlook",
  companiesSearch: "company-search",
  settings: "settings"
}
