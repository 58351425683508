import React, { useMemo, useRef, useState, useCallback, useEffect } from "react"
import { Line, Flex, SettingsWhiteIcon } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { RouterUrl } from "setup/router/routes"
import {
  HeaderLink,
  HeaderAction,
  HeaderSetting,
  HeaderHelper,
  navigationE2ETargets
} from "components/navigation/constants/navigation.definitions"
import {
  Sidebar as StyledSidebar,
  SidebarFooter,
  SidebarMainContainer
} from "./Sidebar.styled"
import { SidebarContext } from "./SidebarContext"
import { OptionsPopup } from "./components/OptionsPopup"
import {
  ThreeDotsIcon,
  SupportWhite,
  TeamWhiteIcon as TeamIcon,
  BillingWhiteIcon as BillingIcon,
  colors
} from "@ikiru/talentis-fpc"
import {
  SidebarLogo,
  SidebarLinks,
  SidebarActions,
  SidebarHelpers,
  SidebarFooterSettings
} from "./components"

type SidebarProps = {
  menuItems: HeaderLink[]
  actions?: HeaderAction[]
  settings?: HeaderSetting[]
  helpers?: HeaderHelper[]
  homepageDisabled?: boolean
  children?: never
}
export const Sidebar = (props: SidebarProps) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isMouseOver, setIsMouseOver] = useState(false)
  const [isPopupOpen, setPopupOpen] = useState(false)
  const timeoutRef = useRef<NodeJS.Timeout>()
  const {
    menuItems,
    actions = [],
    settings = [],
    helpers = [],
    homepageDisabled
  } = props

  const toggleExpand = useCallback(
    (timeout: any, expanded: any) => (e: any) => {
      if (timeoutRef?.current) {
        clearTimeout(timeoutRef.current)
      }

      timeoutRef.current = setTimeout(() => {
        setIsExpanded(expanded)
        setIsMouseOver(expanded)
      }, timeout)
    },
    []
  )

  const options = [
    {
      protected: true,
      label: messages.navigation.links.settings,
      icon: <SettingsWhiteIcon />,
      path: RouterUrl.Settings,
      e2eTargets: {
        e2eTargetName: navigationE2ETargets.settings
      }
    },
    {
      protected: true,
      label: messages.navigation.actions.billing,
      icon: <BillingIcon />,
      path: RouterUrl.Billing,
      e2eTargets: {
        e2eTargetName: navigationE2ETargets.billing
      },
      hasPermission: true
    },
    {
      protected: true,
      label: messages.navigation.links.support,
      icon: <SupportWhite />,
      path: RouterUrl.Support,
      e2eTargets: {
        e2eTargetName: navigationE2ETargets.support
      },
      hasPermission: true
    },
    {
      protected: true,
      label: messages.auth.logout,
      path: RouterUrl.Logout,
      e2eTargets: {
        e2eTargetName: navigationE2ETargets.signOut
      }
    }
  ]
  const contextValues = useMemo(() => ({ isExpanded }), [isExpanded])

  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  const handleResize = useCallback(() => {
    setWindowHeight(window.innerHeight)
  }, [])

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [handleResize])

  const shouldDisplayOptions = windowHeight >= 394 && windowHeight <= 565
  const shouldDisplayFooter = windowHeight < 394 || windowHeight > 565

  return (
    <SidebarContext.Provider value={contextValues}>
      <StyledSidebar
        isMouseOver={isMouseOver}
        onMouseEnter={toggleExpand(1500, true)}
        onMouseLeave={toggleExpand(200, false)}
      >
        <SidebarMainContainer>
          <SidebarLogo homepageDisabled={homepageDisabled} />
          <SidebarLinks links={menuItems} />
          <SidebarActions actions={actions} />
        </SidebarMainContainer>
        <SidebarFooter>
          {shouldDisplayFooter && (
            <>
              <SidebarHelpers helpers={helpers} />
              {Boolean(settings.length) && (
                <>
                  <Line />
                  <SidebarFooterSettings settings={settings} />
                </>
              )}
            </>
          )}
          {shouldDisplayOptions && (
            <>
              <OptionsPopup
                alignSelf="center"
                display="flex"
                config={{
                  PopupProps: {
                    position: "top left",
                    onOpen: () => setPopupOpen(true),
                    onClose: () => setPopupOpen(false)
                  }
                }}
                trigger={
                  <Flex
                    p={3}
                    style={{ cursor: "pointer" }}
                    data-e2e-target-name="mobile-header-options"
                  >
                    <ThreeDotsIcon
                      fill={colors.grey.dark}
                      height={24}
                      style={{
                        transform: "rotate(90deg)",
                        textAlign: "center"
                      }}
                    >
                      {isPopupOpen}
                    </ThreeDotsIcon>
                  </Flex>
                }
                options={options || []}
              />
            </>
          )}
        </SidebarFooter>
      </StyledSidebar>
    </SidebarContext.Provider>
  )
}
