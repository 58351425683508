export const VISIBLE_PAGES = 9
export const PAGESIZE = 25

export const AssignmentNotesInfoDefaultValues = {
  isLoading: false,
  totalItemCount: 0,
  pageSize: 0,
  hasNextPage: false,
  hasPreviousPage: false,
  maxPages: 25,
  pageNumber: 0,
  pageCount: 1,
  onPageChange: () => {}
}
