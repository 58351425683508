import React, { useReducer, useCallback, useEffect, useMemo } from "react"
import { sortBy } from "lodash"
import { useNavigate, useParams } from "react-router-dom"
import {
  AssignmentsEndpoints,
  CandidatesEndpoints
} from "setup/api/endpoints/endpoints"
import { apiRequest } from "setup/api/api"
import { RouterUrl } from "setup/router/routes"
import { parseMomentDate } from "utils/moment"
import { Candidate } from "views/candidates/candidates.types"
import { InterviewProgressStage } from "views/assignments/components/candidates-list/components/candidate-record/components/InterviewProgress/definitions"
import { AssignmentContext } from "./assignment-module.context"
import {
  assignmentReducer,
  initialAssignmentState
} from "./assignment-module.reducer"
import {
  AssignmentActionTypes,
  SetAssignmentDetails,
  GetAssignmentData,
  GetAssignmentCandidates,
  SetIsLoading,
  SetCandidates,
  AddCandidate,
  ApplyFilters,
  UpdateFilters,
  CandidateFilter,
  SetNotes,
  SetSelectedCandidatedId,
  SetPage,
  SetRecordsCount,
  SetActiveStages,
  SetSelectedStage,
  SetAllAvailable,
  FilterArrayType,
  UpdateCandidate,
  PatchAssignmentData,
  SetAssignmentTemplates,
  SetCompanies,
  SetCompanyPage,
  SetCompanyPageParams,
  SetCompaniesCandidates,
  SetSelectedTab,
  UpdateCompanyCandidate,
  UpdateCompanyCandidateNote,
  CompanyStages,
  AssignmentCompany,
  SetSelectedCompany,
  SetAssignmentNotesInfo,
  AssignmentNotesInfoType,
  UpdateAssignmentNotesInfo,
  SetNotesPagination
} from "./assignment-module.types"
import {
  groupCandidates,
  hasCandidatesFiltersInSessionStorage,
  saveCandidatesFiltersToSessionStorage,
  getCandidatesFiltersFromSessionStorage,
  filterCandidates
} from "./assignment-module.helpers"
import { useTeam } from "views/team/team/team-module.context"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"
import { Tag } from "components/Tags/tags.types"
import { getAppliedFiltersParams } from "../components/candidates-list/components/candidate-filters/helper"
import { fetchPhotosAndSetUpdatedEntities } from "models/LocalPerson/localPerson.actions"
import { excludeArchive } from "../utils"
import { Assignment } from "../assignment.types"
import { AxiosResponse } from "axios"
import { AITemplatesType } from "components/ProjectActions/AITemplates/constants/definitions"
import { PersonNote } from "views/persons/components/person-notes/types"
import { normalizedCandidate, updateStage } from "./helper"
import { PaginationProps } from "components/functional/pagination/Pagination"

type AssignmentModuleProps = {
  children: React.ReactNode
}

export type AssignmentModuleLocationParams = {
  assignmentId?: string
}

//TODO: Replace fetching data with useAssignmentData() and useCandidates() hooks

export const AssignmentModule = (props: AssignmentModuleProps) => {
  const { children } = props
  const navigate = useNavigate()
  const { fetchTeamMembers } = useTeam()
  const { assignmentId = "" } = useParams<AssignmentModuleLocationParams>()
  const [state, dispatch] = useReducer(
    assignmentReducer,
    initialAssignmentState
  )

  const setIsLoading: SetIsLoading = (data) => {
    dispatch({ type: AssignmentActionTypes.SetIsLoading, payload: data })
  }

  const setSelectedCandidatedId: SetSelectedCandidatedId = (data) => {
    dispatch({
      type: AssignmentActionTypes.SetSelectedCandidatedId,
      payload: data
    })
  }

  const setIsCandidatesLoading: SetIsLoading = (data) => {
    dispatch({
      type: AssignmentActionTypes.SetIsCandidatesLoading,
      payload: data
    })
  }

  const setAssignmentDetails: SetAssignmentDetails = (data) => {
    dispatch({
      type: AssignmentActionTypes.SetAssignmentDetails,
      payload: data
    })
  }

  const setNotes: SetNotes = useCallback((data) => {
    dispatch({
      type: AssignmentActionTypes.SetNotes,
      payload: data
    })
  }, [])

  // const updatePhoto: UpdatePhoto = useCallback((candidateId, photo) => {
  //   dispatch({
  //     type: AssignmentActionTypes.UpdatePhoto,
  //     payload: { candidateId, photo }
  //   })
  // }, [])

  const setCandidates: SetCandidates = ({
    candidates,
    groupedCandidates,
    filteredCandidates
  }) => {
    dispatch({
      type: AssignmentActionTypes.SetCandidates,
      payload: { candidates, groupedCandidates, filteredCandidates }
    })
  }

  const addCandidate: AddCandidate = useCallback(
    (candidate: Candidate) => {
      dispatch({
        type: AssignmentActionTypes.AddCandidate,
        payload: { candidate }
      })
    },
    [dispatch]
  )

  const updateCandidate: UpdateCandidate = useCallback(
    (candidate: Candidate) => {
      dispatch({
        type: AssignmentActionTypes.UpdateCandidate,
        payload: { candidate }
      })
    },
    [dispatch]
  )

  const applyFilters: ApplyFilters = useCallback(
    (candidateFilters: CandidateFilter[]) => {
      dispatch({
        type: AssignmentActionTypes.ApplyFilters,
        payload: { candidateFilters }
      })
    },
    [dispatch]
  )

  // it looks like we do not use it, it should be checked during refactoring candidates filter
  const setHasStoredFilters = useCallback(
    (hasStoredFilters: boolean) => {
      dispatch({
        type: AssignmentActionTypes.SetHasStoredFilters,
        payload: hasStoredFilters
      })
    },
    [dispatch]
  )

  const setPage: SetPage = useCallback(
    (data) => {
      dispatch({
        type: AssignmentActionTypes.SetPageNumber,
        payload: data
      })
    },
    [dispatch]
  )

  const setRecordsCount: SetRecordsCount = useCallback(
    (totalItems, totalPages, filterTotalNumber) => {
      dispatch({
        type: AssignmentActionTypes.SetRecordsCount,
        payload: { totalItems, totalPages, filterTotalNumber }
      })
    },
    [dispatch]
  )

  const setActiveStage: SetActiveStages = useCallback(
    (stages) => {
      dispatch({
        type: AssignmentActionTypes.SetActiveStages,
        payload: stages
      })
    },
    [dispatch]
  )

  const setSelectedStage: SetSelectedStage = useCallback(
    (stage) => {
      dispatch({
        type: AssignmentActionTypes.SetSelectedStage,
        payload: stage
      })
    },
    [dispatch]
  )

  const setAllAvailableFilters: SetAllAvailable = useCallback(
    (data: FilterArrayType) => {
      dispatch({
        type: AssignmentActionTypes.SetAllAvailable,
        payload: data
      })
    },
    [dispatch]
  )

  const setAssignmentTemplates: SetAssignmentTemplates = useCallback(
    (data: AITemplatesType) => {
      dispatch({
        type: AssignmentActionTypes.SetAssignmentTemplates,
        payload: data
      })
    },
    [dispatch]
  )

  const setCompanies: SetCompanies = ({ companies, companiesCount }) => {
    dispatch({
      type: AssignmentActionTypes.SetCompanies,
      payload: { companies, companiesCount }
    })
  }

  const setCompanyPage: SetCompanyPage = (page: SetCompanyPageParams) => {
    dispatch({
      type: AssignmentActionTypes.SetCompanyPage,
      payload: page
    })
  }

  const setCompaniesCandidates: SetCompaniesCandidates = (
    companyId: string,
    candidates: Candidate[],
    page: SetCompanyPageParams
  ) => {
    dispatch({
      type: AssignmentActionTypes.SetCompaniesCandidates,
      payload: { companyId, candidates, page }
    })
  }

  const setSelectedTab: SetSelectedTab = (tab: string) => {
    dispatch({
      type: AssignmentActionTypes.SetSelectedTab,
      payload: tab
    })
  }

  const updateCompanyCandidate: UpdateCompanyCandidate = useCallback(
    (candidate: Candidate) => {
      dispatch({
        type: AssignmentActionTypes.UpdateCompanyCandidate,
        payload: { candidate }
      })
    },
    [dispatch]
  )

  const updateCompanyCandidateNote: UpdateCompanyCandidateNote = useCallback(
    (candidateId: string, note: PersonNote) => {
      dispatch({
        type: AssignmentActionTypes.UpdateCompanyCandidateNote,
        payload: { candidateId, note }
      })
    },
    [dispatch]
  )

  const setSelectedCompany: SetSelectedCompany = useCallback(
    (company: string) => {
      dispatch({
        type: AssignmentActionTypes.SetSelectedCompany,
        payload: company
      })
    },
    [dispatch]
  )

  const setAssignmentNotesInfo: SetAssignmentNotesInfo = useCallback(
    (data: AssignmentNotesInfoType) => {
      dispatch({
        type: AssignmentActionTypes.SetAssignmentNotesInfoType,
        payload: data
      })
    },
    [dispatch]
  )

  const updateAssignmentNotesInfo: UpdateAssignmentNotesInfo = useCallback(
    (data: Partial<AssignmentNotesInfoType>) => {
      dispatch({
        type: AssignmentActionTypes.UpdateAssignmentNotesInfo,
        payload: data
      })
    },
    [dispatch]
  )

  const setNotesPagination: SetNotesPagination = useCallback(
    (pagination: Partial<PaginationProps>) => {
      dispatch({
        type: AssignmentActionTypes.SetNotesPagination,
        payload: pagination
      })
    },
    [dispatch]
  )

  const redirectToAssignmentsList = useCallback(
    () => navigate(RouterUrl.AssignmentList),
    [navigate]
  )

  const updateCompaniesStage = useCallback(
    async (oldStage: string, candidateNew: Candidate) => {
      const companiesIds = state.companies
        ?.filter(
          (company: AssignmentCompany) => !company.id.includes("gen-uuid")
        )
        .map((elem: AssignmentCompany) => elem.id)

      const [, response] = await apiRequest.post({
        endpoint: CandidatesEndpoints.TargetCompaniesStageCounters,
        data: {
          assignmentId: assignmentId,
          companyIds: companiesIds
        }
      })

      const companyIds: string[] = []

      state.companies.forEach((company: AssignmentCompany) => {
        if (
          company.candidates.some(
            (candidate: Candidate) =>
              candidate.personId === candidateNew.personId
          )
        ) {
          companyIds.push(company.id)
        }
      })

      let companiesTemp = [...state.companies]
      companiesTemp.forEach((companyTemp: AssignmentCompany) => {
        if (companyIds.includes(companyTemp.id)) {
          companyTemp.stages = updateStage(
            oldStage,
            candidateNew.stage || "",
            companyTemp.stages
          )
        }
      })

      if (response?.data.companyStages) {
        companiesTemp.forEach((companyTemp: AssignmentCompany) => {
          response.data.companyStages.forEach((elem: CompanyStages) => {
            if (elem.companyId === companyTemp.id) {
              companyTemp.stages = elem.stages
            }
          })
        })
      }

      setCompanies({
        companies: companiesTemp,
        companiesCount: state.companiesCount
      })
    },
    [state.companies, state.companiesCount, assignmentId]
  )

  const getCandidatesRequest = useCallback(
    async (
      assignmentId: any,
      selectedStage = "all",
      params = new URLSearchParams(),
      preSetStage?: boolean
    ) => {
      params.append("assignmentId", assignmentId)
      params.append("expand", "person")
      params.append("pageSize", state.pageSize.toString())
      if (!params.has("page")) {
        params.append("page", state.pageNumber.toString())
      }
      let filterStage
      filterStage = selectedStage !== "all" ? selectedStage : null
      filterStage && !preSetStage && params.append("stages", filterStage)

      params = excludeArchive(selectedStage, params)

      const [, response] = await apiRequest.get({
        endpoint: CandidatesEndpoints.Root,
        config: { params: params }
      })

      const data = response?.data
      if (!data.candidates.length && data.count > 0) {
        params.append("Stages", "archive")
        const [, response] = await apiRequest.get({
          endpoint: CandidatesEndpoints.Root,
          config: { params: params }
        })
        return response
      }
      return response
    },
    [state.pageNumber, state.pageSize]
  )

  const getAssignmentCandidates: GetAssignmentCandidates = useCallback(
    async (
      assignmentId,
      selectedStage = "all",
      params = new URLSearchParams(),
      preSetStage?: boolean
    ) => {
      setIsCandidatesLoading(true)

      const response = await getCandidatesRequest(
        assignmentId,
        selectedStage,
        params,
        preSetStage
      )

      let candidates = response?.data?.candidates || []
      let stages = response?.data?.stages || {}
      const { count, countFiltered } = response?.data
      const filterTotalNumber = countFiltered

      const pageCount =
        filterTotalNumber > state.pageSize
          ? filterTotalNumber / state.pageSize
          : 0

      let allTabCount = Object.keys(stages!)?.reduce(
        (accumulator: any, currentValue: any) => {
          if (currentValue !== InterviewProgressStage.Archive) {
            return accumulator + stages[currentValue]
          }
          return accumulator
        },
        0
      )

      setActiveStage({ ...stages, all: allTabCount })
      setRecordsCount(count, pageCount, filterTotalNumber)

      candidates = normalizedCandidate(candidates)

      candidates = sortBy(
        candidates,
        [(candidate) => candidate.normalizedPersonData?.name.toLowerCase()],
        ["desc"]
      )

      const groupedCandidates = groupCandidates(candidates)

      setCandidates({ candidates, groupedCandidates })

      fetchPhotosAndSetUpdatedEntities(candidates, (candidates) => {
        const groupedCandidates = groupCandidates(candidates)
        setCandidates({ candidates, groupedCandidates })
      })

      setIsCandidatesLoading(false)

      return candidates
    },
    [getCandidatesRequest, state.pageSize, setRecordsCount, setActiveStage]
  )

  const updateFilters: UpdateFilters = useCallback(
    (candidateFilters: CandidateFilter[], needRefresh = false) => {
      applyFilters(candidateFilters)
      const params = getAppliedFiltersParams(candidateFilters)
      if (needRefresh) {
        params.append("page", "1")
        setPage(1)
        getAssignmentCandidates(assignmentId, state.selectedStage, params).then(
          (data: any) => {
            if (data.length === 0) {
              let currentFilters = candidateFilters
              currentFilters.pop()
              updateFilters(currentFilters, true)
            }
          }
        )
      }
      saveCandidatesFiltersToSessionStorage(candidateFilters, assignmentId)
      setHasStoredFilters(true)
    },
    [
      state.selectedStage,
      applyFilters,
      setHasStoredFilters,
      assignmentId,
      getAssignmentCandidates,
      setPage
    ]
  )

  const fetchAllAvailableByStage = useCallback(async () => {
    let params = new URLSearchParams()
    const filterStage = state.selectedStage !== "all" ? state.selectedStage : ""
    params.append("assignmentId", assignmentId)
    if (filterStage) {
      params.append("stages", filterStage)
    }

    params = excludeArchive(state.selectedStage, params)
    const [, response] = await apiRequest.get({
      endpoint: CandidatesEndpoints.AvailableFilters,
      config: {
        params: params
      }
    })

    const {
      statuses,
      assignedsTo,
      dueDates: dates,
      tags: tagIds
    } = response?.data
    const status = statuses?.map((status: any) => status.data)
    const assignedTo = assignedsTo.map((assignedId: any) => assignedId.data)
    const tags = tagIds.map((tag: any) => tag.data)
    const dueDates = dates.map((dueDates: any) => dueDates.data)
    return { status, assignedTo, tags, dueDates }
  }, [assignmentId, state.selectedStage])

  const getAssignmentData: GetAssignmentData = useCallback(
    async (assignmentId) => {
      setIsLoading(true)
      const [error, response] = await apiRequest.get({
        endpoint: AssignmentsEndpoints.Root,
        endpointParams: assignmentId,
        config: {
          headers: {
            ...skipErrorHeader
          }
        }
      })

      if (error) {
        redirectToAssignmentsList()
      } else {
        const data = response?.data

        const startDate = parseMomentDate(data?.startDate)

        setAssignmentDetails({ ...data, startDate })
        setIsLoading(false)
      }
    },
    [redirectToAssignmentsList]
  )

  const patchAssignment: PatchAssignmentData = useCallback(
    (assignmentId, patch: Partial<Assignment>) => {
      setIsLoading(true)
      return apiRequest.patch({
        endpoint: AssignmentsEndpoints.Root,
        endpointParams: assignmentId,
        data: patch,
        config: {
          headers: {
            ...skipErrorHeader
          }
        }
      }) as Promise<(AxiosResponse<any> | undefined)[]>
    },
    []
  )

  const { appliedFilters, candidates } = state

  const updateStageCount = useCallback(
    async (params: URLSearchParams, selectedStage: string) => {
      const response = await getCandidatesRequest(
        assignmentId,
        selectedStage,
        params,
        false
      )
      let stages = response?.data?.stages || {}
      let allTabCount = Object.keys(stages!)?.reduce(
        (accumulator: any, currentValue: any) => {
          if (currentValue !== InterviewProgressStage.Archive) {
            return accumulator + stages[currentValue]
          }
          return accumulator
        },
        0
      )

      return { allTabCount, stages }
    },
    [assignmentId, getCandidatesRequest]
  )

  const updateCandidateStage = useCallback(
    (candidateId: string, newCandidate: Candidate) => {
      const newStage = newCandidate.stage || "all"
      const oldCandidate: Candidate = candidates.find(
        (candidate: Candidate) => candidate.id === candidateId
      )
      const oldStage = oldCandidate.interviewProgressState?.stage || "all"

      const newStageEqualArchive = InterviewProgressStage.Archive === newStage
      const sameStage = newStage === oldStage
      const params = getAppliedFiltersParams(appliedFilters)
      const currentSelectedCount = state.activeStages[`${state.selectedStage}`]

      if (
        !(newStage === oldStage) &&
        currentSelectedCount <= 1 &&
        state.selectedStage !== "all"
      ) {
        setSelectedStage(newStage)
        setPage(1)
        params.append("page", "1")

        const paramStage = state.selectedStage !== "all" ? newStage : "all"
        getAssignmentCandidates(assignmentId, paramStage, params).then(
          (data: Candidate[]) => {
            if (data.length === 0 && appliedFilters.length > 0) {
              let currentFilters = appliedFilters
              currentFilters.pop()
              updateFilters(currentFilters, true)
            }
          }
        )
      } else {
        const newCandidates = candidates
          .map((candidate: Candidate) =>
            candidate.id === candidateId
              ? { ...candidate, ...newCandidate }
              : candidate
          )
          .filter(
            (candidate: Candidate) => candidate.assignmentId === assignmentId
          )

        if (newStageEqualArchive) {
          setActiveStage({
            ...state.activeStages,
            all: state.activeStages["all"] - 1,
            [oldStage]: state.activeStages[oldStage] - 1,
            [newStage]: state.activeStages[newStage] + 1
          })
        } else if (!sameStage) {
          setActiveStage({
            ...state.activeStages,
            all:
              oldStage === InterviewProgressStage.Archive
                ? state.activeStages["all"] + 1
                : state.activeStages["all"],
            [newStage]: state.activeStages[newStage] + 1,
            [oldStage]: state.activeStages[oldStage] - 1
          })
        }
        const filteredCandidates = filterCandidates(
          newCandidates,
          appliedFilters
        )

        if (!filteredCandidates.length) {
          let currentFilters = appliedFilters
          currentFilters.pop()
          updateFilters(currentFilters, true)
        } else {
          const groupedCandidates = groupCandidates(filteredCandidates)
          let filterCandidatesByStage = filteredCandidates
          if (state.selectedStage !== "all") {
            filterCandidatesByStage = filteredCandidates.filter(
              (candidate: Candidate) =>
                candidate.interviewProgressState?.stage === state.selectedStage
            )
          } else {
            filterCandidatesByStage = filteredCandidates.filter(
              (candidate: Candidate) =>
                candidate.interviewProgressState?.stage !==
                InterviewProgressStage.Archive
            )
          }
          const normalizedCandidateList = normalizedCandidate(
            filterCandidatesByStage
          )
          setCandidates({
            candidates: normalizedCandidateList,
            groupedCandidates,
            filteredCandidates:
              appliedFilters.length > 0 ? filteredCandidates : []
          })
        }
        fetchAllAvailableByStage().then((data) => {
          setAllAvailableFilters(data)
        })
      }
    },
    [
      fetchAllAvailableByStage,
      setAllAvailableFilters,
      state.activeStages,
      appliedFilters,
      setActiveStage,
      setPage,
      assignmentId,
      getAssignmentCandidates,
      updateFilters,
      candidates,
      setSelectedStage,
      state.selectedStage
    ]
  )

  useEffect(() => {
    let isFetching = true
    fetchAllAvailableByStage().then((data) => {
      if (isFetching) {
        setAllAvailableFilters(data)
      }
    })
    return () => {
      isFetching = false
    }
  }, [fetchAllAvailableByStage, setAllAvailableFilters])

  useEffect(() => {
    const savedFilters =
      getCandidatesFiltersFromSessionStorage()?.[assignmentId]

    state.assignmentDetails?.tags?.forEach((tag: Tag) => {
      savedFilters?.forEach((filter) => {
        if (tag.id === filter.value.tags) {
          filter.label = tag.name
          filter.filterKey = `tags${tag.name}`
        }
      })
    })

    saveCandidatesFiltersToSessionStorage(savedFilters, assignmentId)
    applyFilters(savedFilters)
  }, [applyFilters, assignmentId, state.assignmentDetails])

  const loadPreviousCandidatesFilters = useCallback(() => {
    const sessionStorageFilters =
      getCandidatesFiltersFromSessionStorage()?.[assignmentId]

    if (sessionStorageFilters) {
      applyFilters(sessionStorageFilters)
    }
  }, [applyFilters, assignmentId])

  useEffect(() => {
    const hasStoredFilters = hasCandidatesFiltersInSessionStorage()
    if (hasStoredFilters) {
      loadPreviousCandidatesFilters()
      setHasStoredFilters(hasStoredFilters)
    }
  }, [state.candidates, loadPreviousCandidatesFilters, setHasStoredFilters])

  useEffect(() => {
    fetchTeamMembers()
  }, [fetchTeamMembers])

  useEffect(() => {
    if (assignmentId) {
      getAssignmentData(assignmentId)
    }
  }, [getAssignmentData, assignmentId])

  const loadCandidates = useCallback(() => {
    if (assignmentId) {
      const sessionStorageFilters =
        getCandidatesFiltersFromSessionStorage()?.[assignmentId]
      const params = getAppliedFiltersParams(sessionStorageFilters)
      getAssignmentCandidates(assignmentId, "all", params)
    }
  }, [assignmentId, getAssignmentCandidates])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(loadCandidates, [assignmentId])

  const paginationProps = useMemo(() => {
    return {
      hasNextPage: state.pageNumber < state.totalPages,
      hasPreviousPage: state.pageNumber > 1,
      maxPages: 9,
      pageCount: state.totalPages,
      pageNumber: state.pageNumber,
      pageSize: state.pageSize,
      totalItemCount: state.filterTotalNumber
    }
  }, [
    state.pageNumber,
    state.pageSize,
    state.totalPages,
    state.filterTotalNumber
  ])

  return (
    <AssignmentContext.Provider
      value={{
        ...state,
        paginationProps,
        onPageChange: setPage,
        setSelectedCandidatedId: setSelectedCandidatedId,
        assignmentId: assignmentId,
        setAssignmentDetails: setAssignmentDetails,
        getAssignmentData: getAssignmentData,
        patchAssignmentData: patchAssignment,
        getAssignmentCandidates: getAssignmentCandidates,
        updateCandidateStage,
        updateStageCount,
        addCandidate,
        updateCandidate,
        updateFilters,
        loadPreviousCandidatesFilters,
        setNotes,
        setSelectedStage,
        setActiveStage: setActiveStage,
        setRecordsCount,
        setAssignmentTemplates,
        setAssignmentNotesInfo,
        updateAssignmentNotesInfo,
        setNotesPagination,
        setCompanies: setCompanies,
        setCompanyPage: setCompanyPage,
        setCompaniesCandidates: setCompaniesCandidates,
        setSelectedTab: setSelectedTab,
        updateCompanyCandidate: updateCompanyCandidate,
        updateCompanyCandidateNote: updateCompanyCandidateNote,
        updateCompaniesStage: updateCompaniesStage,
        setSelectedCompany: setSelectedCompany
      }}
    >
      {children}
    </AssignmentContext.Provider>
  )
}
