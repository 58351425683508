import { useEffect, useCallback, useState } from "react"
import { apiRequest } from "setup/api/api"
import { NoteEndpoint } from "setup/api/endpoints/endpoints"
import { useCampaign } from "views/campaigns/campaign-module/campaign-module.context"

const useCampaignNotes = () => {
  const [isLoading, setLoading] = useState(false)
  const { campaignId, setNotes, setNotesPagination } = useCampaign()

  const getNotes = useCallback(
    async (page: number) => {
      setLoading(true)

      const [, response] = await apiRequest.get({
        endpoint: NoteEndpoint.Root,
        config: {
          params: { campaignId, page, pageSize: 25 }
        }
      })

      if (response && response.data) {
        const { notes: notesList, ...pagination } = response.data
        const notes = notesList.map((item: any) => ({
          ...item,
          linkCreatedByUser: item.createdByUser,
          linkUpdatedByUser: item.updatedByUser
        }))
        setNotesPagination({
          ...pagination
        })
        setNotes(notes)
      } else {
        setNotes([])
      }

      setLoading(false)
    },
    [campaignId, setNotes, setNotesPagination]
  )

  const updateNotes = useCallback(async () => {
    if (!campaignId) return
    await getNotes(1)
    setNotesPagination({
      onPageChange: getNotes
    })
  }, [campaignId, getNotes, setNotesPagination])

  useEffect(() => {
    updateNotes()
  }, [updateNotes])

  return { isLoading }
}

export { useCampaignNotes }
