import React, { useMemo, useState } from "react"
import { Button, Div, Flex } from "@ikiru/talentis-fpc"
import FormikSelect from "components/functional/formik/formik-select/FormikSelect"
import { Formik, Form } from "formik"
import useGetFormData from "./hooks/useGetFormData"
import { LinkFormFields, LinkNoteToggleStatus } from "./definitions"
import { messages } from "setup/messages/messages"
import { CompanySelected } from "views/assignments/components/assignment-company/types"
import ToggleLinkStatus from "./components/ToggleLinkStatus"
import { Nullable } from "tsdef"
import { apiRequest } from "setup/api/api"
import { NoteEndpoint } from "setup/api/endpoints/endpoints"
import { LinkNotePreviewType } from "views/assignments/components/assignment-notes/components/NotesList"
import { generateLinks } from "./utils"
import PersonSearch from "./components/PersonSearch"
import { isEqual } from "lodash"
import CompanySearch from "views/assignments/components/assignment-company/CompanySearch"
import { useNavigate } from "react-router-dom"
import { NoteType } from "views/assignments/components/assignment-notes/components/NotesList/NoteAssignmentItem"

type LinkedNotesFormProps = {
  noteId?: string
  assignment?: Nullable<string>
  campaign?: Nullable<string>
  person?: Nullable<string>
  company?: Nullable<string>
  setNote: React.Dispatch<React.SetStateAction<NoteType | undefined>>
  projectId: string
  note: NoteType
  setLinks: React.Dispatch<
    React.SetStateAction<LinkNotePreviewType[] | undefined>
  >
  companyDetails:
    | {
        id: string
        name: string
      }
    | undefined
  setCompanyDetails: React.Dispatch<
    React.SetStateAction<
      | {
          id: string
          name: string
        }
      | undefined
    >
  >
  isPersonLinkedNote: boolean
  selectedCandidate: {
    id: string
    name: string
  }
  setSelectedCandidate: React.Dispatch<
    React.SetStateAction<{
      id: string
      name: string
    }>
  >
}

const LinkedNotesForm = ({
  noteId,
  setLinks,
  setNote,
  projectId,
  note,
  setCompanyDetails,
  companyDetails,
  isPersonLinkedNote,
  selectedCandidate,
  setSelectedCandidate
}: LinkedNotesFormProps) => {
  const [linkNoteToggleStatus, setLinkNoteToggleStatus] =
    useState<LinkNoteToggleStatus>(LinkNoteToggleStatus.None)

  const { allAssignments, allCampaigns } = useGetFormData()
  const navigate = useNavigate()
  const initialValues = useMemo(() => {
    return {
      assignment: note?.assignmentId || "",
      campaign: note?.campaignId || "",
      person: note?.personId || "",
      company: note?.companyId || ""
    }
  }, [note])

  const submitForm = async (values: typeof initialValues, formik: any) => {
    if (noteId) {
      const formattedValues = {
        assignmentId: values.assignment || note?.assignmentId || null,
        campaignId: values.campaign || note?.campaignId || null,
        personId: values.person || note?.personId || null,
        companyId: values.company || note?.companyId || null
      }

      const isSame = isEqual(values, initialValues)

      if (!isSame) {
        const [error, response] = await apiRequest.put({
          endpoint: NoteEndpoint.UpdateNoteLink,
          endpointParams: noteId,
          data: formattedValues
        })

        if (!error) {
          const newNoteData = response?.data
          const updatedLinks = generateLinks(newNoteData, navigate, true)

          setLinks(updatedLinks)
          setNote(newNoteData)
        } else {
          console.log(error)
        }
      }
    } else {
      const assignmentName = allAssignments?.find(
        (assignment) => assignment.value === values.assignment
      )?.label
      const campaignName = allCampaigns?.find(
        (campaign) => campaign.value === values.campaign
      )?.label
      const formattedValues = {
        assignment: { id: values.assignment || null, name: assignmentName },
        campaign: { id: values.campaign || null, name: campaignName },
        person: selectedCandidate,
        company: companyDetails
      } as NoteType

      const updatedLinks = generateLinks(formattedValues, navigate, true)
      setLinks(updatedLinks)
    }
  }

  const layoutProps = {
    width: "100%"
  }

  return (
    <Div>
      <ToggleLinkStatus
        disableAssignment={projectId === initialValues.assignment}
        disableCampaign={projectId === initialValues.campaign}
        disablePerson={projectId === initialValues.person || isPersonLinkedNote}
        disableCompany={projectId === initialValues.company}
        linkNoteToggleStatus={linkNoteToggleStatus}
        setLinkNoteToggleStatus={setLinkNoteToggleStatus}
      />
      <Formik initialValues={initialValues} onSubmit={submitForm}>
        {({ handleSubmit, setFieldValue, values }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Flex flexDirection="row" minWidth="100%">
                {linkNoteToggleStatus === LinkNoteToggleStatus.Assignment && (
                  <Flex width="100%" alignItems="center" height="50px">
                    <Div width="100%" mr="xs">
                      <FormikSelect
                        label={messages.form.generic.assignmentName}
                        disabled={false}
                        id={LinkFormFields.Assignment}
                        name={LinkFormFields.Assignment}
                        options={allAssignments}
                        defaultValue={"Select Assignment"}
                        layout={{ ...layoutProps }}
                      />
                    </Div>
                    <Div>
                      <Button
                        onClick={(e: any) => {
                          handleSubmit()
                        }}
                        type="button"
                        mode="standard-green"
                        width="150px"
                        size="small"
                        disabled={
                          values.assignment === note?.campaign?.id ||
                          values.assignment === ""
                        }
                      >
                        {messages.person.assignments.linkAssignment}
                      </Button>
                    </Div>
                  </Flex>
                )}
                {linkNoteToggleStatus === LinkNoteToggleStatus.Campaign && (
                  <Flex width="100%" alignItems="center">
                    <Div width="100%" mr="xs">
                      <FormikSelect
                        status="default"
                        label={messages.person.campaigns.campaingName}
                        id={LinkFormFields.Campaigns}
                        name={LinkFormFields.Campaigns}
                        options={allCampaigns}
                        layout={{ ...layoutProps }}
                      />
                    </Div>
                    <Div>
                      <Button
                        onClick={(e: any) => {
                          handleSubmit()
                        }}
                        type="button"
                        mode="standard-green"
                        width="150px"
                        size="small"
                        disabled={
                          values.campaign === note?.campaign?.id ||
                          values.campaign === ""
                        }
                      >
                        {messages.person.campaigns.linkCampaign}
                      </Button>
                    </Div>
                  </Flex>
                )}
                {linkNoteToggleStatus === LinkNoteToggleStatus.Person && (
                  <PersonSearch
                    onLink={(personId: string) => {
                      setFieldValue("person", personId)
                      handleSubmit()
                    }}
                    selectedCandidate={selectedCandidate}
                    setSelectedCandidate={setSelectedCandidate}
                  />
                )}
                {linkNoteToggleStatus === LinkNoteToggleStatus.Company && (
                  <Flex width="100%" alignItems="center">
                    <Div width="100%" mr="xs">
                      <CompanySearch
                        id={LinkFormFields.Company}
                        name={LinkFormFields.Company}
                        label={messages.form.generic.enterCompany}
                        onClickItem={(company: CompanySelected) => {
                          //Workaround delete after 131610
                          setCompanyDetails({
                            id: company.id || "",
                            name: company.name || ""
                          })
                        }}
                      />
                    </Div>
                    <Div>
                      <Button
                        onClick={(e: any) => {
                          setFieldValue("company", companyDetails?.id)
                          handleSubmit()
                        }}
                        type="button"
                        mode="standard-green"
                        width="150px"
                        size="small"
                        disabled={!companyDetails?.id}
                      >
                        Link company
                      </Button>
                    </Div>
                  </Flex>
                )}
              </Flex>
            </Form>
          )
        }}
      </Formik>
    </Div>
  )
}

export default LinkedNotesForm
