import { CrossSmallIcon, Div, Flex } from "@ikiru/talentis-fpc"
import React from "react"
import { LinkNotePreviewType } from "views/assignments/components/assignment-notes/components/NotesList"
import { LinkWrapper, LinkStyled } from "./style"
import { NoteEndpoint } from "setup/api/endpoints/endpoints"
import { apiRequest } from "setup/api/api"
import { generateLinks } from "../utils"
import { useNavigate } from "react-router-dom"
import { NoteType } from "views/assignments/components/assignment-notes/components/NotesList/NoteAssignmentItem"

type PreviewLinksProps = {
  noteId?: string
  links: LinkNotePreviewType[] | undefined
  setNote: any
  projectId: string
  note: NoteType
  setLinks: React.Dispatch<
    React.SetStateAction<LinkNotePreviewType[] | undefined>
  >
  isPersonLinkedNote?: boolean
}

const PreviewLinks = ({
  noteId,
  links,
  note,
  setNote,
  projectId,
  setLinks,
  isPersonLinkedNote = false
}: PreviewLinksProps) => {
  const navigate = useNavigate()

  const onDelete = async (deleteId: string) => {
    const initialValues = {
      assignmentId: note?.assignmentId || null,
      campaignId: note?.campaignId || null,
      personId: note?.personId || null,
      companyId: note?.companyId || null
    }

    const formattedValues: any = {}

    Object.entries(initialValues).forEach(([key, value]) => {
      if (value === deleteId) {
        formattedValues[key] = null
      } else {
        formattedValues[key] = value
      }
    })

    const [error, response] = await apiRequest.put({
      endpoint: NoteEndpoint.UpdateNoteLink,
      endpointParams: noteId,
      data: formattedValues
    })

    if (!error) {
      const newNoteData = response?.data

      const updatedLinks = generateLinks(newNoteData, navigate, true)
      setLinks(updatedLinks)
      setNote(newNoteData)
    }
  }

  return (
    <Flex mt="xs" flexWrap="wrap">
      {links &&
        links.map((link) => (
          <LinkWrapper key={link.id}>
            <Div>{link.Icon}</Div>
            <LinkStyled size="small" style={{ cursor: "default" }}>
              {link.label}
            </LinkStyled>
            {!(
              projectId === link.id ||
              (isPersonLinkedNote && link.type === "person")
            ) && (
              <Div
                ml="xxs"
                style={{ cursor: "pointer" }}
                onClick={() => onDelete(link.id as string)}
              >
                <CrossSmallIcon />
              </Div>
            )}
          </LinkWrapper>
        ))}
    </Flex>
  )
}

export default PreviewLinks
