import { Div, Line, Flex } from "@ikiru/talentis-fpc"
import { Pagination } from "components/functional/pagination/Pagination"
import { generateLinks } from "components/Notes/components/forms/utils"
import { StyledNotesList } from "components/Notes/components/style"
import React, { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { useCompany } from "views/companies/company-module/company-module.context"

import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { NoteEditForm } from "components/Notes/components/forms/NoteEditForm"
import { NoteType } from "views/assignments/components/assignment-notes/components/NotesList/NoteAssignmentItem"
import NoteCompanyItem from "./NoteCompanyItem"

const CompanyNotesList = () => {
  const { notes, setNotes, notesPagination } = useCompany()

  const { open, close } = useModal()
  const navigate = useNavigate()

  const onSubmit = useCallback(
    (newNoteItem: any) => {
      const updatedNote = {
        ...newNoteItem,
        linkCreatedByUser: newNoteItem.createdByUser,
        linkUpdatedByUser: newNoteItem.updatedByUser
      }

      const newNotes = notes.map((item) => {
        return item.id === updatedNote.id ? updatedNote : item
      })

      setNotes(newNotes)
      close(ModalNames.EditNoteData)
    },
    [close, notes, setNotes]
  )

  const noteItemOpenModal = useCallback(
    (note: NoteType) => {
      const {
        noteTitle,
        noteDescription,
        id,
        assignmentId,
        campaignId,
        personId,
        companyId
      } = note
      open(
        ModalNames.EditNoteData,
        <Div mb="xs">
          <NoteEditForm
            initialValues={{
              noteTitle,
              noteDescription
            }}
            notes={notes}
            projectId={companyId as string}
            setNotes={setNotes as any}
            noteId={id}
            links={generateLinks(note)}
            linkInitialValue={{
              assignment: assignmentId,
              campaign: campaignId,
              person: personId,
              company: companyId
            }}
            onSubmit={onSubmit}
          />
        </Div>
      )
    },
    [notes, onSubmit, open, setNotes]
  )

  return (
    <StyledNotesList>
      <Div>
        {notes.map((note, index) => (
          <Div key={`note_${note?.id}`}>
            {index !== 0 && <Line variant="thin" />}
            <NoteCompanyItem
              {...{
                note,
                links: generateLinks(note, navigate),
                openModal: noteItemOpenModal
              }}
            />
          </Div>
        ))}
        {notesPagination.totalItemCount > 25 && (
          <Flex width="100%" justifyContent="center" mb={20}>
            <Pagination
              {...notesPagination}
              onPageChange={notesPagination.onPageChange}
            />
          </Flex>
        )}
      </Div>
    </StyledNotesList>
  )
}

export default CompanyNotesList
