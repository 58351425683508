import { useCallback, useEffect, useState } from "react"
import { apiRequest } from "setup/api/api"
import { NoteEndpoint } from "setup/api/endpoints/endpoints"
import { useCompany } from "views/companies/company-module/company-module.context"

const useCompanyNotes = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { companyId, setNotes, setNotesPagination } = useCompany()
  const getNotes = useCallback(
    async (page: number) => {
      const [, response] = await apiRequest.get({
        endpoint: NoteEndpoint.Root,
        config: {
          params: { companyId, page, pageSize: 25 }
        }
      })

      if (response && response.data) {
        const { notes: notesList, ...pagination } = response.data
        const notes = notesList.map((item: any) => ({
          ...item,
          linkCreatedByUser: item.createdByUser,
          linkUpdatedByUser: item.updatedByUser
        }))
        setNotesPagination({
          ...pagination
        })
        setNotes(notes)
      } else {
        setNotes([])
      }
    },
    [companyId, setNotes, setNotesPagination]
  )

  const updateNotes = useCallback(async () => {
    if (!companyId) return
    setIsLoading(true)
    await getNotes(1)
    setNotesPagination({
      onPageChange: getNotes
    })
    setIsLoading(false)
  }, [companyId, getNotes, setNotesPagination])

  useEffect(() => {
    updateNotes()
  }, [updateNotes])

  return {
    isLoading
  }
}

export default useCompanyNotes
