import React, { useEffect, useState } from "react"
import { Formik, Form } from "formik"
import { addNoteValidationSchema } from "./schema"
import { NoteAddFormProps } from "./types"
import NoteFormLayout from "./NoteFormLayout"
import { initialDefaultNoteState } from "./definitions"
import { StyledDiv } from "../style"
import { Div, Flex } from "@ikiru/talentis-fpc"
import PreviewLinks from "./components/PreviewLinks"
import LinkedNotesForm from "./LinkedNotesForm"
import { LinkNotePreviewType } from "views/assignments/components/assignment-notes/components/NotesList"
import { NoteType } from "views/assignments/components/assignment-notes/components/NotesList/NoteAssignmentItem"
import { OnAddNoteSubmitType } from "components/Notes/types"
import { generateLinks } from "./utils"
import { useNavigate } from "react-router-dom"
import { apiRequest } from "setup/api/api"
import { NoteEndpoint } from "setup/api/endpoints/endpoints"
import { callWithErrorMessages } from "utils/forms/forms"

const NoteAddForm = ({
  projectId,
  projectType,
  projectName,
  formActionsComponent,
  onSubmit,
  personLinkedNote
}: NoteAddFormProps) => {
  const navigation = useNavigate()
  const [note, setNote] = useState<NoteType | undefined>({
    ...({ [projectType + "Id"]: projectId } as any),
    ...(personLinkedNote?.id
      ? { person: personLinkedNote, personId: personLinkedNote.id }
      : {}),
    ...({ [projectType]: { id: projectId, name: projectName } } as any)
  })
  const [links, setLinks] = useState<LinkNotePreviewType[]>()
  const [companyDetails, setCompanyDetails] = useState<{
    id: string
    name: string
  }>(
    projectType === "company"
      ? { id: projectId, name: projectName }
      : { id: "", name: "" }
  )

  const [selectedCandidate, setSelectedCandidate] = useState({
    ...(personLinkedNote
      ? personLinkedNote
      : projectType === "person"
      ? { id: projectId, name: projectName }
      : { id: "", name: "" })
  })

  useEffect(() => {
    if (note) setLinks(generateLinks(note, navigation, true))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmitForm: OnAddNoteSubmitType = async (values, helpers) => {
    const linksObjectArray = links?.reduce((acc, curr) => {
      acc[`${curr.type}Id`] = curr.id
      return acc
    }, {} as any)

    const formattedValues = {
      assignmentId: null,
      campaignId: null,
      personId: null,
      companyId: null,
      ...linksObjectArray,
      noteTitle: values.noteTitle,
      noteDescription: values.noteDescription
    }

    const [error, response] = await apiRequest.post({
      endpoint: NoteEndpoint.Root,
      data: formattedValues
    })
    if (error) {
      callWithErrorMessages(helpers.setFieldError, error)
      helpers.setSubmitting(false)
    } else {
      onSubmit(response?.data)
    }
  }

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      width="100vw"
      height="100vh"
    >
      <StyledDiv>
        <Formik
          initialValues={initialDefaultNoteState}
          validationSchema={addNoteValidationSchema}
          onSubmit={onSubmitForm}
        >
          {(formik) => {
            return (
              <Form>
                {formActionsComponent?.(formik)}
                <NoteFormLayout />
              </Form>
            )
          }}
        </Formik>
        <PreviewLinks
          setNote={setNote}
          note={note!}
          setLinks={setLinks}
          links={links}
          projectId={projectId}
          isPersonLinkedNote={Boolean(personLinkedNote?.id)}
        />
        <Div minWidth="100%">
          <LinkedNotesForm
            setLinks={setLinks}
            setNote={setNote}
            note={note!}
            companyDetails={companyDetails}
            setCompanyDetails={setCompanyDetails as any}
            projectId={projectId}
            isPersonLinkedNote={Boolean(personLinkedNote?.id)}
            selectedCandidate={selectedCandidate}
            setSelectedCandidate={setSelectedCandidate}
          />
        </Div>
      </StyledDiv>
    </Flex>
  )
}

export default React.memo(NoteAddForm)
