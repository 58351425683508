import React, { useMemo } from "react"
import { Flex, Tag, Div, colors, SmallText, H5, H6 } from "@ikiru/talentis-fpc"
import { StyledRemoveButton } from "views/internal-search/InternalSearchModule/style"
import { ModalNames } from "setup/modal/modal.definitions"
import { useModal } from "utils/hooks/use-modal"
import AssignAttributes from "../assign-attributes/AssignAttributes"
import { StyledPlusIcon } from "../../styles"
import PersonKeywords from "./PersonKeywords"
import { usePerson } from "views/persons/person-module/candiate-module.context"
//import { getCheckedLeafNodeIds, removeNodeByKey } from "../../helper"
//import { assignAttributes } from "../../actions"
import { Attribute } from "views/attributes/attributes-module/attributes-module.types"

const AttributesListTree = () => {
  const { attributes } = usePerson()

  const { openInPortal } = useModal()

  const assignAttributesModal = (keyNode: string) => {
    openInPortal(
      ModalNames.AssignAttributes,
      <AssignAttributes keyNode={keyNode} />
    )
  }

  //do not remove it just in case we decide to return removing
  // const removeAttributes = useCallback(
  //   async (key: string) => {
  //     const newAttributes = removeNodeByKey(attributes, key)
  //     const ids = getCheckedLeafNodeIds(newAttributes)

  //     const updatedAttributes = await assignAttributes(personId, ids)
  //     updateAttributes(updatedAttributes)
  //   },
  //   [attributes, personId, updateAttributes]
  // )

  const renderTree = (nodes, path = "") => {
    return nodes.map((node) => {
      if (!node.isChecked) {
        return null
      }

      const currentPath = path ? `${path} • ${node.title}` : node.title

      if (
        node.children &&
        Array.isArray(node.children) &&
        node.children.length > 0
      ) {
        if (node.children.every((child) => !child.children)) {
          const checkedChildren = node.children.filter(
            (child) => child.isChecked
          )

          if (checkedChildren.length > 0) {
            return (
              <H6
                mt="0"
                mb="0"
                mr="0"
                fontSize="14px"
                key={node.key}
                color={colors.green.darkest}
              >
                {currentPath} {"•"}
                {checkedChildren.map((leaf) => (
                  <Tag
                    m="0px"
                    mb="5px"
                    ml="5px"
                    variant="green"
                    key={leaf.key}
                    // do not remove it just in case we decide to return removing
                    //  onRemoveClick={() => removeAttributes(leaf.key)}
                  >
                    {leaf.title}
                  </Tag>
                ))}
              </H6>
            )
          }

          return null
        }

        const children = renderTree(node.children, currentPath)
        if (children) {
          return <React.Fragment key={node.key}>{children}</React.Fragment>
        }

        return null
      }

      if (node.isChecked && !node.children.length) {
        return (
          <H6
            mt="0"
            mb="0"
            mr="0"
            fontSize="14px"
            key={node.key}
            color={colors.green.darkest}
          >
            {path && `${path} • `}
            <Tag
              key={node.key}
              m="0px"
              mb="5px"
              variant="green"
              // do not remove it just in case we decide to return removing
              //onRemoveClick={() => removeAttributes(node.key)}
            >
              {node.title}
            </Tag>
          </H6>
        )
      }

      return null
    })
  }

  const displayItems = useMemo(() => {
    return attributes?.map((attribute) => ({
      title: `${attribute.title} `,
      nodeKey: attribute.key,
      children: renderTree(attribute.children || [attribute])
    }))
  }, [attributes])

  return (
    <>
      {displayItems?.map((attribute: any) => (
        <Div key={attribute.nodeKey} mb="20px">
          <Flex alignItems="center" key={attribute.nodeKey} mb="5px">
            <H5 my="0" mr="5px">
              {attribute.title}
            </H5>
            <StyledRemoveButton
              mode="standard-white"
              size="action-small"
              onClick={() => assignAttributesModal(attribute.nodeKey)}
            >
              <StyledPlusIcon />
            </StyledRemoveButton>
          </Flex>
          <></>

          {attribute.children?.every((item: Attribute) => item === null) ? (
            <SmallText m="0" pb="20px" color={colors.grey.standard}>
              No {attribute.title} assigned
            </SmallText>
          ) : (
            <>{attribute.children}</>
          )}
        </Div>
      ))}
      <PersonKeywords />
    </>
  )
}

export default AttributesListTree
