import React, {
  useEffect,
  useMemo,
  KeyboardEvent,
  FocusEvent,
  useState
} from "react"
import { Div, Input } from "@ikiru/talentis-fpc"
import {
  DropDownContainer,
  DropDownItem,
  DropDownItemContainer
} from "./styles"

import { LocalSearchEndpoints } from "setup/api/endpoints/endpoints"
import { SectionIds } from "./type"
import { apiRequest } from "setup/api/api"
import { sectionFieldSearchValuesTemplateString } from "./consts"
import { useInternalSearch } from "views/internal-search/InternalSearchModule/context"
import { get } from "lodash"
import { useArrayFieldAutoCompleteChange } from "views/internal-search/hooks"
import { PreferredList } from "../PreferredList"

type OptionsType = { id: string; value: string }
type AutoCompleteAttributesFieldProps = {
  index: number
  indexSection: number
  sectionId: SectionIds
  fieldId: any
}

const AutoCompleteAttributesField = ({
  index,
  indexSection,
  sectionId,
  fieldId
}: AutoCompleteAttributesFieldProps) => {
  const [searchValue, setSearchValue] = useState("")
  const [searchResults, setSearchResults] = useState<OptionsType[]>([])
  const [selectedValues, setSelectedValues] = useState<
    { value: string; label: string }[]
  >([])

  const {
    removeArrayBasedInternalFilter,
    filters,
    updateAutoCompleteLabelList,
    updateFilters,
    autoCompleteLabelList
  } = useInternalSearch()

  const fieldSearchValuesName = sectionFieldSearchValuesTemplateString.format(
    indexSection,
    index
  )
  const currentSelectValues = get(filters, fieldSearchValuesName, [])

  const handleSelectValues = useArrayFieldAutoCompleteChange({
    field: fieldSearchValuesName
  })

  const handleSelectValue = (attribute: string) => {
    updateFilters({
      [fieldSearchValuesName]: [attribute]
    })
  }

  const isValues = useMemo(() => {
    const condition =
      //@ts-ignore
      filters?.sections?.[indexSection]?.fields?.[index]?.condition
    return ["any", "none", "all"].includes(condition)
  }, [filters, indexSection, index])

  useEffect(() => {
    const fetchAttribute = async () => {
      const [, response] = await apiRequest.get({
        endpoint: LocalSearchEndpoints.LocalSearchPropertiesAutocomplete,
        endpointParams: { sectionId, fieldId },
        config: { params: { searchString: searchValue } }
      })

      const results = response?.data?.items || []
      setSearchResults(results)
    }

    if (searchValue) {
      fetchAttribute()
    } else {
      setSearchResults([])
    }
  }, [currentSelectValues, fieldId, searchValue, sectionId])

  const isSingleSearch = useMemo(() => {
    return !isValues && Boolean(currentSelectValues[0])
  }, [currentSelectValues, isValues])

  const onSelect = (newValue: OptionsType) => {
    setSearchResults([])
    setSearchValue("")
    isSingleSearch
      ? handleSelectValue(newValue.id)
      : handleSelectValues(newValue.id)
    const formattedSelectedValue = { label: newValue.value, value: newValue.id }
    updateAutoCompleteLabelList({
      [fieldSearchValuesName]: [formattedSelectedValue, ...selectedValues]
    })
    setSelectedValues([formattedSelectedValue, ...selectedValues])
  }

  const onPressInput = (
    e: KeyboardEvent<HTMLInputElement> | FocusEvent<HTMLInputElement>
  ) => {
    if ((e as KeyboardEvent<HTMLInputElement>).key === "Enter") {
      e.preventDefault()
      if (searchResults.length) {
        onSelect(searchResults[0])
      }
    }
  }

  return (
    <Div minWidth="250px">
      <Input
        label="Search text"
        variant="middle"
        disabled={isSingleSearch}
        placeholder="Search text"
        value={searchValue}
        onKeyPress={
          onPressInput as (e: React.KeyboardEvent<HTMLInputElement>) => void
        }
        onChange={(e: any) => {
          setSearchValue(e.target.value)
        }}
      />
      <Div position="relative">
        {Boolean(searchResults.length) && (
          <DropDownContainer>
            {searchResults.map((result) => {
              return (
                <DropDownItemContainer
                  onClick={() => onSelect(result)}
                  key={result.id}
                >
                  <DropDownItem>{result.value}</DropDownItem>
                </DropDownItemContainer>
              )
            })}
          </DropDownContainer>
        )}
      </Div>
      <PreferredList
        keywords={currentSelectValues}
        valueForSelect={autoCompleteLabelList[fieldSearchValuesName]}
        onKeywordRemove={(value) =>
          removeArrayBasedInternalFilter(fieldSearchValuesName, value)
        }
      />
    </Div>
  )
}

export default AutoCompleteAttributesField
