import React, { useReducer, useCallback, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { CompanyEndpoints } from "setup/api/endpoints/endpoints"
import { apiRequest } from "setup/api/api"
import { RouterUrl } from "setup/router/routes"
import { parseMomentDate } from "utils/moment"
import { companyReducer, initialCompanyState } from "./company-module.reducer"
import {
  CompanyActionTypes,
  SetCompanyDetails,
  SetIsLoading,
  GetCompanyData,
  SetSelectedKnownEmployeeId,
  SetOffLimits,
  SetPersonRecordOverlayUpdated,
  SetNotes,
  SetNotesPagination
} from "./company-module.types"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"
import { CompanyContext } from "./company-module.context"
import { useTeam } from "views/team/team/team-module.context"
import { PaginationProps } from "components/functional/pagination/Pagination"

type CompanyModuleProps = {
  children: React.ReactNode
}

export type CompanyModuleLocationParams = {
  companyId?: string
}

export const CompanyModule = (props: CompanyModuleProps) => {
  const { children } = props
  const { fetchTeamMembers } = useTeam()

  const { companyId = "" } = useParams<CompanyModuleLocationParams>()

  const navigate = useNavigate()

  const [state, dispatch] = useReducer(companyReducer, initialCompanyState)

  const setIsLoading: SetIsLoading = (data) => {
    dispatch({ type: CompanyActionTypes.SetIsLoading, payload: data })
  }

  const setSelectedKnownEmployeeId: SetSelectedKnownEmployeeId = (
    data: string
  ) => {
    dispatch({
      type: CompanyActionTypes.SetSelectedKnownEmployeeId,
      payload: data
    })
  }

  const setCompanyDetails: SetCompanyDetails = (data) => {
    dispatch({
      type: CompanyActionTypes.SetCompanyDetails,
      payload: data
    })
  }

  const setOffLimits: SetOffLimits = (data) => {
    dispatch({
      type: CompanyActionTypes.SetOffLimits,
      payload: data
    })
  }

  const setPersonRecordOverlayUpdated: SetPersonRecordOverlayUpdated = (
    data: boolean
  ) => {
    dispatch({
      type: CompanyActionTypes.SetPersonRecordOverlayUpdated,
      payload: data
    })
  }

  const setNotes: SetNotes = useCallback((data) => {
    dispatch({
      type: CompanyActionTypes.SetNotes,
      payload: data
    })
  }, [])

  const setNotesPagination: SetNotesPagination = useCallback(
    (pagination: Partial<PaginationProps>) => {
      dispatch({
        type: CompanyActionTypes.SetNotesPagination,
        payload: pagination
      })
    },
    [dispatch]
  )

  const redirectToCompanyList = useCallback(
    () => navigate(RouterUrl.CompanyList),
    [navigate]
  )

  const getCompanyData: GetCompanyData = useCallback(
    async (companyId) => {
      setIsLoading(true)
      const [error, response] = await apiRequest.get({
        endpoint: CompanyEndpoints.Root,
        endpointParams: companyId,
        config: {
          headers: {
            ...skipErrorHeader
          }
        }
      })

      if (error) {
        redirectToCompanyList()
      } else {
        const data = response?.data

        const startDate = parseMomentDate(data?.startDate)
        setCompanyDetails({ ...data, startDate })

        const endDate = parseMomentDate(data.offlimits?.endDate)
        setOffLimits({ ...data.offlimits, endDate })

        setIsLoading(false)
      }
    },
    [redirectToCompanyList]
  )

  useEffect(() => {
    if (companyId) {
      getCompanyData(companyId)
    }
  }, [getCompanyData, companyId])

  useEffect(() => {
    fetchTeamMembers()
  }, [fetchTeamMembers])

  return (
    <CompanyContext.Provider
      value={{
        ...state,
        setNotes,
        setNotesPagination,
        companyId: companyId,
        setCompanyDetails: setCompanyDetails,
        getCompanyData: getCompanyData,
        setSelectedKnownEmployeeId: setSelectedKnownEmployeeId,
        setOffLimits: setOffLimits,
        setPersonRecordOverlayUpdated: setPersonRecordOverlayUpdated
      }}
    >
      {children}
    </CompanyContext.Provider>
  )
}
