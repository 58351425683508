import { Nullable } from "tsdef"
import { PersonProfileDetails } from "views/persons/pages/manage-person/person-profile.types"
import { PersonPhoto } from "views/persons/components/person-avatar/constants/person-avatar.definitions"
import { PersonDocuments } from "views/persons/components/person-documnets/types"
import { Experience } from "views/persons/components/person-experience/types"
import { Candidate } from "views/candidates/candidates.types"
import { PersonNote } from "views/persons/components/person-notes/types"
import { Contact } from "views/campaigns/components/contacts/type"
import { DefaultEditedField } from "../../../components/EditableDetails/editable-details.types"
import {
  Experience as ProfExperience,
  PersonTeamMember,
  Person,
  CVParsing,
  GdprLawfulBasisState
} from "../person.types"
import { AxiosError, AxiosResponse } from "axios"
import { OffLimitsType } from "components/OffLimits/type"
import { ItemDataType } from "components/FPCComponents/DragAndDrop/constants/types"
import { Attribute } from "views/attributes/attributes-module/attributes-module.types"
import { PaginationProps } from "components/functional/pagination/Pagination"

export type Keyword = {
  id: Nullable<string>
  value: string
  keywordCategoryId?: Nullable<string>
}

export type PersonState = {
  isLoading: boolean
  profileDetails: PersonProfileDetails
  profilePrivacy: Nullable<GdprLawfulBasisState>
  documents: PersonDocuments
  keywords: Keyword[]
  candidates: { [key: string]: Candidate }
  photo: Nullable<PersonPhoto>
  notes: PersonNote[]
  notesPagination: PaginationProps
  experience: Experience
  contacts: { [key: string]: Contact }
  personOverlayUpdated: boolean
  personOverlayNoteUpdated: boolean
  personOverlayPhotoUpdated: boolean
  defaultEditedField: DefaultEditedField
  linkedinEditMode: boolean
  createdDate: Date | string
  offLimits: OffLimitsType
  teamMembers: PersonTeamMember[]
  person?: Person
  cvParsing: CVParsing
  tempCvParsingData?: Person
  activeTab: number
  completedCVSteps: {
    [k: number]: boolean
  }
  currentHoveredItem: string
  dragItems: ItemDataType[]
  previousButtonClicked: string | number
  attributes: Attribute[]
  changedNodes: Attribute[]
  hiddenAttributesIds: string[]
}

type PartialPersonState = {
  profileDetails: PersonProfileDetails
  profilePrivacy: Nullable<GdprLawfulBasisState>
  createdDate: Date | string
  person: Person
}

export enum PersonActionTypes {
  SetIsLoading = "setIsLoading",
  SetProfileDetails = "setProfileDetails",
  SetPhoto = "setPhoto",
  SetKeywords = "setKeywords",
  SetProfilePrivacy = "setProfilePrivacy",
  SetProfileData = "setProfileData",
  SetDocuments = "setDocuments",
  SetCandidate = "setCandidate",
  SetCandidates = "setCandidates",
  SetNotes = "setNotes",
  SetExperience = "setExperience",
  SetProfileAvatar = "setAvatar",
  UpdateCandidateStage = "updateCandidateStage",
  SetContacts = "setContacts",
  SetContact = "setContact",
  SetPersonOverlayUpdated = "setPersonOverlayUpdated",
  SetPersonOverlayNoteUpdated = "setPersonOverlayNoteUpdated",
  setDefaultFocusedField = "setDefaultFocusedField",
  setLinkedinEditMode = "setLinkedinEditMode",
  SetOffLimits = "setOffLimits",
  SetTeamMembers = "setTeamMembers",
  SetCreatedDate = "setCreatedDate",
  SetPersonOverlayPhotoUpdated = "setPersonOverlayPhotoUpdated",
  SetCVParsing = "setCVParsing",
  SetTempCvParsingData = "setTempCvParsingData",
  SetActiveTab = "setActiveTab",
  SetCompletedCVSteps = "setCompletedCVSteps",
  SetCurrentHoveredItem = "setCurrentHoveredItem",
  SetDragItems = "setDragItems",
  SetPreviousButtonClicked = "setPreviousButtonClicked",
  SetAttributes = "setAttributes",
  UpdateAttributes = "updateAttributes",
  SetChangedNodes = "setChangedNodes",
  SetHiddenAttributes = "setHiddenAttributes",
  SetNotesPagination = "setNotesPagination"
}

export type PersonContextValues = PersonState & {
  personId: string
  setProfileDetails: SetProfileDetails
  setProfilePrivacy: SetProfilePrivacy
  setKeywords: SetKeywords
  setPhoto: SetPhoto
  getProfileData: GetProfileData
  setDocuments: SetDocuments
  setCandidates: SetCandidates
  setCandidate: SetCandidate
  setNotes: SetNotes
  setExperience: SetExperience
  getProfileAvatar: GetProfileAvatar
  updateCandidateStage: UpdateCandidateStage
  setContacts: SetContacts
  setContact: SetContact
  updateContactStage: UpdateContactStage
  setPersonOverlayUpdated: SetPersonOverlayUpdated
  setDefaultFocusedField: setDefaultFocusedField
  setLinkedinEditMode: setLinkedinEditMode
  deleteExperience: DeleteExperience
  editExperience: EditExperience
  createExperience: CreateExperience
  setIsLoading: SetIsLoading
  setOffLimits: SetOffLimits
  setTeamMembers: SetTeamMembers
  setCreatedDate: SetCreatedDate
  setPersonOverlayNoteUpdated: SetPersonOverlayNoteUpdated
  setPersonOverlayPhotoUpdated: SetPersonOverlayPhotoUpdated
  setCVParsing: SetCVParsing
  setTempCvParsingData: SetTempCvParsingData
  setActiveTab: SetActiveTab
  setCompletedCVSteps: SetCompletedCVSteps
  setCurrentHoveredItem: SetCurrentHoveredItem
  setDragItems: SetDragItems
  setPreviousButtonClicked: SetPreviousButtonClicked
  setAttributes: SetAttributes
  updateAttributes: UpdateAttributes
  setChangedNodes: SetChangedNodes
  setNotesPagination: SetNotesPagination
}

export type SetIsLoading = (data: boolean) => void
export type SetPhoto = (data: Nullable<PersonPhoto>) => void
export type SetProfileDetails = (data: PersonProfileDetails) => void
export type SetProfilePrivacy = (data: GdprLawfulBasisState) => void
export type SetKeywords = (data: Keyword[]) => void
export type SetDocuments = (data: PersonDocuments) => void
export type GetProfileData = (personId: string) => void
export type SetProfileData = (data: PartialPersonState) => void
export type SetCreatedDate = (data: Date | string) => void
export type SetCandidate = (data: Candidate) => void
export type SetCandidates = (data: PersonState["candidates"]) => void
export type SetNotes = (data: PersonNote[]) => void
export type SetExperience = (data: Experience) => void
export type ExperienceResponse = [
  AxiosError | undefined,
  (
    | AxiosResponse<{
        experience: ProfExperience[]
        jobTitle: string
        company: string
        companyId: string
      }>
    | undefined
  )
]
export type DeleteExperience = (
  personId: string,
  experienceId: string
) => Promise<ExperienceResponse>

export type EditExperience = (
  personId: string,
  experienceId: string,
  data: Partial<ProfExperience>
) => Promise<ExperienceResponse>
export type CreateExperience = (
  personId: string,
  data: Partial<ProfExperience>
) => Promise<ExperienceResponse>
export type GetProfileAvatar = (personId: string) => void
export type SetProfileAvatar = (photo: PersonPhoto) => void
export type UpdateCandidateStage = (
  candidateId: string,
  newCandidate: Candidate
) => void
export type SetContacts = (data: PersonState["contacts"]) => void
export type SetContact = (data: Contact) => void
export type UpdateContactStage = (
  contactId: string,
  newContact: Contact
) => void
export type SetPersonOverlayUpdated = (data: boolean) => void
export type SetPersonOverlayNoteUpdated = (data: boolean) => void
export type SetPersonOverlayPhotoUpdated = (data: boolean) => void
export type setDefaultFocusedField = (data: DefaultEditedField) => void
export type setLinkedinEditMode = (data: boolean) => void
export type SetOffLimits = (data: OffLimitsType) => void
export type SetTeamMembers = (data: PersonTeamMember[]) => void
export type SetCVParsing = (data: CVParsing) => void
export type SetTempCvParsingData = (data?: Person) => void
export type SetActiveTab = (activeTab: number) => void
export type SetCompletedCVSteps = (activeTab: { [k: number]: boolean }) => void
export type SetCurrentHoveredItem = (data: string) => void
export type SetDragItems = (dragItems: ItemDataType[]) => void
export type SetPreviousButtonClicked = (data: string | number) => void
export type SetAttributes = (attributes: Attribute[]) => void
export type UpdateAttributes = (attributes: Attribute[]) => void
export type SetChangedNodes = (attributes: Attribute[]) => void
export type SetHiddenAttributes = (ids: string[]) => void
export type SetNotesPagination = (pagination: Partial<PaginationProps>) => void
