import React, { useCallback, useMemo } from "react"
import { useIntl } from "react-intl"
import { messages } from "setup/messages/messages"
import {
  H6,
  Div,
  Flex,
  SectionBody,
  SectionHeader,
  Button,
  DropdownArrowIcon as DropdownArrow,
  RefreshWhiteIcon as RefreshIcon,
  getMinWidthMediaQuery,
  SmallText,
  Link,
  H1,
  LargeText
} from "@ikiru/talentis-fpc"
import { useSearch } from "views/search/SearchModule/context"
import { Pagination } from "components/functional/pagination/Pagination"
import {
  getFiltersFromSessionStorage,
  scrollToTop
} from "views/search/SearchModule/utils"
import { useMediaQuery } from "utils/hooks/use-media-query"
import { PersonProfileWrapper } from "views/persons/pages/manage-person/PersonProfileWrapper"
import SearchPersonOverlay from "views/persons/components/person-overlay/SearchPersonOverlay"
import PersonRecordCard from "./PersonRecordCard"
import useGetIgnorePersons from "./hook/useGetIgnorePersons"
import { SaveSearches } from "../SaveSeaches"
import { SearchUpHeader } from "../../SearchModule/style"
import { isEmpty } from "lodash"
// import Relaunch from "assets/relaunch-userguiding.svg"
import {
  UpArrow,
  ReturnToTop,
  StyledSectionHeader,
  StyledFlex
} from "./SearchResultsStyled"
import { NoResultsStyled, SearchContainer } from "./style"
// import { useAuth } from "../../../../setup/auth/module/auth.context"
import ChatGPTSaerch from "../ChatGPTSaerch"
import ProjectSelector from "components/ProjectSelector"

const SearchResults = () => {
  const { formatNumber } = useIntl()
  const {
    persons,
    isLoading,
    totalItems,
    paginationProps,
    isCriteriaNotDefined,
    onPageChange,
    setFiltersExpanded,
    loadPreviousSearch,
    selectedSearchDataPoolId,
    hasSearched,
    fetchAssignments,
    fetchCampaigns,
    setIsAssignmentSelect,
    isAssignmentSelect,
    activeAssignments,
    selectedAssignment,
    setSelectedAssignment,
    activeCampaigns,
    selectedCampaign,
    setSelectedCampaign
  } = useSearch()

  const {
    ignoredPersonArray,
    isLoading: isLoadingIgnoredPersons,
    setIgnoredPersonArray
  } = useGetIgnorePersons()

  const mediaQuery = getMinWidthMediaQuery("lg")
  const mobileMediaQuery = getMinWidthMediaQuery("sm")
  const isLarge = useMediaQuery(mediaQuery)
  const isMobile = !useMediaQuery(mobileMediaQuery)

  const foundResultsMessage = useMemo(
    () =>
      isLarge
        ? messages.talentGraphSearch.foundResultsFromTalentGraph
        : messages.talentGraphSearch.foundResults,
    [isLarge]
  )

  const backToSearchCriteria = useCallback(() => {
    setFiltersExpanded(true)
    scrollToTop()
  }, [setFiltersExpanded])

  const handlePageChange = useCallback(
    (page: any) => {
      onPageChange(page)
      scrollToTop()
    },
    [onPageChange]
  )

  const isPerson = persons?.length === 0

  return (
    <Flex flexDirection="column" width="100%">
      {!isMobile && (
        <>
          <SearchUpHeader py={"xs"} px={["xs", "", "", "m"]}>
            {!isEmpty(getFiltersFromSessionStorage()) ? (
              <Link onClick={loadPreviousSearch} my="none">
                <SmallText fontWeight="600" color="white.standard" my="none">
                  <RefreshIcon fontWeight={600} />
                  &nbsp;
                  {messages.talentGraphSearch.reloadSearch}
                </SmallText>
              </Link>
            ) : (
              <Div />
            )}
            <SaveSearches />
          </SearchUpHeader>
          <SectionHeader
            zIndex="3"
            position="sticky"
            height="45px"
            top="50px"
            title={
              isLoading
                ? messages.talentGraphSearch.loadingCandidates
                : totalItems === 0
                ? ""
                : foundResultsMessage.format(formatNumber(totalItems || 0))
            }
            titleProps={{ mt: "s" }}
            // @ts-ignore
            actionsProps={{ display: "inline-block" }}
            actions={
              <ProjectSelector
                fetchAssignments={fetchAssignments}
                fetchCampaigns={fetchCampaigns}
                setIsAssignmentSelect={setIsAssignmentSelect}
                isAssignmentSelect={isAssignmentSelect}
                isLoading={isLoading}
                activeAssignments={activeAssignments}
                selectedAssignment={selectedAssignment}
                setSelectedAssignment={setSelectedAssignment}
                activeCampaigns={activeCampaigns}
                selectedCampaign={selectedCampaign}
                setSelectedCampaign={setSelectedCampaign}
              />
            }
          />
        </>
      )}
      {!isCriteriaNotDefined && (
        <StyledFlex width="100%" flexDirection="column">
          {isMobile && (
            <Flex
              width="100%"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              py="xs"
              bg="white.standard"
            >
              <Button
                mode="standard-grey"
                size="action-medium"
                onClick={backToSearchCriteria}
              >
                <DropdownArrow
                  fill="currentColor"
                  width={12}
                  height={12}
                  style={{ transform: "rotate(180deg)" }}
                />
              </Button>
              <H6 mt="xxs" mb="none" color="grey.dark">
                {messages.talentGraphSearch.searchCriteria}
              </H6>
            </Flex>
          )}
          {isMobile && (
            <StyledSectionHeader
              title={
                isLoading
                  ? messages.talentGraphSearch.loadingCandidates
                  : totalItems === 0
                  ? ""
                  : foundResultsMessage.format(formatNumber(totalItems || 0))
              }
              // @ts-ignore
              actions={
                <ProjectSelector
                  fetchAssignments={fetchAssignments}
                  fetchCampaigns={fetchCampaigns}
                  setIsAssignmentSelect={setIsAssignmentSelect}
                  isAssignmentSelect={isAssignmentSelect}
                  isLoading={isLoading}
                  activeAssignments={activeAssignments}
                  selectedAssignment={selectedAssignment}
                  setSelectedAssignment={setSelectedAssignment}
                  activeCampaigns={activeCampaigns}
                  selectedCampaign={selectedCampaign}
                  setSelectedCampaign={setSelectedCampaign}
                />
              }
            />
          )}
        </StyledFlex>
      )}
      <Flex flexDirection="column" height="100%" width="100%">
        <SectionBody
          height={isCriteriaNotDefined ? "auto" : "100%"}
          width="100%"
          p="0"
          style={
            isPerson ? { position: "sticky", top: "100px", zIndex: "1" } : {}
          }
        >
          <Flex
            justifyContent="flex-start"
            alignItems="flex-start"
            flexDirection="column"
            width="100%"
          >
            <SearchContainer
              isPerson={isPerson}
              isFaded={!isCriteriaNotDefined && isLoading}
            >
              {isPerson ? (
                <>
                  <ChatGPTSaerch />
                  {hasSearched && (
                    <NoResultsStyled>
                      <H1 color="green.standard" textAlign="center" mb="none">
                        {messages.talentGraphSearch.noResults}
                      </H1>
                      <LargeText
                        color="grey.standard"
                        textAlign="center"
                        mt="xxs"
                      >
                        {messages.talentGraphSearch.pleaseEditCriteria}
                      </LargeText>
                    </NoResultsStyled>
                  )}
                </>
              ) : (
                persons?.map(
                  ({
                    person,
                    previousJobs,
                    assignmentIds,
                    campaignIds,
                    stages,
                    isGtPerson
                  }) => (
                    <Div my="xxs" key={person?.dataPoolId}>
                      <PersonRecordCard
                        {...{
                          person,
                          previousJobs,
                          assignmentIds,
                          stages,
                          campaignIds,
                          ignoredPersons: ignoredPersonArray,
                          isLoadingIgnoredPersons,
                          setIgnoredPersonArray,
                          isGtPerson
                        }}
                      />
                    </Div>
                  )
                )
              )}
            </SearchContainer>
          </Flex>
          {!isCriteriaNotDefined &&
            !isLoading &&
            paginationProps.pageCount > 0 && (
              <Flex width="100%" justifyContent="center" position="relative">
                <ReturnToTop onClick={scrollToTop}>
                  <UpArrow />
                  <Link>Return to top</Link>
                </ReturnToTop>
                <Pagination
                  {...paginationProps}
                  onPageChange={handlePageChange}
                />
              </Flex>
            )}
        </SectionBody>
      </Flex>
      {selectedSearchDataPoolId && (
        <PersonProfileWrapper>
          <SearchPersonOverlay />
        </PersonProfileWrapper>
      )}
    </Flex>
  )
}

export default React.memo(SearchResults)
