import React, { useMemo } from "react"
import { Flex, H6, Tag } from "@ikiru/talentis-fpc"
import Accordion from "../accordion"

type AssignAttributesAccordionProps = {
  attributes: any
  nodeTitle: string
  remove: (key: string) => void
  openNode?: (nodeKey?: string) => void
}

const AssignAttributesAccordion = ({
  attributes,
  nodeTitle,
  remove,
  openNode
}: AssignAttributesAccordionProps) => {
  const renderTree = (nodes, path = "") => {
    return nodes.map((node) => {
      if (!node.isChecked) {
        return null
      }

      const currentPath = path ? `${path} • ${node.title}` : node.title

      if (
        node.children &&
        Array.isArray(node.children) &&
        node.children.length > 0
      ) {
        if (node.children.every((child) => !child.children)) {
          const checkedChildren = node.children.filter(
            (child) => child.isChecked
          )

          if (checkedChildren.length > 0) {
            return (
              <H6 mt="0" mb="0" mr="0" fontSize="14px" key={node.key}>
                {currentPath} {"•"}
                {checkedChildren.map((leaf) => (
                  <Tag
                    m="0px"
                    mb="5px"
                    ml="5px"
                    variant="green"
                    key={leaf.key}
                    onRemoveClick={() => remove(leaf.key)}
                  >
                    {leaf.title}
                  </Tag>
                ))}
              </H6>
            )
          }

          return null
        }

        const children = renderTree(node.children, currentPath)
        if (children) {
          return <React.Fragment key={node.key}>{children}</React.Fragment>
        }

        return null
      }

      if (node.isChecked && !node.children.length) {
        return (
          <H6 mt="0" mb="0" mr="0" fontSize="14px" key={node.key}>
            {path && `${path} • `}
            <Tag
              key={node.key}
              m="0px"
              mb="5px"
              variant="green"
              onRemoveClick={() => remove(node.key)}
            >
              {node.title}
            </Tag>
          </H6>
        )
      }

      return null
    })
  }

  const accordionItems = useMemo(() => {
    return attributes?.map((attribute) => ({
      title: `${attribute.title} `,
      nodeKey: attribute.key,
      children: renderTree(attribute.children || [attribute]),
      isOpen: attribute.title === nodeTitle
    }))
  }, [attributes, nodeTitle])

  return (
    <Flex flexDirection="column" marginLeft="80px" width="100%">
      {accordionItems?.map((item, index) => (
        <Accordion
          key={index}
          title={item.title}
          nodeKey={item.nodeKey}
          children={item.children}
          isOpen={item.isOpen}
          onClick={openNode}
        />
      ))}
    </Flex>
  )
}

export default AssignAttributesAccordion
