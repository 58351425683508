import styled from "styled-components"
import { colors, Div, Flex, H4, spacing } from "@ikiru/talentis-fpc"
import { ReactComponent as PlusIcon } from "assets/plus-internal-search.svg"
import { createGlobalStyle } from "styled-components"
import { scrollBarCvStyle } from "components/FPCComponents/DragAndDrop/Columns/style"

export const StyledTreeWrapper = styled(Div)`
  .rc-tree-switcher {
    display: none;
  }
`

export const GlobalStyles = createGlobalStyle`

.rc-tree-title {
  margin-left: 5px;
  font-family: 'canada-type-gibson';
  font-size: 13px;
  color: ${colors.grey.darkest};
  font-weight: 500;

}

  .rc-tree .rc-tree-treenode span.rc-tree-switcher.rc-tree-switcher_open {
    background-position: -62px -56px;
}

.rc-tree .rc-tree-treenode span.rc-tree-switcher.rc-tree-switcher_close {
    background-position: -62px -56px;
    svg {
    transform: rotate(-90deg);
    }

}

.rc-tree-checkbox {
  background-color: #007bff; 
  
  width: 18px !; 
  height: 18px; 
}
.rc-tree-checkbox-checked {
  background-color: #28a745; 
}

.rc-tree-node-selected {
    background-color: ${colors.green.lightest};
    box-shadow: 0 0 0 1px ${colors.green.lightest};
    opacity: 0.8;
}
`

export const SwitcherIconStyled = styled(Div)`
  padding-left: 4px;
  position: relative;
  bottom: 4px;
`

export const AssignAttributesModalWrapper = styled(Flex)`
  flex-direction: column;
  background-color: ${colors.grey.lightest};
  padding: ${spacing.m}px;
  width: 95vw;
  height: 95vh;
`
export const ReassignAttributesModalWrapper = styled(Flex)`
  flex-direction: column;
  background-color: ${colors.grey.lightest};
  padding: ${spacing.m}px;
  width: 50vw;
  height: 95vh;
`

export const StyledBodyWrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  margin-top: 20px;
  margin-bottom: 40px;
  ${scrollBarCvStyle}
`

const iconStyles = `
  width: 20px !important;
  height: 20px !important;
  
  path {
    fill: ${colors.grey.dark};
  }
`

export const StyledPlusIcon = styled(PlusIcon)`
  ${iconStyles}
`

export const StyledAttributesTitle = styled(Div)`
  color: #6bc4c8;
  margin-left: 10px;
  margin-bottom: 10px;
  cursor: pointer;
`

export const ComingSoon = styled(H4)`
  color: #935a05;
  margin-bottom: 20px;
  margin-top: 5px;
  font-size: 14px;
  padding: 5px;
  background-color: #f9edd7;
  text-align: center;
  font-weight: 400;
`
