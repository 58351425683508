import { Attribute } from "views/attributes/attributes-module/attributes-module.types"

export const removeNodeByKey = (
  attributes: Attribute[],
  keyToRemove: string
) => {
  return attributes.filter((node: Attribute) => {
    if (node.key === keyToRemove) {
      return false
    }
    if (node.children) {
      node.children = removeNodeByKey(node.children, keyToRemove)
    }
    return true
  })
}

export const getCheckedLeafNodeIds = (nodes) => {
  const result: string[] = []

  const traverse = (node) => {
    if (
      node.isChecked &&
      node.isAttribute &&
      (!node.children || node.children.length === 0)
    ) {
      result.push(node.id)
    }

    if (node.children) {
      node.children.forEach(traverse)
    }
  }

  nodes.forEach(traverse)

  return result
}

export const getCheckedNodeIds = (nodes) => {
  const result: string[] = []

  const traverse = (node) => {
    if (node.isChecked) {
      result.push(node.id)
    }

    if (node.children) {
      node.children.forEach(traverse)
    }
  }

  nodes.forEach(traverse)

  return result
}

export const filterCheckedNodes = (nodes) => {
  return nodes
    .filter(
      (node) =>
        node.isChecked ||
        (node.children &&
          node.children.some((child) => filterCheckedNodes([child]).length > 0))
    )
    .map((node) => ({
      ...node,
      children: node.children ? filterCheckedNodes(node.children) : []
    }))
}

export const addIsCheckedFlag = (nodes, checkedNodes) => {
  const checkedMap = new Map()

  const createCheckedMap = (nodes) => {
    nodes?.forEach((node) => {
      checkedMap.set(node.key, true)
      if (node.children) createCheckedMap(node.children)
    })
  }

  createCheckedMap(checkedNodes)

  const addFlag = (nodes) => {
    return nodes?.map((node) => {
      const newNode = { ...node, isChecked: checkedMap.has(node.key) }

      if (newNode.children) {
        newNode.children = addFlag(newNode.children)
      }
      return newNode
    })
  }

  return addFlag(nodes.filter((node) => node.children?.length !== 0))
}

export const setCheckedFlag = (nodes, keys) => {
  return nodes.map((node) => {
    node.isChecked = keys.includes(node.key)

    if (node.children) {
      node.children = setCheckedFlag(node.children, keys)

      node.isChecked =
        node.isChecked || node.children.some((child) => child.isChecked)
    }

    return node
  })
}

export const isLeafNode = (nodeKey, treeData) => {
  const findNode = (key, nodes) => {
    for (const node of nodes) {
      if (node.key === key) {
        return node
      }
      if (node.children) {
        const found = findNode(key, node.children)
        if (found) return found
      }
    }
    return null
  }

  const node = findNode(nodeKey, treeData)
  return node && (!node.children || node.children.length === 0)
}

export const getCheckedKeys = (nodes) => {
  const checkedKeys = []

  const traverse = (nodes) => {
    nodes.forEach((node) => {
      if (node?.children) {
        traverse(node.children)
      }

      if (!node?.children.length && node.isChecked) {
        checkedKeys.push(node.key)
      }
    })
  }

  traverse(nodes)
  return checkedKeys
}

export const getAllKeys = (nodes) => {
  let keys = []

  nodes.forEach((node) => {
    keys.push(node.key)
    if (node.children && node.children.length > 0) {
      keys = keys.concat(getAllKeys(node.children))
    }
  })

  return keys
}

export const getChangedNodes = (
  userTree: Attribute[],
  fullTree: Attribute[]
): Attribute[] => {
  const findDifferences = (
    userNodes: Attribute[],
    fullNodes: Attribute[]
  ): Attribute[] => {
    const changedNodes: Attribute[] = []

    const mapById = (nodes: Attribute[]) =>
      nodes.reduce((acc, node) => {
        acc[node.id] = node
        return acc
      }, {} as Record<string, Attribute>)

    const userNodeMap = mapById(userNodes)

    for (const fullNode of fullNodes) {
      const userNode = userNodeMap[fullNode.id]
      if (!userNode) {
        continue
      }

      const isAttributeChanged = userNode.isAttribute !== fullNode.isAttribute

      if (isAttributeChanged) {
        changedNodes.push(fullNode)
      } else {
        const childDifferences = findDifferences(
          userNode.children || [],
          fullNode.children || []
        )
        if (childDifferences.length > 0) {
          changedNodes.push({ ...fullNode, children: childDifferences })
        }
      }
    }

    return changedNodes
  }

  return findDifferences(userTree, fullTree)
}

export const findIdsByKeys = (checkedKeysData, nodes) => {
  const keys = Object.values(checkedKeysData).flat()

  const ids = []

  function traverseTree(tree) {
    tree.forEach((node) => {
      if (keys.includes(node.key)) {
        ids.push(node.id)
      }
      if (node.children) {
        traverseTree(node.children)
      }
    })
  }

  traverseTree(nodes)
  return ids
}

export const getHiddenLeafIds = (nodes: Attribute[]) => {
  const hiddenLeafIds: string[] = []

  const traverse = (nodes) => {
    nodes.forEach((node) => {
      if (node.isHidden && node.isAttribute) {
        hiddenLeafIds.push(node.id)
      } else if (node.children && node.children.length > 0) {
        traverse(node.children)
      }
    })
  }

  traverse(nodes)

  return hiddenLeafIds
}

export const filterTree = (nodes: Attribute[]) => {
  const filterNodes = (nodeList) => {
    return nodeList
      .filter((node) => !node.isHidden)
      .map((node) => {
        const filteredChildren = node.children ? filterNodes(node.children) : []

        if (!node.isAttribute && filteredChildren.length === 0) {
          return null
        }

        return {
          ...node,
          children: filteredChildren
        }
      })
      .filter((node) => node !== null)
  }

  return filterNodes(nodes)
}
