import React from "react"
import get from "lodash/get"
import { Nullable } from "tsdef"
import { CandidateBox } from "@ikiru/talentis-fpc"

import { useCandidateRecord } from "./hooks"
import { RouterUrl } from "setup/router/routes"
import { messages } from "setup/messages/messages"
import { Candidate } from "views/candidates/candidates.types"

type CandidateProps = {
  candidate: Candidate
  photo?: Nullable<string>
  onToggle?: (checked: boolean) => void
  onToggleShowToClient?: (checked: boolean) => void
}

const CandidateRecord = ({
  candidate,
  photo,
  onToggle,
  onToggleShowToClient
}: CandidateProps) => {
  const { jobs, websites } = useCandidateRecord(candidate)

  const note = candidate?.commentary?.data
    ? {
        title: messages.clientPortal.commentary.title,
        description: candidate?.commentary?.data
      }
    : null

  return (
    <CandidateBox
      status={
        candidate.status?.name || messages.assignment.filters.noSelectedStatus
      }
      onShowInClientViewClick={onToggleShowToClient}
      showInClientView={candidate.commentary?.showToClient}
      selectable
      selected={candidate.showInClientView}
      onSelect={onToggle}
      selectText={messages.clientPortal.show}
      photoUrl={photo}
      name={candidate.normalizedPersonData?.name}
      location={candidate.normalizedPersonData?.location}
      key={candidate.id}
      candidateUrl={`${RouterUrl.ProfileDetails}/${candidate.personId}`}
      {...{ jobs, websites, note }}
      isOffLimits={candidate.normalizedPersonData?.isOfflimits}
      offLimitsMessage={messages.offLimits.offLimits}
    />
  )
}

export default React.memo(CandidateRecord)
