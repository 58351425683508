import React from "react"
import NoteAddForm from "components/Notes/components/forms/NoteAddForm"
import { messages } from "setup/messages/messages"
import { Button, Flex, ModalSectionHeader } from "@ikiru/talentis-fpc"
import { ModalNames } from "setup/modal/modal.definitions"

type NotesEditModeProps = {
  title?: string
  setViewMode?: () => void
  close?: (modalName: ModalNames) => void
  projectId: string
  projectType: string
  projectName: string
  onSubmit: (data: any) => void
  personLinkedNote?: {
    id: string
    name: string
  }
}

const NotesEditMode = ({
  title,
  setViewMode,
  close,
  projectId,
  projectType,
  projectName,
  onSubmit,
  personLinkedNote
}: NotesEditModeProps) => {
  return (
    <NoteAddForm
      projectId={projectId}
      projectType={projectType}
      projectName={projectName}
      personLinkedNote={personLinkedNote}
      onSubmit={onSubmit}
      formActionsComponent={(formik) => {
        const { isSubmitting, resetForm } = formik

        return (
          <Flex>
            <ModalSectionHeader
              title={title ? title : messages.campaign.campaignNotes}
              size="xSmall"
              actions={
                <>
                  <Button
                    type="submit"
                    size="small"
                    mr="xs"
                    disabled={isSubmitting}
                  >
                    {messages.generic.save}
                  </Button>
                  <Button
                    mode="standard-white"
                    size="small"
                    type="button"
                    onClick={() => {
                      resetForm()
                      setViewMode && setViewMode()
                      close && close(ModalNames.EditNoteData)
                    }}
                  >
                    {messages.generic.cancel}
                  </Button>
                </>
              }
            />
          </Flex>
        )
      }}
    />
  )
}

export default NotesEditMode
