import moment, { Moment } from "moment"
import { FilterResponseType, ProjectRecordFilter } from "./types"
import { sessionStorageKeys } from "setup/storage/storage.definitions"
import isEmpty from "lodash/isEmpty"
import { messages } from "setup/messages/messages"

export const getAppliedFiltersParams = (
  appliedFilter: ProjectRecordFilter[] = []
) => {
  let params = new URLSearchParams()
  appliedFilter?.forEach((filter) => {
    if (filter.recordProperty === "interviewProgressState.status") {
      const value = filter.value.eq as string
      params.append("CampaignContactStatuses", value)
    }
  })

  appliedFilter?.forEach((filter) => {
    if (filter.recordProperty === "assignTo") {
      const value = filter.value.eq as string
      filter.filterKey !== "assignToNull"
        ? params.append("assignsTo", value)
        : params.append("assignToExists", "false")
    }
  })

  const fullDateArray: Moment[] = []
  appliedFilter?.forEach((filter) => {
    if (filter.recordProperty === "dueDate") {
      const filterKey = filter.filterKey
      if (filterKey === "dueDateAnyDate" || filterKey === "dueDateNoDueDate") {
        const exists = filter.value.exists as Boolean
        params.append("dueDateExists", exists.toString())
      } else if (filterKey === "dueDateOverdue") {
        const lowestPossibleDate = moment("01/01/1900")
          .subtract(1, "d")
          .format("YYYY-MM-DD HH:mm:ss")
        params.append("DueDateFrom", lowestPossibleDate.toString())
      } else {
        const greastest = filter.value.gte as Date
        const lowest = filter.value.lte as Date
        fullDateArray.push(moment(greastest))
        fullDateArray.push(moment(lowest))
      }
    }
  })
  if (fullDateArray.length > 0) {
    const lowestDate = moment.min(fullDateArray).subtract(1, "d")
    const highestDate = moment.max(fullDateArray).subtract(1, "d")
    const lowestFormat = moment(lowestDate).format("YYYY-MM-DD HH:mm:ss")
    const highestFormat = moment(highestDate).format("YYYY-MM-DD HH:mm:ss")
    if (!params.has("DueDateFrom")) {
      params.append("DueDateFrom", lowestFormat.toString())
    }
    params.append("DueDateTo", highestFormat.toString())
  }

  appliedFilter?.forEach((filter) => {
    if (filter.recordProperty === "tags") {
      const value = filter.value.tags as string
      filter.filterKey !== "noTags"
        ? params.append("tagIds", value)
        : params.append("tagIdExists", "false")
    }
  })
  return params
}

export const spanTagColor = (color: string | undefined) => {
  switch (color) {
    case "yellow.lightest":
      return "yellow.light"
    case "orange.lightest":
      return "orange.light"
    case "red.lightest":
      return "red.light"
    case "green.lightest":
      return "green.standard"
    case "grey.light":
      return "grey.light"
    case "grey.standard":
      return "grey.standard"
    default:
      return "white"
  }
}

export const getFiltersFromSessionStorage = (
  sessionStorageKey: (typeof sessionStorageKeys)[keyof typeof sessionStorageKeys]
): Record<string, ProjectRecordFilter[]> => {
  const filtersJson = sessionStorage.getItem(sessionStorageKey)

  if (!filtersJson) {
    return {}
  }

  let filters = {}

  try {
    filters = JSON.parse(filtersJson)
  } catch (error) {
    console.error("Could not load project record filters")
  }

  return filters
}

export const saveFiltersToSessionStorage = (
  filters: ProjectRecordFilter[],
  projectId: string,
  sessionStorageKey: (typeof sessionStorageKeys)[keyof typeof sessionStorageKeys]
) => {
  if (!projectId) return

  if (isEmpty(filters)) {
    sessionStorage.removeItem(sessionStorageKey)
    return
  }

  try {
    const oldFilters = getFiltersFromSessionStorage(sessionStorageKey)

    const newFilters = {
      ...oldFilters,
      [projectId]: filters
    }

    const filtersJson = JSON.stringify(newFilters)
    sessionStorage.setItem(sessionStorageKey, filtersJson)
  } catch (eror) {
    console.error("Could not save filters")
  }
}

export const getDataFilter = (filters: FilterResponseType[]): any => {
  return (
    filters &&
    filters.map(
      (filter: FilterResponseType) =>
        filter.data || {
          id: "",
          name: messages.assignment.filters.noSelectedStatus
        }
    )
  )
}

export const hasFiltersInSessionStorage = (
  sessionStorageKey: (typeof sessionStorageKeys)[keyof typeof sessionStorageKeys]
) => {
  const filters = getFiltersFromSessionStorage(sessionStorageKey)
  return filters && !isEmpty(filters)
}

export const getItemCount = (
  totalFilteredCount: number,
  totalItemCount: number
): string | number => {
  return totalFilteredCount < totalItemCount
    ? messages.project.filteredCount.format(totalFilteredCount, totalItemCount)
    : totalItemCount
}

export const toBeRefreshed = (value: {}, filters: ProjectRecordFilter[]) => {
  if (!value) return true
  const keyValue = Object.keys(value)?.[0]
  const selectedFilter = filters.find((item) => {
    if (
      item.recordProperty === "interviewProgressState.status" &&
      keyValue === "status"
    ) {
      return true
    }
    return item.recordProperty === keyValue
  })
  return selectedFilter
}

export const checkFirstSelected = (
  recordProperty: string,
  appliedFilters: ProjectRecordFilter[]
) => {
  return appliedFilters[0]?.recordProperty !== recordProperty
}
