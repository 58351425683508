import React, { useCallback } from "react"
import { StyledNotesList } from "components/Notes/components/style"
import { Line, Div, Loader, Flex } from "@ikiru/talentis-fpc"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import { NoteEditForm } from "components/Notes/components/forms/NoteEditForm"
import NoteAssignmentItem, { NoteType } from "./NoteAssignmentItem"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { generateLinks } from "components/Notes/components/forms/utils"
import { useNavigate } from "react-router-dom"
import { Pagination } from "components/functional/pagination/Pagination"

export enum ProjectTypes {
  assignment = "assignment",
  campaign = "campaign",
  person = "person",
  company = "company"
}
export type LinkNotePreviewType = {
  id: string | number
  label: string
  Icon: React.ReactElement
  onClick: () => void
  type: ProjectTypes
}

const NotesList = () => {
  const { notes, setNotes, assignmentNotesInfo, notesPagination } =
    useAssignment()

  const { open, close } = useModal()
  const navigate = useNavigate()

  const onSubmit = useCallback(
    (newNoteItem: any) => {
      const updatedNote = {
        ...newNoteItem,
        linkCreatedByUser: newNoteItem.createdByUser,
        linkUpdatedByUser: newNoteItem.updatedByUser
      }

      const newNotes = notes.map((item) => {
        return item.id === updatedNote.id ? updatedNote : item
      })

      setNotes(newNotes)
      close(ModalNames.EditNoteData)
    },
    [close, notes, setNotes]
  )

  const noteItemOpenModal = useCallback(
    (note: NoteType) => {
      const {
        noteTitle,
        noteDescription,
        id,
        assignmentId,
        campaignId,
        personId,
        companyId
      } = note
      open(
        ModalNames.EditNoteData,
        <Div mb="xs">
          <NoteEditForm
            initialValues={{
              noteTitle,
              noteDescription
            }}
            notes={notes}
            projectId={assignmentId as string}
            setNotes={setNotes as any}
            noteId={id}
            links={generateLinks(note)}
            linkInitialValue={{
              assignment: assignmentId,
              campaign: campaignId,
              person: personId,
              company: companyId
            }}
            onSubmit={onSubmit}
          />
        </Div>
      )
    },
    [notes, onSubmit, open, setNotes]
  )

  return (
    <StyledNotesList>
      {assignmentNotesInfo.isLoading ? (
        <Loader />
      ) : (
        <Div>
          {notes.map((note, index) => (
            <Div key={`note_${note?.id}`}>
              {index !== 0 && <Line variant="thin" />}
              <NoteAssignmentItem
                {...{
                  note,
                  links: generateLinks(note, navigate),
                  openModal: noteItemOpenModal
                }}
              />
            </Div>
          ))}
          {notesPagination.totalItemCount > 25 && (
            <Flex width="100%" justifyContent="center" mb={20}>
              <Pagination
                {...notesPagination}
                onPageChange={notesPagination.onPageChange}
              />
            </Flex>
          )}
        </Div>
      )}
    </StyledNotesList>
  )
}

export default React.memo(NotesList)
