import { Endpoints } from "setup/api/api.types"
import { EmployeeType } from "../../../views/companies/hooks/useCompanyKnowEmployees"
import { SectionIds } from "views/internal-search/components/SearchField/type"

export enum Directories {
  Persons = "persons",
  Users = "users",
  SearchFirm = "searchfirms",
  Candidates = "candidates",
  Assignments = "assignments",
  Sectors = "sectors",
  Captcha = "Recaptcha",
  Subscription = "subscription",
  Search = "persons/search",
  Campaigns = "campaigns",
  Contacts = "campaignsContacts",
  Tasks = "Tasks",
  Notes = "notes",
  Company = "companies",
  GatedTalent = "GatedTalent",
  NewPersons = "newpersons",
  PersonV2 = "v2/persons",
  LocalSearch = "persons/localSearch",
  LocalSearchProperties = "localSearchProperties",
  Attributes = "attributes",
  CandidateStatuses = "candidateStatuses",
  AttributeLinks = "attributeLinks"
}

export enum PersonsEndpoints {
  Root = "personRoot",
  Import = "personImport",
  Search = "personSearch",
  ImportProfileUrl = "personImportProfileUrl",
  ProfileUrl = "personProfileUrl",
  Documents = "personDocuments",
  DownloadDocument = "personDownloadDocument",
  Photo = "personPhoto",
  GetPersonsPhotos = "GetPersonsPhotos",
  Keywords = "personKeywords",
  GDPRLawfulBasis = "personGDPRLawfulbasis",
  GDPRLawfulBasisV2 = "personGDPRLawfulbasisV2",
  Notes = "personNotes",
  Experience = "Experience",
  ContactLookup = "contactLookup",
  ContactLookupDetails = "contactLookupDetails",
  SimilarPeople = "getSimilar",
  DataPoolLinkage = "dataPoolLinkage",
  LinkPerson = "linkPerson",
  NotesDocuments = "notesDocuments",
  GetForCompany = "getForCompany",
  Employees = "Employees",
  CVParsing = "CVParser",
  CVParsingV2 = "CVParsingV2",
  GenerateAiBio = "generateAiBio",
  ContactLookUpV2 = "contactLookUpv2",
  ContactLookupV2Details = " contactLookupV2Details ",
  ParsePersonWithAI = "parsepersonwithai"
}

export enum CandidatesEndpoints {
  Root = "candidatesRoot",
  AvailableFilters = "availableFilters",
  CompaniesForCandidates = "CompaniesForCandidates",
  Filtered = "Filtered",
  TargetCompaniesStageCounters = "TargetCompaniesStageCounters"
}

export enum CandidateStatusesEndpoint {
  Root = "CandidateStatuses"
}

export enum UsersEndpoints {
  Root = "usersRoot",
  Invite = "invite",
  SendSupportEmail = "SendSupportEmail",
  SetFirstUsageFlag = "SetFirstUsageFlag",
  InviteMultiple = "inviteMultiple",
  Me = "me",
  Resend = "resend",
  MakeActive = "makeActive",
  MakeInActive = "makeInActive",
  Revoke = "revoke"
}

export enum AssignmentsEndpoints {
  Root = "assignmentsRoot",
  ExportCandidates = "exportCandidates",
  Shares = "Share",
  SimpleList = "getSimpleList",
  Notes = "assignmentNotes",
  IgnoredCandidates = "ignoredCandidates",
  Statuses = "statuses",
  AssignmentAiTemplate = "AssignmentAiTemplate",
  AssignmentDocument = "AssignmentDocument",
  AssignmentDocDownloads = "AssignmentDocDownloads"
}

export enum SearchFirmEndpoints {
  Root = "searchFirmRoot",
  ResendConfirmation = "resendConfirmation",
  FirmToken = "firmToken",
  PassInitialLogin = "PassInitialLogin",
  Searches = "searches",
  SetDefaultSearch = "SetDefaultSearch"
}

export enum SectorsEndpoints {
  Root = "sectorsRoot"
}

export enum CaptchaEndpoints {
  Root = "captchaRoot"
}

export enum SubscriptionEndpoints {
  Root = "subscriptionRoot",
  Plans = "plans",
  Estimate = "estimate",
  CreatePaymentIntent = "createPaymentIntent",
  CreateSubscription = "createSubscription",
  UpdateCustomer = "UpdateCustomer"
}

export enum SearchEndpoints {
  Root = "searchRoot",
  ByQuery = "searchPersonByQyery",
  GetLocations = "getLocations",
  GetCampaignIds = "campaignIdsList",
  GetPersonsAssignmentIds = "getPersonsAssignmentIds",
  AISearch = "createAiSearchCriteria",
  SimilarPeopleAi = "SimilarPeopleAiSearchCriteria"
}

export enum CampaignsEndpoints {
  Root = "campaignsRoot",
  SimpleList = "getCampaignSimpleList",
  IgnoredContacts = "ignoredContacts",
  Notes = "campaignNotes",
  CampaignAiTemplate = " CampaignAiTemplate",
  SimpleListCampaign = "getSimpleListCampaign",
  ExportCampaignContacts = "exportCampaignContacts",
  CampaignDocument = "CampaignDocument",
  CampaignDocDownloads = "CampaignDocDownloads"
}

export enum ContactsEndpoints {
  Root = "contactsRoot",
  SearchById = "searchById",
  GetList = "getCampaignsContactsList",
  FilterData = "filterdata"
}

export enum TasksEndpoints {
  Root = "Tasks",
  FilterData = "filterdataavailable"
}

export enum CompanyEndpoints {
  Root = "company",
  GetCompaniesName = "getCompaniesName",
  LinkToDataPool = "LinkToDataPool",
  Industries = "industries",
  CreateWithName = "createWithName",
  CompanyDocument = "CompanyDocument",
  CompanyDocDownloads = "CompanyDocDownloads"
}

export enum NoteEndpoint {
  Root = "note",
  updateNote = "updateNote",
  UpdateNoteLink = "updateNoteLink"
}

export enum GatedTalent {
  Root = "GatedTalent",
  GetCvUrl = "GetCvUrl"
}

export enum NewPersons {
  Root = "NewPersons",
  GetPersons = "GetPersons"
}

export enum LocalSearchEndpoints {
  Root = "localSearch",
  LocalSearchPropertiesSections = "localSearchPropertiesSections",
  LocalSearchPropertiesFields = "localSearchPropertiesFields",
  LocalSearchPropertiesReferenceValue = "localSearchPropertiesReferenceValue",
  LocalSearchPropertiesEnums = "localSearchPropertiesEnums",
  LocalSearchPropertiesAutocomplete = "localSearchPropertiesAutocomplete"
}

export enum AttributesEndpoints {
  Root = "attributes",
  WhereUsedList = "whereusedlist",
  AttributesPerson = "attributesPerson",
  AttributesLinksPerson = "attributesLinksPerson"
}

type keywordsParams = { id: string; keywordId?: string }
type documentParams = { id: string; documentId: string }
type noteParams = { id: string; noteId: string }
type shareParams = { assignmentId: string }
type exportProjectRecordsParams = { id: string; exportId?: string }
type noteDocParams = { noteId: string; documentId: string }
type industriesParams = { companyId: string; id?: string }
type cvParseParams = { id: string; docId?: string }
type localSearchAutoComplete = { sectionId: SectionIds; fieldId: string }

export const endpoints: Endpoints = {
  [PersonsEndpoints.Root]: (id?: string) =>
    `/${Directories.Persons}${id ? `/${id}` : ""}`,
  [PersonsEndpoints.Keywords]: ({ id, keywordId }: keywordsParams) =>
    `/${Directories.Persons}/${id}/keywords${keywordId ? `/${keywordId}` : ""}`,
  [PersonsEndpoints.Import]: () => `/${Directories.Persons}/import`,
  [PersonsEndpoints.Search]: () => `/${Directories.Persons}/search`,
  [PersonsEndpoints.ImportProfileUrl]: (url) =>
    `/${Directories.Persons}/import/?linkedInProfileUrl=${url}`,
  [PersonsEndpoints.GetPersonsPhotos]: () =>
    `/${Directories.Persons}/GetPersonsPhotos`,
  [PersonsEndpoints.ProfileUrl]: (url) =>
    `/${Directories.Persons}/?linkedInProfileUrl=${url}`,
  [PersonsEndpoints.Documents]: (id: string) =>
    `/${Directories.Persons}/${id}/documents`,
  [PersonsEndpoints.DownloadDocument]: ({ id, documentId }: documentParams) =>
    `/${Directories.Persons}/${id}/documents/${documentId}/download`,
  [PersonsEndpoints.GDPRLawfulBasis]: (id: string) =>
    `/${Directories.Persons}/${id}/gdprlawfulbasis`,
  [PersonsEndpoints.GDPRLawfulBasisV2]: (id: string) =>
    `/${Directories.Persons}/${id}/GdprLawfulBasisV2`,
  [PersonsEndpoints.Photo]: (id: string) =>
    `/${Directories.Persons}/${id}/photo`,
  [PersonsEndpoints.GetForCompany]: (id: string) =>
    `/${Directories.Persons}/getForCompany/${id}`,
  [PersonsEndpoints.Employees]: ({
    id,
    mode
  }: {
    id: string
    mode: EmployeeType
  }) => `/${Directories.Persons}/companies/${id}/employees/${mode}`,
  [PersonsEndpoints.CVParsing]: ({ id, docId }: cvParseParams) =>
    `/${Directories.Persons}/${id}/ParseCv/${docId ? `${docId}` : ""}`,
  [PersonsEndpoints.CVParsingV2]: ({ id, docId }: cvParseParams) =>
    `/${Directories.Persons}/${id}/ParseCvV2/${docId ? `${docId}` : ""}`,
  [PersonsEndpoints.GenerateAiBio]: ({
    personId,
    isShort,
    Language
  }: {
    personId: string
    isShort: string
    Language: string
  }) =>
    `/${Directories.Persons}/${personId}/generateAiBio?isShort=${isShort}&Language=${Language}`,
  [PersonsEndpoints.ParsePersonWithAI]: () =>
    `/${Directories.Persons}/parsepersonwithai`,
  [SearchFirmEndpoints.Root]: () => `/${Directories.SearchFirm}`,
  [SearchFirmEndpoints.FirmToken]: () => `/${Directories.SearchFirm}/tokens`,
  [UsersEndpoints.Root]: (id?: string) =>
    `/${Directories.Users}${id ? `/${id}` : ""}`,
  [UsersEndpoints.InviteMultiple]: (id: string) =>
    `/${Directories.Users}/Invite/InviteMultiple`,
  [UsersEndpoints.Invite]: (id: string) =>
    `/${Directories.Users}/invite${id ? `/${id}` : ""}`,
  [UsersEndpoints.Resend]: (id: string) =>
    `/${Directories.Users}/${id}/invite/resend`,
  [UsersEndpoints.Revoke]: (id: string) =>
    `/${Directories.Users}/${id}/invite/revoke`,
  [UsersEndpoints.Me]: () => `/${Directories.Users}/me`,
  [UsersEndpoints.SetFirstUsageFlag]: () =>
    `/${Directories.Users}/SetFirstUsageFlag`,
  [UsersEndpoints.SendSupportEmail]: () =>
    `/${Directories.Users}/SendSupportEmail`,
  [UsersEndpoints.MakeActive]: () => `/${Directories.Users}/makeActive`,
  [UsersEndpoints.MakeInActive]: () => `/${Directories.Users}/makeInActive`,
  [PersonsEndpoints.Notes]: ({ id, noteId }: noteParams) =>
    `/${Directories.Persons}/${id}/notes${noteId ? `/${noteId}` : ""}`,
  [PersonsEndpoints.NotesDocuments]: ({ noteId, documentId }: noteDocParams) =>
    `/${Directories.Notes}/${noteId}/Documents/${documentId}/Download`,
  [PersonsEndpoints.Experience]: ({ experienceId, personId }) =>
    `/${Directories.Persons}/${personId}/Experience/${experienceId}`,
  [PersonsEndpoints.ContactLookup]: (id: string) =>
    `/${Directories.Persons}/${id}/contactLookup`,
  [PersonsEndpoints.ContactLookupDetails]: (id: string) =>
    `/${Directories.Persons}/${id}/contactLookup/contactLookupDetails`,
  [PersonsEndpoints.ContactLookUpV2]: (id: string) =>
    `/${Directories.PersonV2}/${id}/contactLookup`,
  [PersonsEndpoints.ContactLookupV2Details]: (id: string) =>
    `/${Directories.PersonV2}/${id}/contactLookup/details`,
  [PersonsEndpoints.SimilarPeople]: (id: string) =>
    `/${Directories.Persons}/${id}/getSimilar`,
  [PersonsEndpoints.DataPoolLinkage]: () =>
    `/${Directories.Persons}/dataPoolLinkage`,
  [PersonsEndpoints.LinkPerson]: (id: string) =>
    `/${Directories.Persons}/linkPerson/${id}`,
  [CandidatesEndpoints.Root]: (id?: string) =>
    `/${Directories.Candidates}${id ? `/${id}` : ""}`,
  [CandidatesEndpoints.AvailableFilters]: () =>
    `/${Directories.Candidates}/filterdata`,
  [CandidatesEndpoints.CompaniesForCandidates]: (id?: string) =>
    `/${Directories.Candidates}/CompaniesForCandidates/${id ? `/${id}` : ""}`,
  [CandidatesEndpoints.Filtered]: () => `/${Directories.Candidates}/Filtered`,
  [CandidatesEndpoints.TargetCompaniesStageCounters]: () =>
    `/${Directories.Candidates}/TargetCompaniesStageCounters`,
  [AssignmentsEndpoints.IgnoredCandidates]: ({ assignmentId, personId }: any) =>
    `/${Directories.Assignments}/${assignmentId}/IgnoredPersons${
      personId ? `/${personId}` : ""
    }`,
  [AssignmentsEndpoints.Root]: (id?: string) =>
    `/${Directories.Assignments}${id ? `/${id}` : ""}`,
  [AssignmentsEndpoints.ExportCandidates]: ({
    id,
    exportId
  }: exportProjectRecordsParams) =>
    `/${Directories.Assignments}/${id}/exportCandidates${
      exportId ? `/${exportId}` : ""
    }`,
  [AssignmentsEndpoints.Shares]: ({ assignmentId }: shareParams) =>
    `/${Directories.Assignments}/${assignmentId}/Shares`,
  [AssignmentsEndpoints.SimpleList]: () =>
    `/${Directories.Assignments}/getSimpleList`,
  [AssignmentsEndpoints.Notes]: ({ id, noteId }: noteParams) =>
    `/${Directories.Assignments}/${id}/notes${noteId ? `/${noteId}` : ""}`,
  [AssignmentsEndpoints.Statuses]: () => `/${Directories.Assignments}/statuses`,
  [AssignmentsEndpoints.AssignmentAiTemplate]: (id: string) =>
    `/${Directories.Assignments}/${id}/templates`,
  [AssignmentsEndpoints.AssignmentDocument]: (assignmentId) =>
    `/${Directories.Assignments}/${assignmentId}/Documents`,
  [AssignmentsEndpoints.AssignmentDocDownloads]: ({
    id,
    documentId
  }: documentParams) =>
    `/${Directories.Assignments}/${id}/documents/${documentId}`,
  [SectorsEndpoints.Root]: () => `/${Directories.Sectors}`,
  [SearchFirmEndpoints.ResendConfirmation]: () =>
    `/${Directories.SearchFirm}/resendConfirmation`,
  [CaptchaEndpoints.Root]: () => `/${Directories.Captcha}`,
  [SubscriptionEndpoints.Root]: () => `/${Directories.Subscription}`,
  [SubscriptionEndpoints.Plans]: () => `/${Directories.Subscription}/plans`,
  [SubscriptionEndpoints.Estimate]: () =>
    `/${Directories.Subscription}/GetEstimate`,
  [SubscriptionEndpoints.UpdateCustomer]: () =>
    `/${Directories.Subscription}/UpdateCustomer`,
  [SubscriptionEndpoints.CreatePaymentIntent]: () =>
    `/${Directories.Subscription}/CreatePaymentIntent`,
  [SubscriptionEndpoints.CreateSubscription]: () =>
    `/${Directories.Subscription}/CreateSubscription`,
  [SearchEndpoints.Root]: () => `/${Directories.Search}`,
  [SearchEndpoints.ByQuery]: () =>
    `/${Directories.Search}/SearchForPersonByQuery`,
  [SearchEndpoints.GetCampaignIds]: () =>
    `/${Directories.Search}/GetPersonsCampaignIds`,
  [SearchEndpoints.GetPersonsAssignmentIds]: () =>
    `/${Directories.Search}/GetPersonsAssignmentIds`,
  [SearchEndpoints.GetLocations]: () => `/${Directories.Search}/GetLocations`,
  [SearchEndpoints.AISearch]: () =>
    `/${Directories.Search}/CreateAiSearchCriteria`,
  [SearchEndpoints.SimilarPeopleAi]: (id: string) =>
    `/${Directories.Search}/SimilarPeopleAiSearchCriteria/${id}`,
  [SearchFirmEndpoints.PassInitialLogin]: ({ searchFirmId }) =>
    `/${Directories.SearchFirm}/PassInitialLogin/${searchFirmId}`,
  [SearchFirmEndpoints.Searches]: (id?: string) =>
    `/${Directories.SearchFirm}/searches${id ? `/${id}` : ""}`,
  [SearchFirmEndpoints.SetDefaultSearch]: (id?: string) =>
    `/${Directories.SearchFirm}/searches${id ? `/${id}` : ""}/SetDefault`,
  [CampaignsEndpoints.Root]: (id?: string) =>
    `/${Directories.Campaigns}${id ? `/${id}` : ""}`,
  [CampaignsEndpoints.SimpleList]: () =>
    `/${Directories.Campaigns}/getSimpleList`,
  [ContactsEndpoints.Root]: (id?: string) =>
    `/${Directories.Contacts}${id ? `/${id}` : ""}`,
  [CampaignsEndpoints.IgnoredContacts]: ({ campaignId, personId }: any) =>
    `/${Directories.Campaigns}/${campaignId}/IgnoredPersons${
      personId ? `/${personId}` : ""
    }`,
  [ContactsEndpoints.GetList]: (id: string) =>
    `/${Directories.Contacts}/searchbycampaignid/${id}`,
  [CampaignsEndpoints.Notes]: ({ id, noteId }: noteParams) =>
    `/${Directories.Campaigns}/${id}/notes${noteId ? `/${noteId}` : ""}`,
  [CampaignsEndpoints.SimpleListCampaign]: () =>
    `/${Directories.Campaigns}/getSimpleList`,
  [ContactsEndpoints.SearchById]: ({ campaignId }) =>
    `/${Directories.Contacts}/searchbycampaignid/${campaignId}`,
  [ContactsEndpoints.FilterData]: ({ campaignId }) =>
    `/${Directories.Contacts}/filterdata/${campaignId}`,
  [CampaignsEndpoints.ExportCampaignContacts]: ({
    id,
    exportId
  }: exportProjectRecordsParams) =>
    `/${Directories.Campaigns}/${id}/exportCampaignContacts${
      exportId ? `/${exportId}` : ""
    }`,
  [CampaignsEndpoints.CampaignAiTemplate]: (id: string) =>
    `/${Directories.Campaigns}/${id}/templates`,
  [CampaignsEndpoints.CampaignDocument]: (campaignId) =>
    `/${Directories.Campaigns}/${campaignId}/Documents`,
  [CampaignsEndpoints.CampaignDocDownloads]: ({
    id,
    documentId
  }: documentParams) =>
    `/${Directories.Campaigns}/${id}/documents/${documentId}`,
  [TasksEndpoints.Root]: () => `/${Directories.Tasks}`,
  [TasksEndpoints.FilterData]: () => `/${Directories.Tasks}/filterdata`,
  [CompanyEndpoints.Root]: (id?: string) =>
    `/${Directories.Company}${id ? `/${id}` : ""}`,
  [CompanyEndpoints.GetCompaniesName]: () => `/${Directories.Company}/ByName`,
  [CompanyEndpoints.CreateWithName]: () =>
    `/${Directories.Company}/CreateWithName`,
  [CompanyEndpoints.LinkToDataPool]: () =>
    `/${Directories.Company}/LinkToDataPool`,
  [CompanyEndpoints.Industries]: ({ companyId, id }: industriesParams) =>
    `/${Directories.Company}/${companyId}/Industries${id ? `/${id}` : ""}`,
  [CompanyEndpoints.CompanyDocument]: (companyId) =>
    `/${Directories.Company}/${companyId}/Documents`,
  [CompanyEndpoints.CompanyDocDownloads]: ({
    id,
    documentId
  }: documentParams) => `/${Directories.Company}/${id}/documents/${documentId}`,
  [GatedTalent.Root]: (dataPoolPersonId: string) =>
    `/${Directories.GatedTalent}/${dataPoolPersonId}`,
  [GatedTalent.GetCvUrl]: (dataPoolPersonId: string) =>
    `/${Directories.GatedTalent}/${dataPoolPersonId}/GetCvUrl`,

  [NewPersons.Root]: () => `/${Directories.NewPersons}`,
  [NewPersons.GetPersons]: (idsList: string) =>
    `/${Directories.NewPersons}?${idsList}`,
  [LocalSearchEndpoints.Root]: () => `/${Directories.LocalSearch}`,
  [LocalSearchEndpoints.LocalSearchPropertiesSections]: () =>
    `/${Directories.LocalSearchProperties}/Sections`,
  [LocalSearchEndpoints.LocalSearchPropertiesFields]: (sectionId: string) =>
    `/${Directories.LocalSearchProperties}/Fields/${sectionId}`,
  [LocalSearchEndpoints.LocalSearchPropertiesReferenceValue]: (
    columnId: string
  ) => `/${Directories.LocalSearchProperties}/ReferenceValues/${columnId}`,
  [LocalSearchEndpoints.LocalSearchPropertiesEnums]: (columnId: string) =>
    `/${Directories.LocalSearchProperties}/Enums/${columnId}`,
  [LocalSearchEndpoints.LocalSearchPropertiesAutocomplete]: ({
    sectionId,
    fieldId
  }: localSearchAutoComplete) =>
    `/${Directories.LocalSearchProperties}/autocomplete/${sectionId}/${fieldId}`,
  [AttributesEndpoints.Root]: () => `/${Directories.Attributes}`,
  [CandidateStatusesEndpoint.Root]: () => `${Directories.CandidateStatuses}`,
  [AttributesEndpoints.WhereUsedList]: () =>
    `/${Directories.Attributes}/whereusedlist`,
  [AttributesEndpoints.AttributesPerson]: () =>
    `/${Directories.Attributes}/person`,
  [AttributesEndpoints.AttributesLinksPerson]: (personId: string) =>
    `/${Directories.AttributeLinks}/person/${personId}`,
  [NoteEndpoint.Root]: () => `/${Directories.Notes}`,
  [NoteEndpoint.UpdateNoteLink]: (noteId: string) =>
    `/${Directories.Notes}/${noteId}/Bind`,
  [NoteEndpoint.updateNote]: (noteId: string) =>
    `/${Directories.Notes}/${noteId}`
}
