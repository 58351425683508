export const RouterSubUrl = {
  AssignmentCreate: "/create",
  AssignmentDetails: "/details",
  AssignmentList: "/list",
  AssignmentPortalConfig: "/details/:assignmentId/portal-configuration",
  CampaignDetails: "/details",
  CampaignList: "/list",
  CampaignCreate: "/create",
  TeamInviteUser: "/invite-user",
  ProfileCreate: "/create",
  ProfileSearch: "/search",
  ProfileAddNew: "/search/add-new",
  ProfileNewContact: "/search/new-contact",
  ProfileDetails: "/details",
  CompanyList: "/list",
  CompanyDetails: "/details",
  CompaniesSearch: "/search",
  InternalSearch: "/search",
  InternalSearchNewContact: "/new-contact"
}

export const RouterUrl = {
  Homepage: "/",
  Index: "/index.html",
  Profile: "/profile",
  ProfileCreate: "/profile" + RouterSubUrl.ProfileCreate,
  ProfileSearch: "/profile" + RouterSubUrl.ProfileSearch,
  ProfileAddNew: "/profile" + RouterSubUrl.ProfileAddNew,
  ProfileNewContact: "/profile" + RouterSubUrl.ProfileNewContact,
  ProfileDetails: "/profile" + RouterSubUrl.ProfileDetails,
  CompaniesSearch: "/companies" + RouterSubUrl.CompaniesSearch,
  Signup: "/signup",
  Team: "/team",
  TeamInviteUser: "/team" + RouterSubUrl.TeamInviteUser,
  RegisterUser: "/register-user",
  Assignment: "/assignment",
  AssignmentCreate: "/assignment" + RouterSubUrl.AssignmentCreate,
  AssignmentDetails: "/assignment" + RouterSubUrl.AssignmentDetails,
  AssignmentList: "/assignment" + RouterSubUrl.AssignmentList,
  AssignmentPortalConfig: "/assignment" + RouterSubUrl.AssignmentPortalConfig,
  VerifySearchFirm: "/confirm",
  OIDCRedirectRouteUrl: "/oidc-redirect/:action",
  Subscription: "/subscription",
  Search: "/search",
  InternalSearchProfile: "/internal-search",
  InternalSearchNewContact:
    "/internal-search" + RouterSubUrl.InternalSearchNewContact,
  InternalSearch: "/internal-search" + RouterSubUrl.InternalSearch,
  Logout: "/logout",
  NotPermission: "/not-permission",
  Billing: "/billing",
  Campaign: "/campaign",
  CampaignDetails: "/campaign" + RouterSubUrl.CampaignDetails,
  CampaignList: "/campaign" + RouterSubUrl.CampaignList,
  CampaignCreate: "/campaign" + RouterSubUrl.CampaignCreate,
  Reports: "/reports",
  Outlook: "/outlook",
  Support: "/support",
  Company: "/company",
  CompanyDetails: "/company" + RouterSubUrl.CompanyDetails,
  CompanyList: "/campaign" + RouterSubUrl.CampaignList,
  Settings: "/settings",
  Attributes: "/attributes"
}
