import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Formik, Form, FormikHelpers } from "formik"
import { Flex } from "@ikiru/talentis-fpc"
import { Candidate } from "views/candidates/candidates.types"
import FormikSelect from "components/functional/formik/formik-select/FormikSelect"
import { AutoSave } from "components/functional/formik/formik-autosave/AutoSave"
import { useCandidate } from "views/assignments/components/candidates-list/components/candidate-record/CandidateContext"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import {
  e2eTargets,
  InterviewProgressField,
  interviewProgressInitialTouched,
  interviewProgressInitialValues,
  candidateStages,
  NO_SELECTED_STATUS,
  DefaultStatusValue
} from "./definitions"
import { useTeam } from "views/team/team/team-module.context"
import { FormikCheckedDatapicker } from "components/functional/formik/formik-checked-datapicker/FormikCheckedDatepicker"
import { messages } from "setup/messages/messages"
import { useTelemetry } from "utils/hooks/use-telemetry"
import { getChangedValues } from "views/assignments/utils"
import { apiRequest } from "setup/api/api"
import { CandidateStatusesEndpoint } from "setup/api/endpoints/endpoints"

export const InterviewProgress = () => {
  const [statusList, setStatusList] = useState<
    { value: string; label: string }[]
  >([DefaultStatusValue])
  const { candidate, onChangeInterviewProgress } = useCandidate()

  const { teamMembers } = useTeam()
  const {
    updateCandidateStage,
    assignmentId,
    selectedTab,
    updateCompanyCandidate,
    updateCompaniesStage
  } = useAssignment()
  const {
    trackCandidateAssignToSet,
    trackCandidateDueDateSet,
    trackCandidateCompletedOrRemoveSet
  } = useTelemetry()

  const membersOptions = useMemo(
    () =>
      teamMembers.map((member) => ({
        label: `${member.firstName} ${member.lastName}`,
        value: member.id
      })),
    [teamMembers]
  )

  const initialValues = useMemo(
    () => ({
      ...interviewProgressInitialValues,
      ...{
        stage: candidate.stage,
        status: candidate.status?.id || NO_SELECTED_STATUS
      },
      assignTo: candidate.assignTo,
      dueDate: candidate.dueDate
    }),
    [
      candidate.stage,
      candidate.status?.id,
      candidate.assignTo,
      candidate.dueDate
    ]
  )

  const onSubmit = onChangeInterviewProgress(
    candidate.id,
    (candidateNew: Candidate) => {
      if (selectedTab === "companyCandidate") {
        updateCompanyCandidate(candidateNew)
        updateCompaniesStage(candidate.stage || "", candidateNew)
      }

      updateCandidateStage(candidate.id, candidateNew)
    }
  )

  const submitForm = (values: any, actions: FormikHelpers<any>) => {
    const formattedInitialValues = {
      assignTo: initialValues.assignTo,
      dueDate: initialValues.dueDate,
      stage: initialValues.stage,
      statusId: initialValues.status
    }

    const formattedValues = {
      assignTo: values.assignTo,
      dueDate: values.dueDate,
      stage: values.stage,
      statusId: values.status
    }

    const changeValues = getChangedValues(
      formattedValues,
      formattedInitialValues
    )

    onSubmit(changeValues, actions)
  }

  const getStatusList = useCallback(async () => {
    const [, response] = await apiRequest.get({
      endpoint: CandidateStatusesEndpoint.Root,
      config: {
        params: {
          parentStage: candidate.stage
        }
      }
    })

    const statusSelectList: any[] = response?.data.map(
      (statusInfo: { id: string; name: string }) => {
        return { value: statusInfo.id, label: statusInfo.name }
      }
    )
    statusSelectList.unshift(DefaultStatusValue)
    setStatusList(statusSelectList)
  }, [candidate.stage])

  useEffect(() => {
    getStatusList()
  }, [getStatusList])

  return (
    <Formik
      initialValues={initialValues}
      initialTouched={interviewProgressInitialTouched}
      onSubmit={submitForm}
      enableReinitialize={true}
    >
      {({ handleChange, setFieldValue }) => {
        return (
          <AutoSave debounceMs={0}>
            <Form>
              <Flex
                mb={["xs"]}
                alignItems="start"
                justifyContent="flex-start"
                flexDirection="row"
              >
                <Flex flexDirection="column" mr="s">
                  <FormikSelect
                    layout={{
                      mb: ["xs"],
                      mr: "xs",
                      width: "100%",
                      maxWidth: "200px",
                      position: "relative",
                      zIndex: 2
                    }}
                    variant="small"
                    options={candidateStages}
                    id={InterviewProgressField.Stage}
                    name={InterviewProgressField.Stage}
                    onChange={(e) => {
                      setFieldValue("status", NO_SELECTED_STATUS)
                      handleChange(e)
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                    }}
                    data-e2e-target-name={`candidate-${
                      candidate.normalizedPersonData?.name
                    }-${e2eTargets[InterviewProgressField.Stage]}`}
                  />
                  <FormikSelect
                    layout={{
                      mt: ["none", "xxs"],
                      width: "100%",
                      maxWidth: "200px",
                      position: "relative",
                      zIndex: 2
                    }}
                    variant="small"
                    options={statusList}
                    id={InterviewProgressField.Status}
                    name={InterviewProgressField.Status}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                    }}
                    width="80%"
                    data-e2e-target-name={`candidate-${
                      candidate.normalizedPersonData?.name
                    }-${e2eTargets[InterviewProgressField.Status]}`}
                  />
                </Flex>
                <Flex flexDirection="column">
                  <FormikCheckedDatapicker
                    variant="small"
                    layout={{
                      mt: ["none"],
                      mb: ["xs"],
                      width: "100%",
                      maxWidth: "200px",
                      position: "relative",
                      zIndex: 3
                    }}
                    onChange={() => {
                      trackCandidateDueDateSet({ assignmentId })
                    }}
                    onComplete={() => {
                      trackCandidateCompletedOrRemoveSet({ assignmentId })
                    }}
                    onRemove={() => {
                      trackCandidateCompletedOrRemoveSet({ assignmentId })
                    }}
                    id={InterviewProgressField.DueDate}
                    name={InterviewProgressField.DueDate}
                    label={messages.person.assignments.dueDate}
                  />
                  <FormikSelect
                    layout={{
                      mt: ["none", "xxs"],
                      width: "100%",
                      maxWidth: "200px",
                      position: "relative",
                      zIndex: 2
                    }}
                    variant="small"
                    firstOption={{
                      label: messages.form.generic.assignTo,
                      value: ""
                    }}
                    options={membersOptions}
                    onChange={() => {
                      trackCandidateAssignToSet({ assignmentId })
                    }}
                    id={InterviewProgressField.AssignTo}
                    name={InterviewProgressField.AssignTo}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                    }}
                    width="80%"
                    data-e2e-target-name={`candidate-${
                      candidate.normalizedPersonData?.name
                    }-${e2eTargets[InterviewProgressField.AssignTo]}`}
                  />
                </Flex>
              </Flex>
            </Form>
          </AutoSave>
        )
      }}
    </Formik>
  )
}
