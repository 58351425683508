import React from "react"
import { Div, Flex, BodyText } from "@ikiru/talentis-fpc"
import { RouterLink } from "components/RouterLink/RouterLink"

type SettingsSectionProps = {
  title: string
  link: string
  description: string[]
}

export const SettingsSection = ({
  title,
  link,
  description
}: SettingsSectionProps) => (
  <Flex alignItems="baseline" mb="20px">
    <Div width="250px">
      <RouterLink mb="0" to={link} href={link}>
        {title}
      </RouterLink>
    </Div>
    <Flex flexDirection="column" ml="30px">
      {description.map((text, index) => (
        <BodyText key={index} m="0">
          {text}
        </BodyText>
      ))}
    </Flex>
  </Flex>
)
