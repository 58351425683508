import noop from "lodash/noop"
import {
  AttributesAction,
  AttributesActionTypes,
  AttributesContextValues,
  AttributesState
} from "./attributes-module.types"

export const initialAttributesState: AttributesState = {
  isLoading: false,
  attributes: [],
  whereUsedList: []
}

export const initialAttributesContextValues: AttributesContextValues = {
  ...initialAttributesState,
  setAttributes: noop,
  setIsLoading: noop,
  setWhereUsedList: noop
}

export const attributesReducer = (
  state: AttributesState,
  action: AttributesAction
) => {
  const { type, payload } = action

  switch (type) {
    case AttributesActionTypes.SetIsLoading:
      return {
        ...state,
        isLoading: payload
      }
    case AttributesActionTypes.SetAttributes:
      return {
        ...state,
        attributes: payload
      }
    case AttributesActionTypes.SetWhereUsedList:
      return {
        ...state,
        whereUsedList: payload
      }
    default:
      return state
  }
}
