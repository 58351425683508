import { Section, Div, Flex, Loader } from "@ikiru/talentis-fpc"
import React, { useCallback } from "react"
import { messages } from "setup/messages/messages"
// import PersonSectionButton from "views/persons/components/person-components/PersonSectionButton"

import PersonSectionButton from "views/persons/components/person-components/PersonSectionButton"
import NotesEditMode from "components/Notes/components/EditMode"
import { ModalNames } from "setup/modal/modal.definitions"
import { useModal } from "utils/hooks/use-modal"
import { useCompany } from "views/companies/company-module/company-module.context"
import CompanyNotesList from "./components/CompanyNotesList"

type CompanyNotesProps = {
  isLoading: boolean
}
const CompanyNotes = ({ isLoading }: CompanyNotesProps) => {
  const { notes, setNotes, companyDetails, companyId } = useCompany()
  const { open, close } = useModal()

  const onSubmit = useCallback(
    (newNoteItem: any) => {
      const newNote = {
        ...newNoteItem,
        linkCreatedByUser: newNoteItem.createdByUser,
        linkUpdatedByUser: newNoteItem.updatedByUser
      }
      const newNotes = [newNote, ...notes]

      setNotes(newNotes)
      close(ModalNames.EditNoteData)
    },
    [close, notes, setNotes]
  )

  const noteOpenModal = useCallback(() => {
    open(
      ModalNames.EditNoteData,
      <NotesEditMode
        projectId={companyId}
        projectType="company"
        projectName={companyDetails.name}
        onSubmit={onSubmit}
        close={() => close(ModalNames.EditNoteData)}
      />
    )
  }, [open, companyId, companyDetails.name, onSubmit, close])

  return (
    <Section
      size="xxSmall"
      header={{
        title: messages.person.note.title,
        actions: (
          <PersonSectionButton
            disabled={false}
            label={messages.person.note.newNote}
            onClick={noteOpenModal}
          />
        )
      }}
    >
      {isLoading ? (
        <Flex>
          <Loader />
        </Flex>
      ) : (
        <Div py="s" width="100%">
          <CompanyNotesList />
        </Div>
      )}
    </Section>
  )
}

export default React.memo(CompanyNotes)
