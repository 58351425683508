import React, { useMemo } from "react"
import {
  Badge,
  H1,
  H5,
  Div,
  Flex,
  PlusIcon,
  colors,
  SectionBody
} from "@ikiru/talentis-fpc"
import { RouterUrl } from "setup/router/routes"
import { messages } from "setup/messages/messages"
import { Loader } from "components/Loader/Loader"
import { RouterLink } from "components/RouterLink/RouterLink"
import { AssignmentsGroup } from "views/assignments/components/assignments-group"
import { assignmentListE2ETargets } from "./list-assignment.definitions"
import { Helmet } from "react-helmet"
import { Pagination } from "components/functional/pagination/Pagination"
import StatusGroupTabs from "views/assignments/components/assignments-group/StatusGroupTabs"
import { ProjectSearch } from "components/ProjectActions/ProjectSearch/ProjectSearch"
import { useAssignments } from "./hooks"
import { sortByDate } from "views/assignments/utils"
import { StyledLargeText } from "./style"
import ActionButton from "components/ProjectActions/ActionButton"

export const ListAssignment = () => {
  const {
    totalCount,
    findAssignmentNames,
    onChangeSearch,
    searchedAssignmentChanged,
    statusArray,
    onSelectStatus,
    selectedStatus,
    isLoading,
    totalItems,
    assignments,
    totalPages,
    paginationAssignmentList,
    handlePageChange,
    setShowMyAssignmentsOnly,
    showMyAssignmentsOnly
  } = useAssignments()

  const assignmentsSort = useMemo(() => sortByDate(assignments), [assignments])

  return (
    <>
      <Helmet>
        <title>{messages.seo.title.format(messages.seo.assignments)}</title>
      </Helmet>
      <Flex flexDirection="column" minWidth="300px">
        <Flex mb="s" width="100%" justifyContent="space-between">
          <Flex
            data-e2e-target-name={assignmentListE2ETargets.title}
            alignItems="center"
          >
            <H1 color="green.standard" my="none" mr="xxs">
              {messages.assignment.plural}
            </H1>
            <Badge
              bg="grey.standard"
              color="white.standard"
              height={24}
              pt="2px"
            >
              <H5 color="white.standard" my="none">
                {totalCount}
              </H5>
            </Badge>
          </Flex>
          <Div display={["none", "none", "block"]}>
            <RouterLink to={RouterUrl.AssignmentCreate}>
              <ActionButton
                type="button"
                mode="standard-green"
                prefix={
                  <PlusIcon
                    width={12}
                    height={12}
                    fill={colors.white.standard}
                  />
                }
                size="small"
                label={messages.assignment.addNew}
              />
            </RouterLink>
          </Div>
        </Flex>
        <ProjectSearch
          projectNames={findAssignmentNames}
          onChanged={onChangeSearch}
          onItemClicked={searchedAssignmentChanged}
          buttonTitle={messages.assignment.searchButton}
          searchInput={messages.assignment.searchLable}
          setShowMyAssignmentsOnly={setShowMyAssignmentsOnly}
          showMyAssignmentsOnly={showMyAssignmentsOnly}
        />
        {statusArray?.length > 0 && (
          <StatusGroupTabs
            onSelect={onSelectStatus}
            selectedStatus={selectedStatus}
            assignmentStatus={statusArray}
          />
        )}
        {isLoading ? (
          <Div mt="100px">
            <Loader />
          </Div>
        ) : (
          <>
            <Flex
              mt={0}
              width="100%"
              flexDirection="column"
              data-e2e-target-name={assignmentListE2ETargets.list}
            >
              <SectionBody px="m">
                {totalItems > 0 ? (
                  <AssignmentsGroup assignments={assignmentsSort || []} />
                ) : (
                  <StyledLargeText>
                    {messages.assignment.noResultFound}
                  </StyledLargeText>
                )}
                {totalPages > 1 && (
                  <Flex width="100%" justifyContent="center" mb={20}>
                    <Pagination
                      {...paginationAssignmentList}
                      onPageChange={handlePageChange}
                    />
                  </Flex>
                )}
              </SectionBody>
            </Flex>
          </>
        )}
      </Flex>
    </>
  )
}
