import React, { useCallback } from "react"
import { messages } from "setup/messages/messages"
import NotesList from "./components/NotesList"
import { useCampaign } from "views/campaigns/campaign-module/campaign-module.context"
import { Div, Section } from "@ikiru/talentis-fpc"
import { ModalNames } from "setup/modal/modal.definitions"
import { useModal } from "utils/hooks/use-modal"
import NotesEditMode from "components/Notes/components/EditMode"
import PersonSectionButton from "views/persons/components/person-components/PersonSectionButton"

type CampaignNotesProps = {
  isLoading: boolean
}

const CampaignNotes = ({ isLoading }: CampaignNotesProps) => {
  const { campaignId, notes, setNotes, campaignDetails } = useCampaign()

  const { open, close } = useModal()

  const onSubmit = useCallback(
    (newNoteItem: any) => {
      const newNote = {
        ...newNoteItem,
        linkCreatedByUser: newNoteItem.createdByUser,
        linkUpdatedByUser: newNoteItem.updatedByUser
      }
      const newNotes = [newNote, ...notes]

      setNotes(newNotes)
      close(ModalNames.EditNoteData)
    },
    [close, notes, setNotes]
  )

  const noteOpenModal = useCallback(() => {
    open(
      ModalNames.EditNoteData,
      <NotesEditMode
        projectId={campaignId}
        projectType="campaign"
        projectName={campaignDetails.name}
        onSubmit={onSubmit}
        close={() => close(ModalNames.EditNoteData)}
      />
    )
  }, [campaignDetails.name, campaignId, close, onSubmit, open])

  return (
    <Section
      size="xxSmall"
      header={{
        title: messages.person.note.title,
        actions: (
          <PersonSectionButton
            disabled={false}
            label={messages.person.note.newNote}
            onClick={noteOpenModal}
          />
        )
      }}
    >
      <Div width="100%" py="s">
        <NotesList isLoading={isLoading} />
      </Div>
    </Section>
  )
}
export default React.memo(CampaignNotes)
