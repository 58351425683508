import {
  AssignmentsIcon,
  CampaignWhiteIcon,
  colors,
  CompaniesIcon,
  PeopleIcon
} from "@ikiru/talentis-fpc"
import React from "react"
import { NavigateFunction } from "react-router-dom"
import { RouterUrl } from "setup/router/routes"
import {
  LinkNotePreviewType,
  ProjectTypes
} from "views/assignments/components/assignment-notes/components/NotesList"
import { NoteType } from "views/assignments/components/assignment-notes/components/NotesList/NoteAssignmentItem"

export const generateLinks = (
  note: NoteType,
  navigate?: NavigateFunction,
  disableRoute?: boolean
) => {
  const linksInfoList: LinkNotePreviewType[] = []
  if (note.person?.id) {
    linksInfoList.push({
      id: note.person.id || "",
      label: note.person?.name || "",
      Icon: <PeopleIcon width={12} height={12} viewBox="0 0 20 20" />,
      onClick: () => {
        if (!disableRoute && navigate) {
          navigate(`${RouterUrl.ProfileDetails}/${note.person?.id}`)
        }
      },
      type: ProjectTypes.person
    })
  }
  if (note.assignment?.id) {
    linksInfoList.push({
      id: note.assignment?.id || "",
      label: note.assignment?.name || "",
      Icon: <AssignmentsIcon width={12} height={12} viewBox="0 0 20 20" />,
      onClick: () => {
        if (!disableRoute && navigate) {
          navigate(`${RouterUrl.AssignmentDetails}/${note.assignment?.id}`)
        }
      },
      type: ProjectTypes.assignment
    })
  }
  if (note.campaign?.id) {
    linksInfoList.push({
      id: note.campaign.id,
      label: note.campaign?.name || "",
      Icon: (
        <CampaignWhiteIcon
          fill={colors.grey.dark}
          width={12}
          height={12}
          viewBox="0 0 20 20"
        />
      ),
      onClick: () => {
        if (!disableRoute && navigate) {
          navigate(`${RouterUrl.CampaignDetails}/${note.campaign?.id}`)
        }
      },
      type: ProjectTypes.campaign
    })
  }
  if (note.company?.id) {
    linksInfoList.push({
      id: note.company.id || "",
      label: note.company?.name || "",
      Icon: <CompaniesIcon width={12} height={12} viewBox="0 0 20 20" />,
      onClick: () => {
        if (!disableRoute && navigate) {
          navigate(`${RouterUrl.CompanyDetails}/${note.company?.id}`)
        }
      },
      type: ProjectTypes.company
    })
  }

  return linksInfoList
}
