import styled from "styled-components"

export const List = styled.ul`
  display: inline-block;
  list-style-type: none;
  padding-left: 0;
`

export const LiStyled = styled.li`
  text-align: left;
  padding-left: ${({ theme }) => theme.space.s}px;

  ::before {
    content: "\\1F449";
    font-size: 25px;
    margin-right: ${({ theme }) => theme.space.s}px;
    position: relative;
    bottom: -5px;
  }
`

export const SpanStyled = styled.span`
  font-weight: bold;
`
