import React, { useMemo, useCallback } from "react"
import {
  AssignmentsIcon,
  CampaignWhiteIcon,
  colors,
  CompaniesIcon,
  Flex,
  NoteDocuments,
  NoteLinksProps,
  PeopleIcon
} from "@ikiru/talentis-fpc"
import { formatDateTime } from "utils/format-date"
import { messages } from "setup/messages/messages"
import { RouterUrl } from "setup/router/routes"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import AttachmentModel from "components/PersonRecords/Notes/NoteModal/AttachmentModel"
import { generateNoteDate } from "components/NoteItem/helpers"
import { PersonNote } from "views/persons/components/person-notes/types"
import { AssignmentNote } from "views/assignments/components/assignment-notes/types"
import { CampaignNote } from "views/campaigns/components/campaign-notes/types"
import { StyledNoteLinks } from "../../style"
import { openNoteDoc } from "components/NoteItem/utlis"
import { LinkNotePreviewType } from "."

export type NoteType = PersonNote | AssignmentNote | CampaignNote

type NoteItemProps = {
  note: NoteType
  containerDataE2ETarget?: string
  buttonEditDataE2ETarget?: string
  links: LinkNotePreviewType[]
  openModal: (note: NoteType) => void
}

const NoteAssignmentItem = ({
  note,
  containerDataE2ETarget,
  buttonEditDataE2ETarget,
  links,
  openModal
}: NoteItemProps) => {
  const {
    noteTitle,
    noteDescription,
    createdBy,
    createdDate,
    updatedBy,
    updatedDate,
    linkAssignment,
    linkCampaign
  } = generateNoteDate(note)

  const { open } = useModal()

  const projectRoute = useMemo(
    () =>
      linkAssignment?.id
        ? `${RouterUrl.AssignmentDetails}/${linkAssignment?.id}`
        : linkCampaign?.id
        ? `${RouterUrl.CampaignDetails}/${linkCampaign?.id}`
        : " ",
    [linkCampaign, linkAssignment]
  )

  const seeDocument = useCallback(
    (documentId: string) => {
      const document = note.documents?.find(
        (doc) => doc.id === documentId
      ) as NoteDocuments
      if (document) {
        openNoteDoc(document, documentId, note.id, () =>
          open(
            ModalNames.EmailAttachments,
            <AttachmentModel noteId={note.id} documentId={documentId} />
          )
        )
      }
    },
    [note.documents, note.id, open]
  )

  return (
    <Flex
      as="li"
      bg="grey.lightest"
      flexDirection="column"
      pb={0}
      m={0}
      my="s"
      data-e2e-target={containerDataE2ETarget}
    >
      <Flex>
        <StyledNoteLinks
          title={noteTitle}
          description={noteDescription}
          createdDate={createdDate}
          createdBy={createdBy}
          formattedCreatedDate={formatDateTime(createdDate)}
          formattedUpdatedDate={
            updatedDate ? formatDateTime(updatedDate) : null
          }
          tag={linkAssignment?.name || linkCampaign?.name}
          tagVariant={linkCampaign?.name ? "grey-standard" : undefined}
          updatedBy={updatedBy}
          updatedDate={updatedDate}
          moreButtonLabel={messages.person.note.showFullNote}
          lessButtonLabel={messages.person.note.showLessNote}
          linesNumber={4}
          useArrow
          isHeaderFlexRow
          projectUrl={projectRoute}
          documents={note.documents as unknown as NoteDocuments[]}
          onDocumentClick={seeDocument}
          editModalButton
          isJustifyContent
          openModal={() => openModal(note)}
          links={links}
        />
      </Flex>
    </Flex>
  )
}

export default React.memo(NoteAssignmentItem)
