import { generateWithoutReadOnly } from "models/LocalPerson/localPerson.utils"
import { PersonState, PersonActionTypes } from "./person-module.types"
import { PersonContextValues } from "./person-module.types"

export const initialPersonState: PersonState = {
  person: undefined,
  isLoading: true,
  profileDetails: null!,
  profilePrivacy: null!,
  keywords: [],
  documents: [],
  photo: null,
  candidates: {},
  notes: [],
  experience: null!,
  contacts: {},
  personOverlayUpdated: false,
  personOverlayNoteUpdated: false,
  personOverlayPhotoUpdated: false,
  defaultEditedField: "",
  linkedinEditMode: false,
  createdDate: "",
  offLimits: null!,
  teamMembers: [],
  cvParsing: null!,
  tempCvParsingData: undefined,
  activeTab: 0,
  completedCVSteps: {},
  currentHoveredItem: "",
  dragItems: [],
  previousButtonClicked: 0,
  attributes: [],
  changedNodes: [],
  hiddenAttributesIds: [],
  notesPagination: {
    firstItemOnPage: 1,
    totalItemCount: 1,
    pageSize: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    pageNumber: 1,
    maxPages: 5,
    pageCount: 1,
    onPageChange: () => {}
  }
}

export const initialPersonContextValues: PersonContextValues = {
  ...initialPersonState,
  personId: "",
  setDocuments: () => {},
  setProfileDetails: () => {},
  setProfilePrivacy: () => {},
  setKeywords: () => {},
  getProfileData: () => {},
  setCreatedDate: () => {},
  setPhoto: () => {},
  setCandidate: () => {},
  setCandidates: () => {},
  setNotes: () => {},
  setIsLoading: () => {},
  setExperience: () => {},
  getProfileAvatar: () => {},
  updateCandidateStage: () => {},
  setContacts: () => {},
  setContact: () => {},
  updateContactStage: () => {},
  setPersonOverlayUpdated: () => {},
  setPersonOverlayNoteUpdated: () => {},
  setPersonOverlayPhotoUpdated: () => {},
  setDefaultFocusedField: () => {},
  setLinkedinEditMode: () => {},
  deleteExperience: () => Promise.resolve([undefined, undefined]),
  createExperience: () => Promise.resolve([undefined, undefined]),
  editExperience: () => Promise.resolve([undefined, undefined]),
  setOffLimits: () => {},
  setTeamMembers: () => {},
  setCVParsing: () => {},
  setTempCvParsingData: () => {},
  setActiveTab: () => {},
  setCompletedCVSteps: () => {},
  setCurrentHoveredItem: () => {},
  setDragItems: () => {},
  setPreviousButtonClicked: () => {},
  setAttributes: () => {},
  updateAttributes: () => {},
  setChangedNodes: () => {},
  setNotesPagination: () => {}
}

export const personReducer = (
  state: PersonState,
  action: { type: string; payload?: any }
) => {
  const { type, payload } = action
  switch (type) {
    case PersonActionTypes.SetProfileData:
      return { ...state, ...payload }
    case PersonActionTypes.SetIsLoading:
      return {
        ...state,
        isLoading: payload
      }
    case PersonActionTypes.SetProfileDetails:
      const newPersonValues = generateWithoutReadOnly(payload, [
        "name",
        "company",
        "linkedInProfileUrl",
        "bio",
        "dataPoolPersonId",
        "rocketReachFetchedInformation",
        "isOfflimits"
      ])

      const person = { ...state.person, ...newPersonValues }

      return {
        ...state,
        profileDetails: payload,
        person
      }
    case PersonActionTypes.SetPhoto:
      return {
        ...state,
        photo: payload
      }
    case PersonActionTypes.SetDocuments:
      return {
        ...state,
        documents: payload
      }
    case PersonActionTypes.SetKeywords:
      return {
        ...state,
        keywords: payload
      }
    case PersonActionTypes.SetProfilePrivacy:
      return {
        ...state,
        profilePrivacy: payload
      }
    case PersonActionTypes.SetExperience:
      return {
        ...state,
        experience: payload
      }
    case PersonActionTypes.SetCandidate:
      return {
        ...state,
        candidates: {
          ...state.candidates,
          [payload.id]: {
            ...state.candidates[payload.id],
            ...payload
          }
        }
      }
    case PersonActionTypes.SetCandidates:
      return {
        ...state,
        candidates: payload
      }
    case PersonActionTypes.SetNotes:
      return {
        ...state,
        notes: payload
      }
    case PersonActionTypes.SetProfileAvatar:
      return {
        ...state,
        photo: payload
      }
    case PersonActionTypes.SetContacts:
      return {
        ...state,
        contacts: payload
      }
    case PersonActionTypes.SetContact:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          [payload.id]: {
            ...state.contacts[payload.id],
            ...payload
          }
        }
      }
    case PersonActionTypes.SetPersonOverlayUpdated:
      return {
        ...state,
        personOverlayUpdated: payload,
        ...(!payload && { personOverlayPhotoUpdated: false })
      }
    case PersonActionTypes.SetPersonOverlayNoteUpdated:
      return { ...state, personOverlayNoteUpdated: payload }
    case PersonActionTypes.SetPersonOverlayPhotoUpdated:
      return {
        ...state,
        personOverlayPhotoUpdated: payload,
        personOverlayUpdated: payload
      }
    case PersonActionTypes.setDefaultFocusedField:
      return { ...state, defaultEditedField: payload }
    case PersonActionTypes.setLinkedinEditMode:
      return { ...state, linkedinEditMode: payload }
    case PersonActionTypes.SetOffLimits:
      return { ...state, offLimits: payload }
    case PersonActionTypes.SetTeamMembers:
      return { ...state, teamMembers: payload }
    case PersonActionTypes.SetCreatedDate: {
      return { ...state, createdDate: payload }
    }
    case PersonActionTypes.SetCVParsing:
      return {
        ...state,
        cvParsing: payload
      }
    case PersonActionTypes.SetTempCvParsingData: {
      return {
        ...state,
        tempCvParsingData: payload
      }
    }
    case PersonActionTypes.SetActiveTab: {
      return {
        ...state,
        activeTab: payload
      }
    }
    case PersonActionTypes.SetCompletedCVSteps: {
      return {
        ...state,
        completedCVSteps: payload
      }
    }
    case PersonActionTypes.SetCurrentHoveredItem: {
      return { ...state, currentHoveredItem: payload }
    }
    case PersonActionTypes.SetDragItems: {
      return { ...state, dragItems: payload }
    }
    case PersonActionTypes.SetPreviousButtonClicked: {
      return { ...state, previousButtonClicked: payload }
    }
    case PersonActionTypes.SetAttributes: {
      return { ...state, attributes: payload }
    }
    case PersonActionTypes.SetChangedNodes: {
      return { ...state, changedNodes: payload }
    }
    case PersonActionTypes.SetHiddenAttributes: {
      return { ...state, hiddenAttributesIds: payload }
    }
    case PersonActionTypes.SetNotesPagination: {
      return {
        ...state,
        notesPagination: { ...state.notesPagination, ...payload }
      }
    }
    default:
      return state
  }
}
