import { apiRequest } from "setup/api/api"
import { AttributesEndpoints } from "setup/api/endpoints/endpoints"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"
import { Attribute } from "./attributes-module.types"

export const getAllAttributes = async () => {
  const [, response] = await apiRequest.get({
    endpoint: AttributesEndpoints.Root,
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

  return response?.data
}

export const getWhereUsedList = async () => {
  const [, response] = await apiRequest.get({
    endpoint: AttributesEndpoints.WhereUsedList,
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

  return response?.data
}

export const saveAttributes = async (attributes: Attribute[]) => {
  const [, response] = await apiRequest.patch({
    endpoint: AttributesEndpoints.Root,
    data: { attributes },
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

  return response?.data
}
