import { apiRequest } from "setup/api/api"
import { AttributesEndpoints } from "setup/api/endpoints/endpoints"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"

export const assignAttributes = async (personId: string, ids: string[]) => {
  const [, response] = await apiRequest.patch({
    endpoint: AttributesEndpoints.AttributesLinksPerson,
    data: { ids },
    endpointParams: personId,
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

  return response?.data
}

export const getAllAttributes = async () => {
  const [, response] = await apiRequest.get({
    endpoint: AttributesEndpoints.AttributesPerson,
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

  return response?.data
}
