import { OffLimitsType } from "components/OffLimits/type"
import { WebSiteType } from "../components/company-details/types"
import { ProjectTeamMember } from "components/ProjectTeamMembers/types"
import { PersonNote } from "views/persons/components/person-notes/types"
import { PaginationProps } from "components/functional/pagination/Pagination"

export type CompanyState = {
  isLoading: boolean
  notes: PersonNote[]
  notesPagination: PaginationProps
  companyDetails: Company
  selectedKnownEmployeeId: string
  offLimits: OffLimitsType
  personRecordOverlayUpdated: boolean
}

export enum CompanyActionTypes {
  SetIsLoading = "setIsLoading",
  SetCompanyDetails = "setCompanyDetails",
  SetSelectedKnownEmployeeId = "setSelectedKnownEmployeeId",
  GetCompanyData = "getCompanyData",
  SetOffLimits = "setOffLimits",
  SetPersonRecordOverlayUpdated = "setPersonRecordOverlayUpdated ",
  SetNotes = "SetNotes",
  SetNotesPagination = "setNotesPagination"
}

export type CompanyContextValues = CompanyState & {
  companyId: string
  setCompanyDetails: SetCompanyDetails
  setSelectedKnownEmployeeId: SetSelectedKnownEmployeeId
  getCompanyData: GetCompanyData
  setOffLimits: SetOffLimits
  setPersonRecordOverlayUpdated: SetPersonRecordOverlayUpdated
  setNotes: SetNotes
  setNotesPagination: SetNotesPagination
}

export type SetCompanyDetails = (data: any) => void
export type SetIsLoading = (data: boolean) => void
export type SetSelectedKnownEmployeeId = (data: string) => void
export type GetCompanyData = (companyId: string) => void
export type SetOffLimits = (offLimits: OffLimitsType) => void
export type SetPersonRecordOverlayUpdated = (data: boolean) => void
export type SetNotesPagination = (pagination: Partial<PaginationProps>) => void
export type SetNotes = (notes: PersonNote[]) => void

export type CompanyDescription = {
  id: string
  source: string
  text: string
}

export type CompanyPhones = {
  id: string
  name: string
}

export type CompanyWebsiteLinks = {
  id: string
  linkTo: WebSiteType
  url: string
}

export type CompanyIndustries = {
  id: string
  name: string
}

export type CompanyStockMarket = {
  symbol: string
  exchange: string
  region: string
}

export type CompanyAddresses = {
  freeformAddress: string
}

export type Company = {
  id: string
  dpName: boolean
  dpLogoUrl: boolean
  dpEmployees: boolean
  dpYearFounded: boolean
  dpStockMarket: boolean
  dpStrapline: boolean
  dpClassification: boolean
  descriptions: CompanyDescription[]
  alternativeNames: []
  addresses: CompanyAddresses[]
  phoneNumbers: CompanyPhones[]
  websiteLinks: CompanyWebsiteLinks[]
  industries: CompanyIndustries[]
  isLinkedToDataPool: boolean
  affiliatedCompanies: []
  name: string
  logoUrl: string
  speciality: string
  size: "verySmall" | "small" | "medium" | "large" | "veryLarge" | "unknown"
  yearFounded: number
  stockMarket: CompanyStockMarket
  offLimits: OffLimitsType
  teamMembers: ProjectTeamMember[]
  knownEmployees: number
}
