import {
  Avatar,
  BodyText,
  Button,
  ConnectedIcon,
  Div,
  Flex,
  H6,
  Loader,
  SmallText,
  spacing
} from "@ikiru/talentis-fpc"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { PaginationProps } from "components/functional/pagination/Pagination"
import { AvatarWrapper } from "components/PersonRecords/OverlayOpenner/PersonAvatar/style"
import React, { useState } from "react"
import { Nullable } from "tsdef"
import { onFetchPersons } from "views/persons/pages/search-person/search-person/search-person.actions"
import { formatResults } from "views/persons/pages/search-person/utils"
import { PersonContainer, PersonListContainer } from "./style"
import { messages } from "setup/messages/messages"

type PersonSearchProps = {
  selectedCandidate: {
    id: string
    name: string
  }
  setSelectedCandidate: React.Dispatch<
    React.SetStateAction<{
      id: string
      name: string
    }>
  >
  onLink: (personId: string) => void
}

const PersonSearch = ({
  onLink,
  selectedCandidate,
  setSelectedCandidate
}: PersonSearchProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [foundPerson, setFoundPerson] = useState<
    | {
        id: string
        name: string
        company: string
        jobTitle: string
        location: string
        photo: string
      }[]
    | null
  >(null)

  const [pagination, setPagination] = useState<Nullable<PaginationProps>>({
    hasNextPage: false,
    hasPreviousPage: false,
    isFirstPage: false,
    isLastPage: false,
    lastItemOnPage: undefined,
    pageCount: 0,
    pageSize: 0,
    totalItemCount: 0,
    pageNumber: 1,
    maxPages: 0,
    onPageChange: () => {}
  })

  const submitForm = async () => {
    setIsLoading(true)
    setSelectedCandidate({ id: "", name: "" })
    const response = await onFetchPersons(searchValue, pagination?.pageNumber)

    const personsFound = formatResults(response.results) as any[]

    setFoundPerson(personsFound || [])

    setPagination(response.pagination)
    setIsLoading(false)
  }

  const onLinkPerson = () => {
    onLink(selectedCandidate.id)
    setFoundPerson(null)
    setSearchValue("")
  }

  return (
    <Div width="100%">
      <Flex alignItems="center" justifyContent="flex-start">
        <Flex width="100%" alignItems="center">
          <Div width="100%">
            <FormikInput
              status="valid"
              label="Search for contact"
              id="searchString"
              variant="default"
              name="searchString"
              value={searchValue}
              onChange={(e: any) => {
                const value = e.target.value
                setSearchValue(value)
              }}
            />
          </Div>
          <Div ml="xs">
            <Button
              onClick={(e) => {
                e.stopPropagation()
                submitForm()
              }}
              type="button"
              width="170px"
              size="small"
              mode="standard-white"
            >
              {messages.form.notes.searchForContacts}
            </Button>
          </Div>
          {isLoading && (
            <Div ml="xs">
              <Loader size="small" />
            </Div>
          )}
        </Flex>
        {selectedCandidate.id && (
          <Div ml="xxs">
            <Button
              onClick={onLinkPerson}
              mode="standard-green"
              width="150px"
              type="button"
              size="small"
            >
              {messages.form.notes.linkPerson}
            </Button>
          </Div>
        )}
      </Flex>
      {foundPerson &&
        (!!foundPerson?.length ? (
          <PersonListContainer>
            {foundPerson?.map((person) => {
              return (
                <PersonContainer
                  key={person.id}
                  isSelected={selectedCandidate.id === person.id}
                  onClick={() =>
                    setSelectedCandidate({ id: person.id, name: person.name })
                  }
                >
                  <Div>
                    <AvatarWrapper>
                      <Avatar
                        photo={person.photo}
                        width={30}
                        height={30}
                        name={person.name}
                        disableHoverOpacity
                      />
                    </AvatarWrapper>
                  </Div>
                  <Div ml="xs">
                    <Flex flexWrap="nowrap">
                      <H6 m="0" mr="xxs">
                        {person.name}
                      </H6>
                      <ConnectedIcon width={spacing.m} />
                    </Flex>
                    <Flex flexDirection="column">
                      <SmallText m="0">{person.company}</SmallText>
                      <SmallText m="0">{person.jobTitle}</SmallText>
                      <SmallText m="0">{person.location}</SmallText>
                    </Flex>
                  </Div>
                </PersonContainer>
              )
            })}
          </PersonListContainer>
        ) : (
          <PersonListContainer>
            <BodyText>{messages.form.notes.noResultsFound}</BodyText>
          </PersonListContainer>
        ))}
    </Div>
  )
}

export default PersonSearch
