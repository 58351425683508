import React, { useCallback } from "react"
import { messages } from "setup/messages/messages"
import NotesList from "./components/NotesList"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import { Div, Section } from "@ikiru/talentis-fpc"
import { ModalNames } from "setup/modal/modal.definitions"
import { useModal } from "utils/hooks/use-modal"
import PersonSectionButton from "views/persons/components/person-components/PersonSectionButton"
import NotesEditMode from "components/Notes/components/EditMode"

const AssignmentNotes = () => {
  const { assignmentId, notes, setNotes, assignmentDetails } = useAssignment()

  const { open, close } = useModal()

  const onSubmit = useCallback(
    (newNoteItem: any) => {
      const newNote = {
        ...newNoteItem,
        linkCreatedByUser: newNoteItem.createdByUser,
        linkUpdatedByUser: newNoteItem.updatedByUser
      }
      const newNotes = [newNote, ...notes]

      setNotes(newNotes)
      close(ModalNames.EditNoteData)
    },
    [close, notes, setNotes]
  )

  const noteOpenModal = useCallback(() => {
    open(
      ModalNames.EditNoteData,
      <NotesEditMode
        projectId={assignmentId}
        projectType="assignment"
        projectName={assignmentDetails.name}
        onSubmit={onSubmit}
        close={() => close(ModalNames.EditNoteData)}
      />
    )
  }, [open, assignmentId, assignmentDetails.name, onSubmit, close])

  return (
    <Section
      size="xxSmall"
      header={{
        title: messages.person.note.title,
        actions: (
          <PersonSectionButton
            disabled={false}
            label={messages.person.note.newNote}
            onClick={noteOpenModal}
          />
        )
      }}
    >
      <Div py="s" width="100%">
        <NotesList />
      </Div>
    </Section>
  )
}

export default React.memo(AssignmentNotes)
