import React, { useCallback } from "react"
import { H6, Div, Toggle } from "@ikiru/talentis-fpc"
import { capitalizeFirstLetter } from "views/search/SearchModule/utils"

type ShowInBodyProps = {
  whereUsedList: string[]
  whereUsed?: string[]
  changeShowIn: (showIn: string[]) => void
}

export const ShowInBody = ({
  whereUsedList,
  whereUsed = [],
  changeShowIn
}: ShowInBodyProps) => {
  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, item: string) => {
      let tempWhereUsed: string[] = [...whereUsed]
      if (event.target.checked) {
        tempWhereUsed = [...tempWhereUsed, item]
      } else {
        tempWhereUsed = tempWhereUsed.filter(
          (currentItem) => currentItem !== item
        )
      }

      changeShowIn(tempWhereUsed)
    },
    [whereUsed, changeShowIn]
  )

  const onToggleAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        changeShowIn(whereUsedList.map((item) => item.toLowerCase()))
      } else {
        changeShowIn([])
      }
    },
    [whereUsedList, changeShowIn]
  )

  const isAllSelected = whereUsedList.every((item) =>
    whereUsed.includes(item.toLowerCase())
  )

  return (
    <>
      <Div>
        <Toggle
          hideUnderline
          small
          type="checkbox"
          label={
            <H6 m="0" color="grey.dark">
              Select all
            </H6>
          }
          value="all"
          checked={isAllSelected}
          onChange={onToggleAll}
        />
      </Div>
      {whereUsedList.map((item: string) => (
        <Div key={item}>
          <Toggle
            hideUnderline
            small
            type="checkbox"
            label={
              <H6 m="0" color="grey.dark">
                {capitalizeFirstLetter(item)}
              </H6>
            }
            value={item}
            checked={whereUsed.includes(item.toLowerCase())}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange(e, item.toLowerCase())
            }
          />
        </Div>
      ))}
    </>
  )
}
