import React, { useCallback, useMemo } from "react"
import { PersonNote } from "../types"
import { generateNoteDate } from "components/NoteItem/helpers"
import { RouterUrl } from "setup/router/routes"
import { Div, Flex, Line, Note } from "@ikiru/talentis-fpc"
import { formatDateTime } from "utils/format-date"
import { messages } from "setup/messages/messages"
import { ModalNames } from "setup/modal/modal.definitions"
import AttachmentModel from "components/PersonRecords/Notes/NoteModal/AttachmentModel"
import { useModal } from "utils/hooks/use-modal"
import { openNoteDoc } from "components/NoteItem/utlis"
import { StyledNoteLinks } from "views/assignments/components/assignment-notes/style"
import { LinkNotePreviewType } from "views/assignments/components/assignment-notes/components/NotesList"
import { NoteType } from "views/assignments/components/assignment-notes/components/NotesList/NoteAssignmentItem"

type PersonNoteItemProps = {
  note: PersonNote
  index: number
  editNote: (note: PersonNote) => void
  buttonEditDataE2ETarget: string
  links: LinkNotePreviewType[]
}

const PersonNoteItem = ({
  note,
  index,
  buttonEditDataE2ETarget,
  editNote,
  links
}: PersonNoteItemProps) => {
  const {
    noteTitle,
    noteDescription,
    createdBy,
    createdDate,
    updatedBy,
    updatedDate,
    linkAssignment,
    linkCampaign
  } = generateNoteDate(note)
  const { open } = useModal()

  const projectRoute = useMemo(
    () =>
      linkAssignment?.id
        ? `${RouterUrl.AssignmentDetails}/${linkAssignment?.id}`
        : linkCampaign?.id
        ? `${RouterUrl.CampaignDetails}/${linkCampaign?.id}`
        : " ",
    [linkCampaign, linkAssignment]
  )

  const seeDocument = useCallback(
    async (documentId: string) => {
      const document = note.documents?.find((doc) => doc.id === documentId)
      if (document) {
        openNoteDoc(document, documentId, note.id, () =>
          open(
            ModalNames.EmailAttachments,
            <AttachmentModel noteId={note.id} documentId={documentId} />
          )
        )
      }
    },
    [note.documents, note.id, open]
  )

  return (
    <Flex as="li" bg="grey.lightest" flexDirection="column" pb={0} m={0} my="s">
      <Flex>
        <StyledNoteLinks
          title={noteTitle}
          description={noteDescription}
          createdDate={createdDate}
          createdBy={createdBy}
          formattedCreatedDate={formatDateTime(createdDate)}
          formattedUpdatedDate={
            updatedDate ? formatDateTime(updatedDate) : null
          }
          tag={linkAssignment?.name || linkCampaign?.name}
          tagVariant={linkCampaign?.name ? "grey-standard" : undefined}
          updatedBy={updatedBy}
          updatedDate={updatedDate}
          moreButtonLabel={messages.person.note.showFullNote}
          lessButtonLabel={messages.person.note.showLessNote}
          linesNumber={4}
          useArrow
          isHeaderFlexRow
          projectUrl={projectRoute}
          documents={note.documents}
          onDocumentClick={seeDocument}
          editModalButton
          isJustifyContent
          openModal={() => editNote(note)}
          links={links}
        />
      </Flex>
    </Flex>
  )
}

export default PersonNoteItem
