import React from "react"
import { AttributesModule } from "./attributes-module/AttributesModule"
import { Attributes } from "./Attributes"

export const AttributesPage = () => {
  return (
    <AttributesModule>
      <Attributes />
    </AttributesModule>
  )
}
