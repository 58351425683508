import {
  Flex,
  H1,
  H3,
  BodyText,
  Div,
  Span,
  SmallText
} from "@ikiru/talentis-fpc"
import React from "react"
import { Helmet } from "react-helmet"
import { messages } from "setup/messages/messages"
import { LiStyled, List, SpanStyled } from "./styles"

const BoldText = ({ text }: { text: string }) => (
  <Span fontWeight="bold">{text}</Span>
)

const outlookSteps = [
  {
    text: [
      messages.outlook.in,
      <BoldText text={messages.outlook.outlook} />,
      messages.outlook.goTo,
      <BoldText text={messages.outlook.home} />,
      messages.outlook.tab,
      <BoldText text={messages.outlook.getAddIn} />,
      "."
    ]
  },
  { text: [messages.outlook.addInDialog] },
  {
    text: [
      messages.outlook.inThe,
      <BoldText text={messages.outlook.search} />,
      messages.outlook.box,
      messages.outlook.talentis,
      "."
    ]
  },
  {
    text: [
      messages.outlook.select,
      <BoldText text={messages.outlook.talentisAddIn} />,
      messages.outlook.and,
      <BoldText text={messages.outlook.add} />,
      "."
    ]
  },
  {
    text: [
      messages.outlook.see,
      <BoldText text={messages.outlook.talentisAdd} />,
      messages.outlook.appear
    ]
  },
  { text: [messages.outlook.goToEmail] }
]

export const Outlook = () => {
  return (
    <>
      <Helmet>
        <title>{messages.seo.title.format(messages.outlook.title)}</title>
      </Helmet>
      <H1 m="0" color="green.standard">
        {messages.outlook.title}
      </H1>
      <Flex
        mt="s"
        bg="grey.lightest"
        flexDirection="column"
        alignItems="center"
      >
        <Flex width="50%" flexDirection="column" alignItems="center">
          <H1 mt="xl" color="green.standard" textAlign="center">
            {messages.outlook.description}
          </H1>
          <iframe
            title="vimeo-player"
            src="https://player.vimeo.com/video/787853136?h=2f9c0d9ae3"
            width="640"
            height="400"
            frameBorder="0"
            allowFullScreen
          />
          <Div color="grey.dark" textAlign="center" mt="45px">
            <BodyText>{messages.outlook.install}</BodyText>

            <Div color="grey.dark" mt="s">
              {messages.outlook.youCanGetPart1}

              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://appsource.microsoft.com/en-us/product/office/WA200004909?src=office&tab=Overview"
              >
                {messages.outlook.link}
              </a>
              {messages.outlook.youCanGetPart2}
            </Div>

            <H3 mt="45px" color="grey.dark">
              {messages.outlook.list}
            </H3>
            <List>
              {outlookSteps.map((step, index) => (
                <LiStyled key={index}>
                  {step.text.map((part, idx) => (
                    <React.Fragment key={idx}>{part}</React.Fragment>
                  ))}
                </LiStyled>
              ))}
            </List>
            <Div mb="s">
              <SmallText>
                {messages.outlook.connectPart1}
                <SpanStyled>{messages.outlook.exchange}</SpanStyled>,
                <SpanStyled>{messages.outlook.office}</SpanStyled>,
                {messages.outlook.or}
                <SpanStyled>{messages.outlook.outlookCom}</SpanStyled>
                {messages.outlook.connectPart2}
              </SmallText>
            </Div>
          </Div>
        </Flex>
      </Flex>
    </>
  )
}
