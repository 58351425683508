import React from "react"
import { PersonNote } from "views/persons/components/person-notes/types"
import { Div, Flex, Line, SelectOptionProps } from "@ikiru/talentis-fpc"
import { personNotesFormE2ETargets } from "../form/constants/definitions"
import PersonNoteItem from "./PersonNoteItem"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import PersonNoDataScreen from "../../person-no-data-message/PersonNoDataScreen"
import { messages } from "setup/messages/messages"
import { NoteEditForm } from "components/Notes/components/forms/NoteEditForm"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { generateLinks } from "components/Notes/components/forms/utils"
import { useNavigate } from "react-router-dom"
import { Pagination } from "components/functional/pagination/Pagination"

type PersonNotesListProps = {
  notes: PersonNote[]
  personId: string
  editPersonNote: (note: any) => void
  assignmentOptions: SelectOptionProps[]
  campaignOptions: SelectOptionProps[]
}

export const PersonNotesList = ({
  notes,
  personId,
  editPersonNote
}: PersonNotesListProps) => {
  const { setNotes, notesPagination } = usePerson()
  const navigate = useNavigate()
  const { open: openModal } = useModal()

  const openEditModal = (note: PersonNote) => {
    const {
      noteTitle,
      noteDescription,
      id,
      assignmentId,
      campaignId,
      companyId
    } = note
    openModal(
      ModalNames.EditNoteData,
      <Div mb="xs">
        <NoteEditForm
          initialValues={{
            noteTitle,
            noteDescription
          }}
          notes={notes}
          projectId={personId}
          setNotes={setNotes as any}
          noteId={id}
          links={generateLinks(note)}
          linkInitialValue={{
            assignment: assignmentId,
            campaign: campaignId,
            person: personId,
            company: companyId
          }}
          onSubmit={editPersonNote}
        />
      </Div>
    )
  }

  return (
    <Flex width="100%">
      {notes.length ? (
        <Div width="100%">
          {notes.map((note, index) => {
            return (
              <Div key={`note_${note?.id}`}>
                {index !== 0 && <Line variant="thin" />}
                <PersonNoteItem
                  note={note}
                  index={index}
                  links={generateLinks(note, navigate)}
                  editNote={openEditModal}
                  buttonEditDataE2ETarget={personNotesFormE2ETargets.editNote}
                />
              </Div>
            )
          })}
          {notesPagination.totalItemCount > 25 && (
            <Flex width="100%" justifyContent="center" mb={20}>
              <Pagination
                {...notesPagination}
                onPageChange={notesPagination.onPageChange}
              />
            </Flex>
          )}
        </Div>
      ) : (
        <PersonNoDataScreen
          message={messages.person.personTabSection.noDataTexts.noNotes}
        />
      )}
    </Flex>
  )
}
