import React, { useCallback } from "react"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { Flex, SelectOptionProps } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { PersonNotesList } from "views/persons/components/person-notes/components/PersonNotesList"
import { Loader } from "components/Loader/Loader"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import PersonSections from "../person-components/PersonSections"
import PersonSectionButton from "../person-components/PersonSectionButton"
import NotesEditMode from "components/Notes/components/EditMode"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import { useCampaign } from "views/campaigns/campaign-module/campaign-module.context"

type PersonNotesProps = {
  isNotesLoading: boolean
  assignmentNoteOptions: SelectOptionProps[]
  campaignNoteOptions: SelectOptionProps[]
}

export const PersonNotes = React.memo(
  ({
    isNotesLoading,
    campaignNoteOptions: campaignsOptions,
    assignmentNoteOptions: assignmentsOptions
  }: PersonNotesProps) => {
    const {
      notes = [],
      setNotes,
      personId,
      person,
      setPersonOverlayNoteUpdated,
      notesPagination
    } = usePerson()
    const {
      assignmentId,
      notesPagination: assignmentNotePagination,
      setNotesPagination: setAssignmentNotePage,
      notes: assignmentNotes,
      setNotes: setAssignmentNotes
    } = useAssignment()

    const {
      campaignId,
      notesPagination: campaignNotePagination,
      setNotesPagination: setCampaignNotePage,
      notes: campaignNotes,
      setNotes: setCampaignNotes
    } = useCampaign()

    const { open: openModal, close } = useModal()

    const editPersonNote = useCallback(
      (newNoteItem: any) => {
        setPersonOverlayNoteUpdated(true)
        const updatedNote = {
          ...newNoteItem,
          linkCreatedByUser: newNoteItem.createdByUser,
          linkUpdatedByUser: newNoteItem.updatedByUser
        }

        const newNotes = notes.map((item) => {
          if (item.id === updatedNote.id) {
            if (
              item.assignmentId !== assignmentId &&
              updatedNote.assignmentId === assignmentId
            ) {
              setAssignmentNotePage({
                totalItemCount: assignmentNotePagination.totalItemCount + 1
              })
              setAssignmentNotes([newNoteItem, ...assignmentNotes])
            } else if (
              item.assignmentId === assignmentId &&
              updatedNote.assignmentId !== assignmentId
            ) {
              setAssignmentNotePage({
                totalItemCount: assignmentNotePagination.totalItemCount - 1
              })
            }

            if (
              item.campaignId !== campaignId &&
              updatedNote.campaignId === campaignId
            ) {
              setCampaignNotePage({
                totalItemCount: campaignNotePagination.totalItemCount + 1
              })
              setCampaignNotes([newNoteItem, ...campaignNotes])
            } else if (
              item.campaignId !== campaignId &&
              updatedNote.campaignId === campaignId
            ) {
              setCampaignNotePage({
                totalItemCount: campaignNotePagination.totalItemCount - 1
              })
            }
            return updatedNote
          }
          return item
        })

        setNotes(newNotes)
        close(ModalNames.EditNoteData)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [close, notes, setNotes]
    )

    const onSubmit = useCallback(
      (newNoteItem: any) => {
        const newNote = {
          ...newNoteItem,
          linkCreatedByUser: newNoteItem.createdByUser,
          linkUpdatedByUser: newNoteItem.updatedByUser
        }
        const newNotes = [newNote, ...notes]
        if (assignmentId && newNote.assignmentId === assignmentId) {
          setPersonOverlayNoteUpdated(true)
          setAssignmentNotePage({
            totalItemCount: assignmentNotePagination.totalItemCount + 1
          })
          setAssignmentNotes([newNote, ...assignmentNotes])
        }
        if (campaignId && newNote.campaignID === campaignId) {
          setPersonOverlayNoteUpdated(true)
          setCampaignNotePage({
            totalItemCount: campaignNotePagination.totalItemCount + 1
          })
          setAssignmentNotes([newNote, ...assignmentNotes])
        }
        setNotes(newNotes)
        close(ModalNames.EditNoteData)
      },
      [
        assignmentId,
        assignmentNotePagination.totalItemCount,
        assignmentNotes,
        campaignId,
        campaignNotePagination.totalItemCount,
        close,
        notes,
        setAssignmentNotePage,
        setAssignmentNotes,
        setCampaignNotePage,
        setNotes,
        setPersonOverlayNoteUpdated
      ]
    )

    const addNewNote = () => {
      openModal(
        ModalNames.EditNoteData,
        <NotesEditMode
          projectId={personId}
          projectType="person"
          projectName={person?.name || ""}
          onSubmit={onSubmit}
          close={() => close(ModalNames.EditNoteData)}
        />
      )
    }

    return (
      <PersonSections
        title={messages.person.note.title}
        sectionCount={notesPagination.totalItemCount}
        actions={
          <PersonSectionButton
            disabled={isNotesLoading}
            label={messages.person.note.newNote}
            onClick={addNewNote}
          />
        }
      >
        <Flex width="100%" alignItems="center" justifyContent="center">
          {isNotesLoading ? (
            <Loader />
          ) : (
            <PersonNotesList
              personId={personId}
              notes={notes}
              editPersonNote={editPersonNote}
              assignmentOptions={assignmentsOptions}
              campaignOptions={campaignsOptions}
            />
          )}
        </Flex>
      </PersonSections>
    )
  }
)
